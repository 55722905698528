import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  AeApproval,
  ClearApproval,
  InitializeApprovers,
  TmScApproval,
  UpdateApproverComment,
  UpdateApproverEmail,
  UpdateApproverUserInfo
} from './approval.actions';
import { ApprovalStatus, Approver, ApproverRole } from 'src/app/models/Approver';
import { Injectable, NgZone } from '@angular/core';
import {
  LoadTrainingRequirement,
  LogAnalyticEvent
} from 'src/app/state/trainingRequirement.actions';
import { TcaValidationService, ValidationErrors } from 'src/app/services/tca-validation.service';

import { AccountExecutiveApproveCommand } from 'src/app/models/AccountExecutiveApproveCommand';
import { ApproveCommand } from 'src/app/models/ApproveCommand';
import { ApproverEmailUpdateCommand } from 'src/app/models/ApproverEmailUpdateCommand';
import { Router } from '@angular/router';
import { TcaService } from 'src/app/services/data-layer/tca.service';
import { tap } from 'rxjs/operators';

export interface ApprovalStateModel {
  approvers: Array<Approver>;
  validationErrors: ValidationErrors;
}

@State<ApprovalStateModel>({
  name: 'approval',
  defaults: {
    approvers: [
      new Approver(ApproverRole.AccountExecutive),
      new Approver(ApproverRole.TrainingManager),
      new Approver(ApproverRole.Scheduler)
    ],
    validationErrors: new ValidationErrors()
  }
})
@Injectable()
export class ApprovalState {
  constructor(
    private router: Router,
    private ngZone: NgZone,
    private trainingRequirementService: TcaService,
    private trainingRequirementValidationService: TcaValidationService
  ) { }

  @Selector()
  static approvers(state: ApprovalStateModel): Array<Approver> {
    return state.approvers;
  }

  @Selector()
  static validationErrors(state: ApprovalStateModel) {
    return state.validationErrors;
  }

  @Action(InitializeApprovers)
  initializeApprovers(
    { patchState, getState }: StateContext<ApprovalStateModel>,
    action: InitializeApprovers
  ) {
    let approvers = action.approvers.slice(0);
    if (approvers.length === 0) {
      approvers = [
        new Approver(ApproverRole.AccountExecutive),
        new Approver(ApproverRole.TrainingManager),
        new Approver(ApproverRole.Scheduler)
      ];
      approvers[0].email = action.accountExecutiveEmail;
    }
    if (approvers.length === 1) {
      approvers.push(new Approver(ApproverRole.TrainingManager));
      approvers.push(new Approver(ApproverRole.Scheduler));
    }
    patchState({
      ...getState(),
      approvers: approvers
    });
  }

  @Action(UpdateApproverEmail)
  updateApproverEmail(
    { dispatch, patchState, getState }: StateContext<ApprovalStateModel>,
    action: UpdateApproverEmail
  ) {
    const approvers = getState().approvers.slice(0);
    const approverToUpdate = approvers.find(approver => approver.role === action.approverRole);
    approverToUpdate.email = action.email;

    if (!!action.trainingRequirementId) {
      const approvalCommand = this.createApproverEmailCommand(
        action.revisionNumer,
        approverToUpdate
      );
      this.trainingRequirementService
        .updateApprovalEmail(action.trainingRequirementId, approvalCommand)
        .pipe(
          tap(() => {
            dispatch(new LogAnalyticEvent('regular', 'Update Approver Email TCA'));
            dispatch(new LoadTrainingRequirement(action.trainingRequirementId, true));
          })
        )
        .subscribe();
    }

    patchState({
      ...getState(),
      approvers
    });
  }

  @Action(UpdateApproverUserInfo)
  updateApproverUserInfo(
    { dispatch, patchState, getState }: StateContext<ApprovalStateModel>,
    action: UpdateApproverUserInfo
  ) {
    const approvers = getState().approvers.slice(0);
    const approverToUpdate = approvers.find(approver => approver.role === action.approverRole);
    approverToUpdate.email = action.user.email;
    approverToUpdate.userId = action.user.id;
    approverToUpdate.name = action.user.name;

    if (!!action.trainingRequirementId) {
      const approvalCommand = this.createApproverEmailCommand(
        action.revisionNumer,
        approverToUpdate
      );
      this.trainingRequirementService
        .updateApprovalEmail(action.trainingRequirementId, approvalCommand)
        .pipe(
          tap(() => {
            dispatch(new LogAnalyticEvent('regular', 'Update Approver Email TCA'));
            dispatch(new LoadTrainingRequirement(action.trainingRequirementId, true));
          })
        )
        .subscribe();
    }

    patchState({
      ...getState(),
      approvers
    });
  }

  @Action(UpdateApproverComment)
  updateApproverComment(
    { patchState, getState }: StateContext<ApprovalStateModel>,
    action: UpdateApproverComment
  ) {
    const approvers = getState().approvers.slice(0);
    const approverToUpdate = approvers.find(approver => approver.role === action.approverRole);
    approverToUpdate.comment = action.comment;
    patchState({
      ...getState(),
      approvers
    });
  }

  @Action(AeApproval)
  aeApprove(
    { dispatch, patchState, getState }: StateContext<ApprovalStateModel>,
    action: AeApproval
  ) {
    const state = getState();
    const approvalCommand = this.getAEApprovalCommand(state.approvers, action.revisionNumer);
    const validationErrors = this.trainingRequirementValidationService.validateApproversEmails(
      approvalCommand.SchedulerEmail,
      approvalCommand.TrainingManagerEmail,
      'TCA Review Page'
    );

    if (validationErrors.isValid) {
      this.trainingRequirementService
        .updateAeApproval(action.trainingRequirementId, approvalCommand)
        .pipe(
          tap(() => {
            dispatch(new LogAnalyticEvent('regular', 'Approve TCA'));
            dispatch(new LoadTrainingRequirement(action.trainingRequirementId, true));
          })
        )
        .subscribe();
      this.ngZone.run(() => {
        this.router.navigate(['../tca-state'], { relativeTo: action.route.parent });
      });
    } else {
      patchState({
        ...state,
        validationErrors: validationErrors
      });
    }
  }

  @Action(TmScApproval)
  tmScApproval({ dispatch, getState }: StateContext<ApprovalStateModel>, action: TmScApproval) {
    const state = getState();
    const approver = this.getApproverByRole(state.approvers, action.approverRole);
    this.trainingRequirementService
      .updateTmScApproval(
        action.trainingRequirementId,
        this.getTmScApprovalCommand(getState().approvers, action.revisionNumer, approver.comment)
      )
      .pipe(
        tap(() => {
          dispatch(new LogAnalyticEvent('regular', 'Approve TCA'));
          dispatch(new LoadTrainingRequirement(action.trainingRequirementId, true));
        })
      )
      .subscribe();
    this.ngZone.run(() => {
      this.router.navigate(['../tca-state'], { relativeTo: action.route.parent });
    });
  }

  @Action(ClearApproval)
  clearCurrentTrainingRequirement({ patchState }: StateContext<ApprovalStateModel>) {
    patchState({
      approvers: [],
      validationErrors: null
    });
  }

  private getAEApprovalCommand(
    approvers: Array<Approver>,
    revisionNumber: number
  ): AccountExecutiveApproveCommand {
    const approvalCommand = new AccountExecutiveApproveCommand();
    approvalCommand.RevisionNumber = revisionNumber;

    const accountExecutive = this.getApproverByRole(approvers, ApproverRole.AccountExecutive);
    approvalCommand.AccountExecutiveName = accountExecutive.name;
    approvalCommand.AccountExecutiveEmail = accountExecutive.email;
    approvalCommand.AccountExecutiveUserId = accountExecutive.userId;
    approvalCommand.AccountExecutiveComment = accountExecutive.comment;

    const trainingManager = this.getApproverByRole(approvers, ApproverRole.TrainingManager);
    approvalCommand.TrainingManagerName = !!trainingManager.name
      ? trainingManager.name
      : trainingManager.email;
    approvalCommand.TrainingManagerEmail = trainingManager.email;
    approvalCommand.TrainingManagerUserId = trainingManager.userId;

    const scheduler = this.getApproverByRole(approvers, ApproverRole.Scheduler);
    approvalCommand.SchedulerName = !!scheduler.name ? scheduler.name : scheduler.email;
    approvalCommand.SchedulerEmail = scheduler.email;
    approvalCommand.SchedulerUserId = scheduler.userId;

    approvalCommand.ApprovalStatus = ApprovalStatus.APPROVED;
    return approvalCommand;
  }

  private getTmScApprovalCommand(
    approvers: Array<Approver>,
    revisionNumber: number,
    comment: string
  ): ApproveCommand {
    const approveCommand = new ApproveCommand();

    approveCommand.RevisionNumber = revisionNumber;
    approveCommand.Comment = comment;
    approveCommand.Status = ApprovalStatus.APPROVED;

    return approveCommand;
  }

  private getApproverByRole(approvers: Array<Approver>, approverRole: ApproverRole): Approver {
    return (
      approvers.find(approver => {
        return approver.role === approverRole;
      }) || new Approver(approverRole)
    );
  }

  private createApproverEmailCommand(
    revisionNumber: number,
    approver: Approver
  ): ApproverEmailUpdateCommand {
    const result = new ApproverEmailUpdateCommand();

    if (!!approver) {
      result.Name = !!approver.name ? approver.name : approver.email;
      result.Email = approver.email;
      result.UserId = approver.userId;
      result.Role = approver.role;
      result.RevisionNumber = revisionNumber;
    }

    return result;
  }
}
