<div class="preset-wrapper-action-save">
  <daui-button class="title" [icon]="buttonIcon.Save" [type]="buttonType.Flat" [attractive]="buttonAttractive.Regular"
    (click)="showPresetDialog()" [disabled]="!hasTrainingOptions()">
    Save Options as Training Preset
  </daui-button>
</div>
<daui-dialog class="dialogPreset" [isShow]="isShowPresetDialog" [size]="size">
  <div class="dialogPreset-wrapper">
    <div class="dialogPreset-wrapper-title">Save as a Preset</div>
    <div class="dialogPreset-wrapper-body">
      <daui-form-field class="dialogPreset-wrapper-body-presetTitle" label="Preset Name:">
        <div content>
          <input #inputPresetTitle type="text" name="presetTitle" placeholder="Enter preset title" [value]="presetTitle"
            (keyup)="onPresetTitleChange($event)" />
        </div>
      </daui-form-field>
      <daui-form-field class="dialogPreset-wrapper-body-presetDescription" label="Preset Description:">
        <div content>
          <textarea #inputPresetDescription name="presetDescription" placeholder="Enter description"
            [value]="presetDescription" [attr.maxlength]="maxChars"></textarea>
          <div class="maxChars">({{ maxChars }} char. max)</div>
        </div>
      </daui-form-field>
      <daui-form-field class="dialogPreset-wrapper-body-presetSave" label="Save:">
        <div content>
          <daui-radio-button class="radio" [checked]="currentPresetType == 'private'" name="saveOption"
            label="For This Pilot" id="pilot" (change)="toggleSaveOption($event, 'private')">
          </daui-radio-button>
          <daui-radio-button class="radio" [checked]="currentPresetType == 'global'" name="saveOption"
            label="As a Customer Preset" id="global" (change)="toggleSaveOption($event, 'global')">
          </daui-radio-button>
        </div>
      </daui-form-field>
    </div>
  </div>
  <daui-action-bar [primaryButtonText]="'SAVE NEW PRESET'" secondaryButtonText="Cancel" [hasSecondary]="true"
    (primary)="onPresetDialogSave(inputPresetTitle, inputPresetDescription)"
    (secondary)="onPresetDialogCancel(inputPresetTitle, inputPresetDescription)"
    [primaryDisabled]="!isSavePresetEnabled">
  </daui-action-bar>
</daui-dialog>