import * as environment from '../../../../environments/environment';

import { ActivatedRoute, Router } from '@angular/router';
import {
  ClearCurrentTrainingRequirement,
  LogAnalyticEvent
} from 'src/app/state/trainingRequirement.actions';
import { Component, Input } from '@angular/core';
import { HeaderMode, HeaderService } from 'src/app/core/services/header.service';
import { Select, Store } from '@ngxs/store';

import { AppState } from 'src/app/state/app.state';
import { AuthService } from 'src/app/core/services/auth.service';
import { BaseUIComponent } from '../base-ui-component';
import { ClearApproval } from 'src/app/tca/approval/store';
import { LoginState } from '../../../state/login.state';
import { Observable } from 'rxjs';
import { ToggleFeatures } from 'src/app/data/toggle-features';
import { TrainingRequirement } from 'src/app/models/TrainingRequirement';
import { User } from '../../../models/User';
import { UserInfoViewModel } from 'common-web-ui';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent extends BaseUIComponent {
  @Input() currentTrainingRequirement: TrainingRequirement | null;
  username = '';
  userInfo: UserInfoViewModel = new UserInfoViewModel();
  darkModeFeatureEnabled = true;

  @Select(LoginState.userId) private _userId$: Observable<User>;
  buildNumber = environment.buildNumber;

  @Select(AppState.isFeatureActive(ToggleFeatures.CustomerWebAppViewSharepoint))
  isViewSharepoint$: Observable<boolean>;

  isVisible = true;

  constructor(
    private authService: AuthService,
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private headerService: HeaderService
  ) {
    super();
  }

  onInit(): void {
    super.subscribe(
      this.store
        .select(AppState.isFeatureActive(ToggleFeatures.CustomerWebAppDarkMode))
        .subscribe(isEnabled => {
          this.darkModeFeatureEnabled = isEnabled;
        })
    );

    super.subscribe(
      this._userId$.subscribe((user: User) => {
        if (!!user) {
          this.userInfo.Email = user.email;
          this.userInfo.FirstName = user.givenName;
          this.userInfo.LastName = user.surname ? user.surname : ' ';
        }
      })
    );
    this.subscriptions.push(this.isViewSharepoint$.subscribe(toggleValue => { }));

    this.headerService.headerMode$.subscribe(mode => {
      this.isVisible = mode !== HeaderMode.Hidden;
    });
  }

  onDestroy(): void { }

  onLogOut() {
    this.store.dispatch(new ClearCurrentTrainingRequirement());
    this.store.dispatch(new ClearApproval());
    this.authService.logout();
  }
}
