import { ActivatedRoute, Router } from '@angular/router';
import {
  ApplicationRights,
  TrainingRequirementStatuses
} from './../../../models/TrainingRequirement';
import { ButtonAttractive, ButtonIcon, ButtonType, CheckboxContentMode, DialogSize } from 'common-web-ui';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { LogAnalyticEvent, UpdateTcaSubmitted } from 'src/app/state/trainingRequirement.actions';
import { Select, Store } from '@ngxs/store';

import { AppState } from 'src/app/state/app.state';
import { AttachmentService } from 'src/app/services/data-layer/attachment.service';
import { BaseUIComponent } from '../base-ui-component';
import { DownloadService } from 'src/app/services/data-layer/download.service';
import { Observable } from 'rxjs';
import { RegulationAgencyName } from 'src/app/models/RegulationAgencyName';
import { SubmissionStatus } from 'src/app/models/SubmissionStatus';
import { ToggleFeatures } from 'src/app/data/toggle-features';
import { TrainingRequirement } from 'src/app/models/TrainingRequirement';
import { TrainingRequirementState } from 'src/app/state/trainingRequirement.state';
import { replaceRegex } from '../../helpers/string-helpers';

@Component({
  selector: 'app-state-actions',
  templateUrl: './state-actions.component.html',
  styleUrls: ['./state-actions.component.less']
})
export class StateActionsComponent extends BaseUIComponent {
  isConfirmed: boolean;
  @ViewChild('downloadLink') private downloadLink: ElementRef;
  @Input() label: string;

  isShowDialog: boolean;
  isShowDownloadDialog: boolean;
  dialogSize = DialogSize;
  isEditable: boolean;
  checkboxContentMode = CheckboxContentMode;
  isTcaLockedToggleFeatureEnabled = false;
  regulationType: string;
  files: File[] = [];

  readonly buttonType = ButtonType;
  readonly buttonAttractive = ButtonAttractive;
  readonly buttonIcon = ButtonIcon;

  constructor(
    private downloadService: DownloadService,
    private route: ActivatedRoute,
    private store: Store,
    private router: Router,
    private attachmentService: AttachmentService
  ) {
    super();
  }

  @Select(TrainingRequirementState.currentTrainingRequirement)
  currentTrainingRequirement$: Observable<TrainingRequirement>;
  currentTrainingRequirement: TrainingRequirement = new TrainingRequirement();

  onInit(): void {
    if (!this.label || this.label === '') {
      this.label =
        'CAE will do its best to accommodate any changes, but by checking this box, you understand that once updated, the TCA is subject to review.';
    }
    super.subscribe(
      this.currentTrainingRequirement$.subscribe((data: TrainingRequirement) => {
        this.currentTrainingRequirement = data;
        this.regulationType = data.regulationAgency;
        if (!!this.currentTrainingRequirement && !!this.currentTrainingRequirement.appRights) {
          this.isEditable =
            this.currentTrainingRequirement.appRights.indexOf(ApplicationRights.TcaReadWrite) >= 0;
        }

        this.attachmentService.getFiles(this.currentTrainingRequirement.id, false).then(files => {
          this.files = [];
          this.files.push(...files);
        })
      })
    );

    super.subscribe(
      this.store
        .select(AppState.isFeatureActive(ToggleFeatures.CustomerWebAppTCALocked))
        .subscribe((isToggleFeatureEnabled: boolean) => {
          this.isTcaLockedToggleFeatureEnabled = !!isToggleFeatureEnabled;
        })
    );
  }

  onDestroy(): void { }

  iOS() {
    const iDevices = [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ];

    if (!!navigator.platform) {
      while (iDevices.length) {
        if (navigator.platform === iDevices.pop()) {
          return true;
        }
      }
    }

    return false;
  }

  removeFileExtension(name) {
    return name.replace(/\.[^/.]+$/, "");
  }

  async downloadPDF(filename: string) {

    const result = await this.attachmentService.getAttachment(this.currentTrainingRequirement.id, filename);



    if (result.blobBody) {

      const blob = await result.blobBody;
      const url = window.URL.createObjectURL(blob);
      const link = this.downloadLink.nativeElement;
      link.href = url;
      link.download = filename;
      link.click();
      window.URL.revokeObjectURL(url);
      this.store.dispatch(new LogAnalyticEvent('regular', 'TCA View PDF Attachment'));
    }
  }

  async gotoViewPDF(): Promise<void> {
    if (this.iOS()) {
      const url = `${window.location.protocol}//${window.location.host}/tca/${this.currentTrainingRequirement.id}/reports/paperversion`;
      window.open(url, '_blank');
      return;
    }
    const fileName = this.createTCAFilename();

    let blob;

    try {
      blob = await this.downloadService.getPdfByTcaId(this.route.snapshot.params.id);

      const url = window.URL.createObjectURL(blob);
      const link = this.downloadLink.nativeElement;
      link.href = url;
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(url);
      this.store.dispatch(new LogAnalyticEvent('regular', 'TCA View PDF'));
    }
    catch (ex) {
      console.error(ex);
      this.isShowDownloadDialog = true;
    }

  }

  gotoEdit() {
    if (!this.isTcaLocked()) {
      this.isShowDialog = true;
      this.isConfirmed = false;
    } else {
      this.router.navigate(['./locked'], { relativeTo: this.route });
    }
  }

  isTcaLocked(): boolean {
    let isTcaLocked = false;

    if (
      this.isTcaLockedToggleFeatureEnabled &&
      this.currentTrainingRequirement.status === TrainingRequirementStatuses.Locked
    ) {
      isTcaLocked = true;
    }

    return isTcaLocked;
  }

  onDialogCancel() {
    this.isShowDialog = false;
  }

  onDownloadDialogCancel() {
    this.isShowDownloadDialog = false;
  }

  onDialogOk() {
    this.isShowDialog = false;
    this.store.dispatch(new UpdateTcaSubmitted(SubmissionStatus.NotSubmitted));
    this.store.dispatch(new LogAnalyticEvent('regular', 'TCA Form Edit'));
    if (this.route.snapshot.routeConfig.path.includes('/confirmation')) {
      this.router.navigate(['../'], { relativeTo: this.route });
    }
  }
  onConfirm(value: any) {
    this.isConfirmed = value.srcElement.checked;
  }
  isBrowserEdge() {
    return window.navigator.userAgent.indexOf('Edge/') > 0;
  }

  isBrowserIE() {
    return navigator.userAgent.search(/(?:MSIE|Trident\/.*; rv:)/) > 0;
  }

  createTCAFilename() {
    if (
      this.currentTrainingRequirement.schedulerReservationId &&
      this.currentTrainingRequirement.client
    ) {
      const fileName =
        this.currentTrainingRequirement.schedulerReservationId +
        '_' +
        replaceRegex(this.currentTrainingRequirement.client, /[^A-Za-z0-9]/g, '_') +
        '.pdf';
      return fileName;
    } else {
      const fileName = 'tca.pdf';
      return fileName;
    }
  }
}
