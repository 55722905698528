import {
  CheckboxContentMode,
  FormFieldSize,
  PanelInfoState
} from 'common-web-ui';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BaseUIComponent } from '../base-ui-component';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.less']
})
export class DisclaimerComponent extends BaseUIComponent {
  isConfirmed: boolean;
  @Input() label: string;
  @Input() disclaimerParagraphs: string[] = [];
  @Output() checked: EventEmitter<any> = new EventEmitter();
  @Input() isShowDialog: boolean;
  @Output() isShowDialogChange = new EventEmitter<boolean>();
  @Input() confirmationState: PanelInfoState.Default;
  @Input() errorMessage: string;
  checkboxContentMode = CheckboxContentMode;
  formFieldSize = FormFieldSize;

  constructor() {
    super();
  }

  onInit() { }
  onDestroy() { }

  onConfirm(value: any) {
    this.isConfirmed = value.srcElement.checked;
    this.checked.emit(value.srcElement.checked);
  }

  onDialogClose() {
    this.isShowDialog = false;
    this.isShowDialogChange.emit(false);
  }
}
