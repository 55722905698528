export class SetReturnUrl {
  static readonly type = '[Login] Set Return Url';
  constructor(public returnUrl: string) { }
}

export class StoreUserInfo {
  static readonly type = '[Login] Store User Info';
  constructor(public userInfo) { }
}

export class ChangeLDUserInfo {
  static readonly type = '[Login] Change LaunchDarkly UserInfo';
  constructor(public userInfo) { }
}

export class GetUserInfo {
  static readonly type = '[Login] Get User Info';
  constructor() { }
}

export class GetUserInfoSuccess {
  static readonly type = '[Login] Get User Info Success';
  constructor(public userInfo) { }
}
