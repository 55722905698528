<div *ngIf="!isDatePickerVisible" [class]='containerClass'>
  <daui-datepicker [hasMonth]="true" [hasYear]="true" [hasPrev]="true" [hasNext]="true" [hasToday]="true"
    [size]="datePanelSize.Small" [selectedDate]="dauiDate" dateFormat="YYYY-MM-DD" locale="en"
    (selectedDateChange)="onDateModelChange($event)">
  </daui-datepicker>
  <daui-button (click)="onDateModelChange(null)" style="margin-left: 4px;" *ngIf='!!date' class="item"
    [icon]="buttonIcon.Reject" [type]="buttonType.Flat"></daui-button>
  <input style="display: none;" #dateControl="ngModel" [name]="dateControlName" [ngModel]='date'>
</div>
<div *ngIf="isDatePickerVisible">
  <input [class]="dateControlClass" [id]="dateControlId" type="date" [name]="dateControlName"
    [ngModel]="dateSelected | date: 'yyyy-MM-dd'" (ngModelChange)="onDateModelChange($event)"
    (change)="onDateChange($event)" />
</div>