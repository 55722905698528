import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-etca-toggle',
  templateUrl: './etca-toggle.component.html',
  styleUrls: ['./etca-toggle.component.less']
})
export class EtcaToggleComponent implements OnInit, OnDestroy {
  @Input() showEtca: boolean;
  @Input() showCustomTca: boolean;
  @Output() onClickUseEtca = new EventEmitter();
  @Output() onClickUseCustomTca = new EventEmitter();

  showDialogEtca: boolean = false;
  showDialogCustomTca: boolean = false;

  constructor() {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  useEtca() {
    this.showDialogEtca = true;
    this.showDialogCustomTca = false;
  }

  useCustomTca() {
    this.showDialogEtca = false;
    this.showDialogCustomTca = true;
  }

  onDialogShowEtcaOk() {
    this.showDialogEtca = false;
    this.onClickUseEtca.emit();
  }

  onDialogShowCustomTcaOk() {
    this.showDialogCustomTca = false;
    this.onClickUseCustomTca.emit();
  }

  onDialogCancel(): void {
    this.showDialogEtca = false;
    this.showDialogCustomTca = false;
  }
}
