export enum TCCheckTypeInitial {
  PPC = 'PPC',
  PCC = 'PCC',
}
export enum TCCheckTypeRecurrent {
  PPC = 'PPC',
  TrainingInLieu = 'Training in Lieu',
  PCC = 'PCC',
}

export enum EASACheckType {
  OPC = 'OPC',
  LPC = 'LPC',
  LPCOPC = 'LPC/OPC',
  TrainingOnly = 'Training Only',
}

export enum FAACheckType {
  SIC_Training = '61.55 (SIC Training)',
  PIC_Checking = '61.58 (PIC Checking)',
  Standalone_PIC_Checking = '61.58 (Standalone PIC Checking)',
}

export enum OtherNAALicense {
  FAA = 'FAA',
  EASA = 'EASA',
  Other = 'Other',
}

export enum ChecktypeAdditionalInfo {
  ATPUnfreeze = 'ATPUnfreeze',
  OtherChecktypeDetails = 'OtherChecktypeDetails',
  AtplSkillTestRequired = 'AtplSkillTestRequired',
}

export class TrainingProfile {
  public operatingRegulation: string;

  /**
   * Provide the name of the ACP who will be completing the check ride
   */
  public acpCompletingCheckRideName?: string;

  public typeOfExaminer?: string;

  public hasPilotMonitoringTimeRequirements: boolean;

  public isOTETrainingRequired: boolean;

  public minimumGroundSchoolTime?: number;

  public minimumPFTime?: number;

  public minimumPMTime?: number;

  public minimumPPCTime?: number;

  constructor() {
    this.operatingRegulation = '704';
    this.typeOfExaminer = '';
    this.acpCompletingCheckRideName = null;
    this.hasPilotMonitoringTimeRequirements = false;
    this.isOTETrainingRequired = false;
    this.minimumGroundSchoolTime = null;
    this.minimumPFTime = null;
    this.minimumPMTime = null;
    this.minimumPPCTime = null;
  }
}
