import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TrainingRequirement } from 'src/app/models/TrainingRequirement';
import { Store, Select } from '@ngxs/store';
import { TrainingRequirementState } from 'src/app/state/trainingRequirement.state';
import { Observable } from 'rxjs';
import { PanelInfoState } from 'common-web-ui';

@Component({
  selector: 'app-pilot-information',
  templateUrl: './pilot-information.component.html',
  styleUrls: ['./pilot-information.component.less']
})
export class PilotInformationComponent implements OnInit {
  @Input() readonly: boolean;

  @Select(TrainingRequirementState.currentTrainingRequirement)
  currentTrainingRequirement$: Observable<TrainingRequirement>;

  @Input() state: PanelInfoState;

  constructor(private store: Store) {}

  ngOnInit() {}
}
