import { BlockBlobClient } from '@azure/storage-blob';
import { AppSettings } from 'src/app/models/AppSettings';
import { AppSettingsService } from 'common-web-core';
import { BaseDataService } from 'src/app/core/services/base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpErrorHandler } from 'src/app/app.error-handler';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { UpdateCustomTcaErrors } from 'src/app/state/trainingRequirement.actions';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService extends BaseDataService {
  uploadUrl;
  public message: string;
  constructor(http: HttpClient, 
              httpErrorHandler: HttpErrorHandler,
              appSettingsService: AppSettingsService<AppSettings>,
              private store: Store) {
    super(http, httpErrorHandler.createHandleError('AttachmentService'));

    if (!!appSettingsService.settings()) {
      this.uploadUrl = appSettingsService.settings().webApiSettings.api + '/tca';
    }
  }

  async upload(id: string, files: File[], isCustomTca: boolean) {

    files.forEach(async (file: File) => {
      const sasUri = await this.getBlobSASUri(id, file.name, isCustomTca);
      const blobClient = new BlockBlobClient(sasUri);
      const options = { blobHTTPHeaders: { blobContentType: file.type } };
      // upload file
      await blobClient.uploadData(file, options).then(() => {
        if (isCustomTca) {
          this.store.dispatch(new UpdateCustomTcaErrors(false));
        }
      });
    });
  }

  async getFiles(id: string, isCustomTca: boolean) {
    if (isCustomTca) {
      let files = [];
      const url = `${this.uploadUrl}/${id}/reports/custom/tca`;
      const hasCustomTca = await this.http.get<boolean>(url).toPromise();
      if (hasCustomTca) {
        files.push(new File([], "TCA file"));
      }
      this.store.dispatch(new UpdateCustomTcaErrors(files.length === 0));
      return files;
    } else {
      const url = `${this.uploadUrl}/${id}/attachments/tca`;
      const result = await this.http.get<{ name: string }[]>(url).toPromise();
      return result.map(x => new File([], x.name.replace(`${id}/tca/`, '')));
    }
  }

  async getAttachment(id: string, filename: string) {
    const url = `${this.uploadUrl}/${id}/attachments/tca/${filename}`;
    const result = await this.http.get<{ name: string, type: string, url: string }>(url).toPromise();

    // Get Blob
    const blobClient = new BlockBlobClient(result.url);
    const blobResult = await blobClient.download();
    return blobResult;
  }

  async deleteFile(id: string, key: string, isCustomTca: boolean) {
    const url = isCustomTca ? 
      `${this.uploadUrl}/${id}/reports/custom/tca` : 
      `${this.uploadUrl}/${id}/attachments/tca/${encodeURIComponent(key)}`;
    
    try {
      return await this.http.delete(url).toPromise().then(() => {
        if (isCustomTca) {
          this.store.dispatch(new UpdateCustomTcaErrors(true));
        }
      });
    } catch (e) {
      return false;
    }
  }

  async getBlobSASUri(id: string, filename: string, isCustomTca: boolean) {
    const url = isCustomTca ? 
      `${this.uploadUrl}/${id}/reports/custom/tca` : 
      `${this.uploadUrl}/${id}/attachments`;
    try {
      return await this.http.post<string>(url, isCustomTca ? null : { Name: filename, Type: 'tca' }).toPromise();
    } catch (e) {
      return '';
    }
  }
}
