import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AppSettingsService, AuthB2CService } from 'common-web-core';
import { AuthAuthorities, AuthHelper } from 'src/app/core/helpers/auth.helper';
import { ButtonAttractive, ButtonType, ThemeSkin } from 'common-web-ui';
import { Component, OnInit } from '@angular/core';

import { AppSettings } from 'src/app/models/AppSettings';

@Component({
  selector: 'app-login-selection-page',
  templateUrl: './login-selection-page.component.html',
  styleUrls: ['./login-selection-page.component.less']
})
export class LoginSelectionPageComponent implements OnInit {
  loginHint: string;
  authHelper: AuthHelper;
  readonly themeSkin = ThemeSkin;
  readonly buttonType = ButtonType;

  constructor(private router: Router, 
              private activatedRoute: ActivatedRoute, 
              authB2cService: AuthB2CService,
              private appSettingsService: AppSettingsService<AppSettings>,) {       
      this.authHelper = new AuthHelper(this.appSettingsService.settings().authAADSettings, authB2cService);             
  }

  ngOnInit() {    
    this.authHelper.currentAuthMode = AuthAuthorities.Unknown;
    this.loginHint = this.activatedRoute.snapshot.queryParams['login_hint'] || '';
  }

  onAuthWithAD() {
    this.router.navigate(['/login-azure-ad'], this.getNavigationParams());
  }

  onAuthWithB2C() {
    this.router.navigate(['/login-azure-b2c'], this.getNavigationParams());
  }

  onAuthWithSalesforce() {
    this.router.navigate(['/login-salesforce'], this.getNavigationParams());
  }

  private getNavigationParams(): NavigationExtras {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        login_hint: this.loginHint
      }
    };
    return navigationExtras;
  }
}
