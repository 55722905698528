import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { UpdateNote, LogAnalyticEvent } from 'src/app/state/trainingRequirement.actions';
import { TrainingRequirementState } from 'src/app/state/trainingRequirement.state';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { EditMode } from 'src/app/models/EditMode';
import { FormContentComponentLayout } from 'src/app/shared/components/form-content/form-content.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-note-page',
  templateUrl: './note-page.component.html',
  styleUrls: ['./note-page.component.less']
})
export class NotePageComponent implements OnInit, OnDestroy {
  public formContentComponentLayout = FormContentComponentLayout;
  note: string | null;
  mode: EditMode;

  @Select(TrainingRequirementState.note)
  note$: Observable<string>;
  subscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private router: Router,
    private location: Location
  ) {
    this.mode = EditMode.Edit;
  }

  ngOnInit() {
    this.subscription = this.note$.subscribe(note => {
      this.note = note;
    });
    this.store.dispatch(new LogAnalyticEvent('subpage', 'TCA Subpage Load', 'Note Page'));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.store.dispatch(new LogAnalyticEvent('subpage', 'TCA Subpage Unload', 'Note Page'));
  }

  onDelete() {
    this.updateNote(this.note, true);
    this.store.dispatch(new LogAnalyticEvent('subpage', 'TCA Subpage Delete', 'Note Page'));
    //this.router.navigate(['tca/' + this.route.snapshot.params.id]);
    this.location.back();
  }

  onCancel() {
    this.store.dispatch(new LogAnalyticEvent('subpage', 'TCA Subpage Back', 'Note Page'));
    //this.router.navigate(['tca/' + this.route.snapshot.params.id]);
    this.location.back();
  }

  onSave() {
    this.updateNote(this.note, false);
    this.store.dispatch(new LogAnalyticEvent('subpage', 'TCA Subpage Save', 'Note Page'));
    //this.router.navigate(['tca/' + this.route.snapshot.params.id]);
    this.location.back();
  }

  private updateNote(note: string, isDelete: boolean) {
    if (isDelete) {
      this.note = null;
    }
    this.store.dispatch(new UpdateNote(this.note));
  }

  formDirtyChanged(val: boolean) {
    if (val) {
      this.store.dispatch(new LogAnalyticEvent('subpage', 'TCA Subpage Start', 'Note Page'));
    }
  }
}
