<div class="preset-summary-item">
  <div class="item-title">{{item.title}}</div>
  <div class="item-summary">
    <div class="label">Description:</div>
    <div class="description">{{item.description ? item.description : 'No description provided'}}</div>
  </div>
  <ng-container *ngFor="let option of item.options">
    <div *ngIf="option.summary">
      <div class="item-summary">
        <div class="label">{{option.type}}:</div>
        <div class="description">{{option.summary}}</div>
      </div>
    </div>
  </ng-container>
</div>