import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';

import { AppState } from 'src/app/state/app.state';
import { AuthService } from '../services';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { RouterExtService } from '../services/router-ext.service';
import { Store } from '@ngxs/store';
import { ToggleFeatures } from 'src/app/data/toggle-features';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomHttpInterceptor implements HttpInterceptor {
  accessToken: string;
  
  constructor(
    private router: Router,
    private ngZone: NgZone,
    private localStorageService: LocalStorageService,
    private store: Store,
    private routerExtService: RouterExtService,
    private authService: AuthService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const cloned = req.clone({
      headers: req.headers,
    });
    return next.handle(cloned).pipe(
      tap(
        () => { },
        async (error) => {
          let routePrefix = 'tca';
          const returnUrl = this.authService.getReturnUrl();
          if (!returnUrl) {
            this.authService.setReturnUrl(this.routerExtService.getCurrentUrl());
          }
          routePrefix = !!returnUrl
            ? returnUrl.includes('tca')
              ? 'tca'
              : 'record-manager'
            : 'tca';
          switch (error.status) {
            case 401:
            case 403: {
              await this.navigateTo(`/${routePrefix}/accessDenied`);
              break;
            }
            case 404: {

              // Check that we are not requesting a pdf document. 
              // We will handle these in the respective controllers
              if (!error.url || !error.url.endsWith('/reports/paperversion')) {
                this.localStorageService.deleteFromSessionStorage('tcaId');
                await this.navigateTo(`/${routePrefix}/notFound`);
              }
              break;
            }
            case 500:
            default: {
              await this.navigateTo(`/${routePrefix}/unexpectedError`);
              break;
            }
          }
          return cloned;
        }
      )
    );
  }

  async navigateTo(url: string): Promise<void> {
    await this.ngZone.run(() => {
      return this.router.navigate([url]);
    });
  }
}
