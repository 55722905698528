import { Account, AppSettingsService, AuthB2CService } from 'common-web-core';

import { AppSettings } from 'src/app/models/AppSettings';
import { BaseDataService } from 'src/app/core/services/base.service';
import { HttpClient } from '@angular/common/http';
import { HttpErrorHandler } from 'src/app/app.error-handler';
import { Injectable } from '@angular/core';
import { User } from 'src/app/models/User';

@Injectable()
export class UserInfoService extends BaseDataService {
  constructor(http: HttpClient, 
              httpErrorHandler: HttpErrorHandler, 
              private b2cAuthService: AuthB2CService,
              appSettingsService: AppSettingsService<AppSettings>) 
  {
    super(http, httpErrorHandler.createHandleError('TcaService'));

    appSettingsService.appSettings$.subscribe((appSettings: AppSettings) => {
      this.resourceUrl = appSettings.webApiSettings.api+'/userinfo';      
    });
  }

  getUserInfo(callback) {

    if (this.b2cAuthService.isUserLoggedIn()) {
      this.b2cAuthService.getAccount().then((acc: Account) => {

        const user: User = { givenName: acc.firstName, name: `${acc.firstName} ${acc.lastName}`, email: acc.email, id: acc.userId, surname: acc.lastName }
        callback(user);
      }, this.handleHttpError)
    }
    else {
      return this.http.get(this.resourceUrl).subscribe(callback, this.handleHttpError);
    }


  }
}
