<form [hidden]="showWaitingScreen" class="form" #f="ngForm" (ngSubmit)="onSubmit(f.value)" novalidate>
  <div class="container">
    <div class="text">
      You can enter your email linked to CAETerminal or your CAE email (for employees only)
    </div>
    <input id="email" name="email" type="email" placeholder="email" ngModel required [email]="true"
      pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$" />
    <daui-button id="loginButton" class="login" [skin]="themeSkin.Alternative" [disabled]="f.invalid">Login
    </daui-button>
  </div>
</form>
<daui-waiting-panel [hidden]="!showWaitingScreen"></daui-waiting-panel>