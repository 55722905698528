<div class="action">
  <a class="download-link" #downloadLink></a>
  <daui-button class="view" [icon]="buttonIcon.View" [type]="buttonType.Flat" [attractive]="buttonAttractive.Regular"
    (click)="gotoViewPDF()">
    {{ files.length > 0 ? "View TCA (PDF)" : "View (PDF)" }}
  </daui-button>
  <div *ngIf="files.length > 0" class="attachments">
    <div *ngFor="let file of files" class="attachment">
      <daui-button class="item" [icon]="buttonIcon.Link" [type]="buttonType.Flat" (click)="downloadPDF(file.name)">
        VIEW ATTACHMENT {{removeFileExtension(file.name)}} (PDF)
      </daui-button>
    </div>
  </div>
  <daui-button class="edit" *ngIf="isEditable" [icon]="buttonIcon.Edit" [type]="buttonType.Flat"
    [attractive]="buttonAttractive.Regular" (click)="gotoEdit()">
    {{ files.length > 0 ? "Edit TCA" : "Edit" }}
  </daui-button>
</div>
<daui-dialog class="dialogEdit" [isShow]="isShowDialog">
  <div class="dialogEdit-wrapper">
    <div class="dialogEdit-wrapper-title">Edit a TCA</div>
    <div class="dialogEdit-wrapper-body">
      <daui-checkbox [mode]="checkboxContentMode.Simple" ngDefaultControl [(ngModel)]="isConfirmed"
        (change)="onConfirm($event)" [label]="label">
      </daui-checkbox>
    </div>
  </div>
  <daui-action-bar [hasSecondary]="true" [primaryDisabled]="!isConfirmed" primaryButtonText="Ok"
    secondaryButtonText="Cancel" (secondary)="onDialogCancel()" (primary)="onDialogOk()">
  </daui-action-bar>
</daui-dialog>

<daui-dialog class="dialogEdit" [isShow]="isShowDownloadDialog" [size]="dialogSize.Small">
  <div class="dialogEdit-wrapper">
    <div class="dialogEdit-wrapper-title">Generating PDF document...</div>
    <div class="dialogEdit-wrapper-body">
      PDF document is not yet available. Please try again later.
    </div>
  </div>
  <daui-action-bar [hasSecondary]="false" primaryButtonText="Ok" (primary)="onDownloadDialogCancel()">
  </daui-action-bar>
</daui-dialog>