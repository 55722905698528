import { Injectable } from "@angular/core";
import { AppConfigurationClient, ConfigurationSetting } from '@azure/app-configuration';
import { ToggleFeatures } from 'src/app/data/toggle-features';

@Injectable()
export class AzureAppConfigurationService {
  private client: AppConfigurationClient;

  initialize(connectionString: string) {
    return new Promise<void>((resolve, reject) => {
       this.client = new AppConfigurationClient(connectionString);
       resolve();
    });
  }

  public async getAllFlags(): Promise<{ [key: string]: boolean | string }> {
    const featureFlagPrefix: string = ".appconfig.featureflag";
    const featureFlags: string[] = Object.values(ToggleFeatures);
    const featureFlagMap: { [key: string]: boolean | string } = {};

    const settingsArray: ConfigurationSetting[] = [];
    const settings = this.client.listConfigurationSettings({ keyFilter: `*` });

    for await (const setting of settings) {
      settingsArray.push(setting);
    }

    settingsArray.sort((a, b) => a.key.localeCompare(b.key));

    for (const setting of settingsArray) {
      const key = setting.key.replace(`${featureFlagPrefix}/`, '');
      if (featureFlags.includes(key)) {
        if (setting.key.startsWith(featureFlagPrefix)) {
          featureFlagMap[key] = JSON.parse(setting.value).enabled;
        } else if (featureFlagMap[key]) {
          featureFlagMap[key] = setting.value;
        }
      }
    }

    return featureFlagMap;
  } 
}
