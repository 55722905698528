<app-form-content [layout]="formContentComponentLayout.SubPage">
  <ng-form #f="ngForm" appFormChangesWatcher (formDirtyChanged)="formDirtyChanged($event)">
    <div class="form-wrapper">
      <div class="content">
        <app-form-card>
          <h2>Notes</h2>
          <div class="subTitle">
            Please type any additional details for this training event.
          </div>

          <daui-form-field>
            <div messages></div>
            <div content>
              <textarea class="note" [(ngModel)]="note" name="notes"> </textarea>
            </div>
          </daui-form-field>

          <app-form-card-actions
            [isSaveEnabled]="f.dirty"
            [mode]="mode"
            (delete)="onDelete()"
            (cancel)="onCancel()"
            (save)="onSave()"
          ></app-form-card-actions>
        </app-form-card>
      </div>
    </div>
  </ng-form>
</app-form-content>
