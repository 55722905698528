import { ActivatedRoute, Router } from '@angular/router';
import {
  ButtonAttractive,
  ButtonIcon,
  ButtonType,
  PanelInfoState
} from 'common-web-ui';
import { Component, Input, OnInit } from '@angular/core';
import { DeleteLicence, LogAnalyticEvent } from 'src/app/state/trainingRequirement.actions';
import {
  FormFieldListItemMode,
  FormFieldListItemState
} from '../form-field-list-item/form-field-list-item.component';
import { Select, Store } from '@ngxs/store';
import { TrainingRequirement, TrainingType } from 'src/app/models/TrainingRequirement';

import { AppState } from 'src/app/state/app.state';
import { Authority } from 'src/app/models/Authority';
import { FormContentComponentLayout } from 'src/app/shared/components/form-content/form-content.component';
import { Licence } from '../../../models/Licence';
import { Observable } from 'rxjs';
import { RegulationAgencyName } from 'src/app/models/RegulationAgencyName';
import { TitleCasePipe } from '@angular/common';
import { ToggleFeatures } from 'src/app/data/toggle-features';
import { countryList } from 'src/app/data/countries';
import { LicenseCategory } from 'src/app/data/licenseCategory';

@Component({
  selector: 'app-licence',
  templateUrl: './licence.component.html',
  styleUrls: ['./licence.component.less']
})
export class LicenceComponent implements OnInit {
  public formContentComponentLayout = FormContentComponentLayout;
  _currentTrainingRequirement: TrainingRequirement | null;
  @Input()
  set currentTrainingRequirement(value: TrainingRequirement) {
    this._currentTrainingRequirement = value;
    this.updateLicences(this._currentTrainingRequirement);
  }

  @Input() state: PanelInfoState;
  @Input() readonly: boolean;

  protected panelInfoState = PanelInfoState;
  protected formFieldListItemMode = FormFieldListItemMode;
  protected formFieldListItemState = FormFieldListItemState;
  protected informations = [];
  countries = countryList;
  authority = Authority;
  licenceTypeCategory = LicenseCategory;
  readonly trainintType = TrainingType;
  isLicenceEnabled: { [key: string]: boolean } = {
    easa: true,
    ukcaa:true,
    faa: true,
    military: true,
    other: true,
    tc: true
  };
  licences = new Array<Licence>();

  readonly buttonIcon = ButtonIcon;
  readonly buttonType = ButtonType;
  readonly buttonAttractive = ButtonAttractive;
  readonly regulatoryAgencyName = RegulationAgencyName;

  @Select(AppState.isFeatureActive(ToggleFeatures.CustomerWebAppOtherLicense))
  isOtherLicenseFeatureEnabled$: Observable<boolean>;

  constructor(private route: ActivatedRoute, private router: Router, private store: Store, private titlecasePipe: TitleCasePipe) { }

  ngOnInit() {
  }

  goToLicenceEdit(number: string, authority: Authority) {
    if (!this.readonly) {
      switch (authority) {
        case Authority.EASA:
          this.store.dispatch(
            new LogAnalyticEvent('subpage', 'TCA Subpage Edit', 'EASA Licence Page')
          );
          this.router.navigate(['./easa-licence/' + number], { relativeTo: this.route });
          break;
        case Authority.UKCAA:
          this.store.dispatch(
            new LogAnalyticEvent('subpage', 'TCA Subpage Edit', 'UK CAA Licence Page')
          );
          this.router.navigate(['./ukcaa-licence/' + number], { relativeTo: this.route });
          break;
        case Authority.TC:
          this.store.dispatch(
            new LogAnalyticEvent('subpage', 'TC Subpage Edit', 'TC Licence Page')
          );
          this.router.navigate(['./tc-licence/' + number], { relativeTo: this.route });
          break;
        case Authority.FAA:
          this.store.dispatch(
            new LogAnalyticEvent('subpage', 'TCA Subpage Edit', 'FAA Licence Page')
          );
          this.router.navigate(['./faa-licence/' + number], { relativeTo: this.route });
          break;
        case Authority.Military:
          this.store.dispatch(
            new LogAnalyticEvent('subpage', 'TCA Subpage Edit', 'Military Licence Page')
          );
          this.router.navigate(['./military-licences/'], { relativeTo: this.route });
          break;
        case Authority.Other:
          this.store.dispatch(
            new LogAnalyticEvent('subpage', 'TCA Subpage Edit', 'Other Licence Page')
          );
          this.router.navigate(['./other-licence/' + number], { relativeTo: this.route });
          break;
        default:
          this.store.dispatch(new LogAnalyticEvent('subpage', 'TCA Subpage Edit', 'Licence Page'));
          this.router.navigate(['./licence/' + number], { relativeTo: this.route });
          break;
      }
    }
  }

  showPBN(license: Licence): boolean {

    if (!license.authority) {
      return false;
    }

    return license.authority.toLowerCase() !== this.authority.FAA &&
      license.authority.toLowerCase() !== this.authority.Military &&
      license.authority.toLowerCase() !== this.authority.TC &&
      (!this._currentTrainingRequirement || this._currentTrainingRequirement.regulationAgency.toString() !== this.authority.TC);
  }

  showSubtitle() {
    return this._currentTrainingRequirement ? this._currentTrainingRequirement.regulationAgency.toString() === this.authority.TC : false;
  }

  getLabel(licence: Licence): string {

    if (licence.authority === this.authority.TC) {

      return 'Transport Canada License';
    }
    else if (licence.authority === this.authority.UKCAA){
      return 'UK CAA'
    }
    else {
      return !licence.authority
        ? 'License'
        : (licence.authority.toLocaleLowerCase() === this.authority.Military ||
          licence.authority.toLocaleLowerCase() === this.authority.Other
          ? this.titlecasePipe.transform(licence.authority)
          : licence.authority.toLocaleUpperCase()) +
        (licence.authority.toLocaleLowerCase() === this.authority.FAA ? ' Certificate' : ' License');
    }
  }

  getNoLicenceErrorMessage(): string {
    if (this._currentTrainingRequirement && RegulationAgencyName.TC === this._currentTrainingRequirement.regulationAgency) {
      return 'You must at least add your TC license information';
    } else {
      return 'You must provide at least one license. Click the button below to add a license.';
    }
  }

  /**
   * Show an error message if
   * 1. It is a TC Form and no TC license was provided
   * 2. It is another form but no licenses where provided
   */
  showErrorMessage() {

    if (this._currentTrainingRequirement) {
      if (this._currentTrainingRequirement && RegulationAgencyName.TC === this._currentTrainingRequirement.regulationAgency) {
        return this._currentTrainingRequirement.licences.findIndex(x => x.authority === Authority.TC) === -1;
      } else {
        return this._currentTrainingRequirement.licences.length === 0;
      }
    } else {
      return true;
    }

  }

  goToLicenceAdd(authority: string) {
    switch (authority) {
      case Authority.EASA:
        this.store.dispatch(
          new LogAnalyticEvent('subpage', 'TCA Subpage Add', 'EASA Licence Page')
        );
        this.router.navigate(['./easa-licences'], { relativeTo: this.route });
        break;
      case Authority.UKCAA:
        this.store.dispatch(
          new LogAnalyticEvent('subpage', 'UK TCA Subpage Add', 'UK CAA Licence Page')
        );
        this.router.navigate(['./ukcaa-licences'], { relativeTo: this.route });
        break;
      case Authority.TC:
        this.store.dispatch(
          new LogAnalyticEvent('subpage', 'TC Subpage Add', 'TC Licence Page')
        );
        this.router.navigate(['./tc-licences'], { relativeTo: this.route });
        break;
      case Authority.FAA:
        this.store.dispatch(new LogAnalyticEvent('subpage', 'TCA Subpage Add', 'FAA Licence Page'));
        this.router.navigate(['./faa-licences'], { relativeTo: this.route });
        break;
      case Authority.Military:
        this.store.dispatch(
          new LogAnalyticEvent('subpage', 'TCA Subpage Add', 'Military Licence Page')
        );
        this.router.navigate(['./military-licences'], { relativeTo: this.route });
        break;
      case Authority.Other:
        this.store.dispatch(
          new LogAnalyticEvent('subpage', 'TCA Subpage Add', 'Other Licence Page')
        );
        this.router.navigate(['./other-licences'], { relativeTo: this.route });
        break;
      default:
        this.store.dispatch(new LogAnalyticEvent('subpage', 'TCA Subpage Add', 'Licence Page'));
        this.router.navigate(['./licences'], { relativeTo: this.route });
        break;
    }
  }

  getState(licence: Licence) {
    if (licence.authority !== Authority.FAA && licence.authority !== Authority.Military) {
      this.informations = [];
      const expiryDate = Date.parse(licence.expiryDate);
      const dateNow = Date.now();

      if (expiryDate < dateNow) {
        this.informations.push('Rating is expired. Your AE will contact you for more information.');
        return FormFieldListItemState.Warning;
      }
    }
    return FormFieldListItemState.Default;
  }

  updateLicences(trainingRequirement: TrainingRequirement) {
    this.licences = new Array<Licence>();
    const licencesOrder = [null, '', 'tc', 'easa', 'ukcaa', 'faa', 'military', 'other'];

    if (trainingRequirement.regulationAgency === Authority.TC.toString()) {
      this.isLicenceEnabled[Authority.Military] = false;
    }

    if (trainingRequirement.regulationAgency !== Authority.TC.toString()) {
      this.isLicenceEnabled[Authority.TC] = false;
    }


    licencesOrder.forEach(authority => {
      const foundLicences = trainingRequirement.licences.filter(licence => licence.authority === authority);
      if (!!foundLicences && foundLicences.length > 0) {
        this.isLicenceEnabled[authority] = false;
        this.licences.push(...foundLicences);
      }
    });

    // Update licences control for errors
  }

  isFaaRegulated() {
    if (!!this._currentTrainingRequirement) {
      return this._currentTrainingRequirement.regulationAgency === RegulationAgencyName.FAA;
    } else {
      return false;
    }
  }

  onDelete(authority: Authority) {
    this.licences = new Array<Licence>();
    const licenceToBeDeleted: Licence[] = this._currentTrainingRequirement.licences.filter(
      licence => licence.authority === authority
    );
    this.store.dispatch(new DeleteLicence(licenceToBeDeleted[0].id));
    this.isLicenceEnabled[authority] = true;
    this.store.dispatch(new LogAnalyticEvent('subpage', 'TCA Subpage delete', 'Licence Page'));
  }
}
