import { ActivatedRoute, Router } from '@angular/router';
import { AddPreset, GetPresetsSummary } from 'src/app/state/preset.actions';
import { ButtonAttractive, ButtonIcon, ButtonType, DialogSize } from 'common-web-ui';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Select, Store } from '@ngxs/store';

import { Authority } from 'src/app/models/Authority';
import { LogAnalyticEvent } from 'src/app/state/trainingRequirement.actions';
import { Observable } from 'rxjs';
import { Preset } from 'src/app/models/Preset';
import { PresetState } from 'src/app/state/preset.state';
import { PresetType } from 'src/app/models/PresetType';
import { TrainingRequirement } from 'src/app/models/TrainingRequirement';
import { withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-save-preset',
  templateUrl: './save-preset.component.html',
  styleUrls: ['./save-preset.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SavePresetComponent {

  isSavePresetEnabled = false;
  presetDescription = '';
  presetTitle = '';
  isShowPresetDialog = false;
  currentPresetType: PresetType = PresetType.Global;
  size = DialogSize.Small;
  maxChars = 100;

  @Input()
  currentTrainingRequirement: TrainingRequirement;
  @Input()
  isTcaLocked: boolean;
  @Input()
  authority: Authority;
  readonly buttonType = ButtonType;
  readonly buttonAttractive = ButtonAttractive;
  readonly buttonIcon = ButtonIcon;
  readonly dialogActionsAttractiveType = ButtonAttractive;

  @Select(PresetState.addPreset)
  presets$: Observable<Preset[]>;



  constructor(private route: ActivatedRoute,
    private store: Store,
    private router: Router,
  ) { }

  onPresetDialogCancel(inputPresetTitle: any, inputPresetDescription: any) {
    inputPresetTitle.value = this.currentTrainingRequirement.course;
    inputPresetDescription.value = '';
    this.isShowPresetDialog = false;
    this.store.dispatch(new LogAnalyticEvent('regular', 'TCA Preset Popup Cancel'));
  }

  showPresetDialog() {
    this.isSavePresetEnabled = false;
    this.presetDescription = '';


    if (!!this.currentTrainingRequirement) {
      this.presetTitle = this.currentTrainingRequirement.course;
      this.isSavePresetEnabled = true;
    }

    if (!this.isTcaLocked) {
      this.isShowPresetDialog = true;
    } else {
      this.router.navigate(['./locked'], { relativeTo: this.route });
    }

    this.store.dispatch(new LogAnalyticEvent('regular', 'TCA Preset Save Options'));
  }

  onPresetSave(inputPresetTitle: string, inputPresetDescription: string, type: PresetType) {
    this.presetTitle = inputPresetTitle;
    this.presetDescription = inputPresetDescription;

    if (this.presetTitle.trim() !== '') {
      const preset = new Preset();
      preset.title = this.presetTitle;
      preset.description = this.presetDescription;
      preset.lastUsed = new Date();
      preset.presetType = type;
      preset.aircraftId = this.currentTrainingRequirement.aircraftId;
      preset.courseId = this.currentTrainingRequirement.courseId;
      preset.clientId = this.currentTrainingRequirement.clientId;
      preset.authority = this.authority;
      preset.checkingAddons = this.currentTrainingRequirement.checkingAddons;
      preset.trainingAddons = this.currentTrainingRequirement.trainingAddons;
      preset.specialAirports = this.currentTrainingRequirement.specialAirports;
      preset.note = this.currentTrainingRequirement.note;

      if (type == PresetType.Private) {
        preset.clientName = this.currentTrainingRequirement.client;
        preset.licences = this.currentTrainingRequirement.licences;
      }

      this.store
        .dispatch(new AddPreset(this.currentTrainingRequirement.customerId, preset))
        .pipe(withLatestFrom(this.presets$))
        .subscribe(([_, presets]) => {
          if (!!presets && presets.length > 0) {
            this.store.dispatch(
              new GetPresetsSummary(
                this.currentTrainingRequirement.customerId,
                this.currentTrainingRequirement.clientId,
                this.authority
              )
            );
          }
        });
    }
  }

  onPresetTitleChange(event: any) {
    if (!this.isSavePresetEnabled && event.target.value !== '') {
      this.isSavePresetEnabled = true;
      return;
    }

    if (this.isSavePresetEnabled && event.target.value === '') {
      this.isSavePresetEnabled = false;
    }
  }

  onPresetDialogSave(inputPresetTitle: any, inputPresetDescription: any) {
    this.isShowPresetDialog = false;
    this.onPresetSave(inputPresetTitle.value, inputPresetDescription.value, this.currentPresetType);
    this.store.dispatch(new LogAnalyticEvent('regular', 'TCA Preset Popup Save'));
  }

  toggleSaveOption(isChecked: boolean, presetType: string) {

    if (isChecked)
      this.currentPresetType = presetType as PresetType;
  }

  hasTrainingOptions() {
    let hasOptions = false;

    if (this.currentTrainingRequirement) {
      if (this.currentTrainingRequirement.note) {
        return true;
      }

      this.currentTrainingRequirement.checkingAddons.forEach(addon => {
        if (addon) {
          addon.options.forEach(option => {
            if (option.value) {
              hasOptions = true;
              return;
            }
          });
        }
      });

      this.currentTrainingRequirement.trainingAddons.forEach(training => {
        if (training) {
          training.options.forEach(option => {
            if (option.value) {
              hasOptions = true;
              return;
            }
          });
        }
      });
    }

    return hasOptions;
  }

}
