import * as environment from 'src/environments/environment';

import { Component } from '@angular/core';

import { BaseUIComponent } from '../base-ui-component';

@Component({
  selector: 'app-form-footer',
  templateUrl: './form-footer.component.html',
  styleUrls: ['./form-footer.component.less']
})
export class FormFooterComponent extends BaseUIComponent {
  buildNumber: string;

  constructor() {
    super();
  }

  onInit() {
    this.buildNumber = environment.buildNumber;
  }

  onDestroy() {}
}
