import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-whats-new-badge',
  templateUrl: './whats-new-badge.component.html',
  styleUrls: ['./whats-new-badge.component.less']
})
export class WhatsNewBadgeComponent implements OnInit {

  @Output() clicked = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  onClick() {
    this.clicked.emit();
  }

}
