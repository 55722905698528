import { AnalyticsBaseProperties } from '../models/AnalyticsBaseProperties';
import { AnalyticsProperties } from '../models/AnalyticsProperties';
import { AppInsightService } from '../core/services/app-insight-service';
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export abstract class MessageGenericService {
  public appInsightService: AppInsightService;
  public datePipe: DatePipe;

  constructor(_appInsightService: AppInsightService, _datePipe: DatePipe) {
    this.appInsightService = _appInsightService;
    this.datePipe = _datePipe;
  }

  abstract pushAnalytics(type: string, analyticMessage: AnalyticsBaseProperties): void;
  abstract push(type: string, message: string): void;
  abstract pushException(error: Error): void;
}
