<div class="actions" *ngIf="mode !== editMode.OkCancel">
  <div class="advance">
    <daui-button [icon]="buttonIcon.Delete" [type]="buttonType.Flat" [attractive]="buttonAttractive.Heavy"
      (click)="onDelete()"></daui-button>
  </div>
</div>
<daui-dialog class="dialogEdit" [isShow]="isShowDialogDelete">
  <div class="dialogEdit-wrapper">
    <div class="dialogEdit-wrapper-body">
      Are you sure you want<br />
      to delete?
    </div>
  </div>
  <daui-action-bar [hasSecondary]="true" primaryButtonText="Delete" secondaryButtonText="Cancel"
    (secondary)="onDialogDeleteCancel()" (primary)="onDialogDeleteOk()">
  </daui-action-bar>
</daui-dialog>