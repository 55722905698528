import { ButtonAttractive, ButtonIcon, ButtonType } from 'common-web-ui';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BaseUIComponent } from '../base-ui-component';
import { EditMode } from 'src/app/models/EditMode';

export type FormCardActionsLayout = 'save' | 'save-delete' | 'send';
@Component({
  selector: 'app-form-card-actions',
  templateUrl: './form-card-actions.component.html',
  styleUrls: ['./form-card-actions.component.less']
})
export class FormCardActionsComponent extends BaseUIComponent {
  @Input() isSaveEnabled: boolean;
  @Input() mode = EditMode.Edit;
  @Input() layout: FormCardActionsLayout = 'save';
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() send: EventEmitter<any> = new EventEmitter();

  public editMode = EditMode;

  isShowDialogDelete: boolean;
  isShowDialogSaveDelete: boolean;

  readonly dialogActionsLayout: FormCardActionsLayout;
  readonly buttonType = ButtonType;
  readonly buttonAttractive = ButtonAttractive;
  readonly buttonIcon = ButtonIcon;

  constructor() {
    super();
  }

  onInit() { }
  onDestroy() { }

  onDialogDeleteCancel() {
    this.isShowDialogDelete = false;
    this.isShowDialogSaveDelete = false;
  }

  onDialogDeleteOk() {
    this.delete.emit();
    this.isShowDialogDelete = false;
    this.isShowDialogSaveDelete = false;
  }

  onDelete() {
    this.isShowDialogDelete = true;
  }

  onCancel() {
    this.cancel.emit();
  }

  onSave() {
    if (this.layout === 'save-delete') {
      this.isShowDialogSaveDelete = true;
    } else {
      this.save.emit();
    }
  }
  onSend() {
    this.send.emit();
  }
}
