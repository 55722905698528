<daui-form-field class="disclaimer-form-field" [state]="confirmationState" [size]="formFieldSize.Inherit">
  <div messages>
    <div id="confirmationRequired">
      {{ errorMessage }}
    </div>
  </div>
  <div content class="disclaimer">
    <div class="container">
      <daui-checkbox ngDefaultControl [(ngModel)]="isConfirmed" (change)="onConfirm($event)"
        [mode]="checkboxContentMode.Advanced" [state]="confirmationState">
        <div content>
          <ng-content select="[content]"></ng-content>
        </div>
      </daui-checkbox>
    </div>
  </div>
</daui-form-field>
<daui-dialog class="dialogEdit" [isShow]="isShowDialog">
  <div class="dialogEdit-wrapper">
    <div class="dialogEdit-wrapper-title">Disclaimers</div>
    <div class="dialogEdit-wrapper-body">
      <p *ngFor="let paragraph of disclaimerParagraphs">
        {{ paragraph }}
      </p>
    </div>
    <daui-action-bar [hasSecondary]="false" primaryButtonText="Close" (primary)="onDialogClose()">
    </daui-action-bar>
  </div>
</daui-dialog>