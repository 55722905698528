import { AnalyticsBaseProperties } from './AnalyticsBaseProperties';

export class AnalyticsProperties extends AnalyticsBaseProperties {
  public customer_id: string;
  public client_id: string;
  public editor_user_id: string;
  public res_num: string;
  public platform: string;
  public revision_number: number;
  public app_version: string;
  public approver_role: string;

  constructor(init?: Partial<AnalyticsProperties>) {
    super();
    if (!!init) {
      Object.assign(this, init);
    } else {
      this.app_name = 'TCAWebapp';
      this.user_id = '';
      this.customer_id = '';
      this.client_id = '';
      this.editor_user_id = '';
      this.res_num = '';
      this.platform = '';
      this.revision_number = 0;
      this.app_version = '';
      this.approver_role = '';
    }
  }
}

export class FormVisibilityAnalyticsProperties extends AnalyticsProperties {
  status: string;
  public constructor(init?: Partial<FormVisibilityAnalyticsProperties>) {
    super();
    Object.assign(this, init);
  }
}
export class SubpageAnalyticsProperties extends AnalyticsProperties {
  subpage_name: string;
  public constructor(init?: Partial<SubpageAnalyticsProperties>) {
    super();
    Object.assign(this, init);
  }
}

export class ErrorMessageAnalyticsProperties extends AnalyticsProperties {
  page_name: string;
  error_name: string;
  error_message: string;
  public constructor(init?: Partial<ErrorMessageAnalyticsProperties>) {
    super();
    Object.assign(this, init);
  }
}

export class WarningMessageAnalyticsProperties extends AnalyticsProperties {
  page_name: string;
  warning_name: string;
  warning_message: string;
  public constructor(init?: Partial<WarningMessageAnalyticsProperties>) {
    super();
    Object.assign(this, init);
  }
}
