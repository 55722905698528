import { SessionItem } from './SessionItem';
import { ModuleTypes } from '../data/module-types';

export class ScheduleSessionCommand {
  RevisionNumber: number;
  ModuleType: ModuleTypes;
  Sessions: SessionItem[];

  constructor() {
    this.Sessions = [];
  }
}
