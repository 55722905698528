<div class="content">
  <p class="etca-toggle-header">Select how you wish to proceed.</p>
  <div class="etca-toggle">
      <button (click)="useEtca()" [class.disabled]="this.showEtca" [class.enabled]="!this.showEtca">Use eTCA</button>
      <button (click)="useCustomTca()" [class.disabled]="this.showCustomTca" [class.enabled]="!this.showCustomTca">Upload TCA manually</button>
  </div>

  <div class="etca-toggle-description">
      <p>This is the preferred way to submit your TCA Form.</p>
      <p>Use this option to submit your custom TCA Form.</p>
  </div>
</div>
<daui-dialog class="dialogEdit" [isShow]="this.showDialogEtca">
  <div class="dialogEdit-wrapper">
    <div class="dialogEdit-wrapper-body">
      Are you sure you want to use the eTCA form? <br />
      Your manually uploaded form will be lost.
    </div>
  </div>
  <daui-action-bar [hasSecondary]="true" primaryButtonText="Confirm" secondaryButtonText="Cancel"
    (secondary)="onDialogCancel()" (primary)="onDialogShowEtcaOk()">
  </daui-action-bar>
</daui-dialog>
<daui-dialog class="dialogEdit" [isShow]="this.showDialogCustomTca">
  <div class="dialogEdit-wrapper">
    <div class="dialogEdit-wrapper-body">
      Are you sure you want to manually upload the TCA form? <br />
      All training and checking options provided in the eTCA will be lost.
    </div>
  </div>
  <daui-action-bar [hasSecondary]="true" primaryButtonText="Confirm" secondaryButtonText="Cancel"
    (secondary)="onDialogCancel()" (primary)="onDialogShowCustomTcaOk()">
  </daui-action-bar>
</daui-dialog>