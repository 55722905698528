export enum Authority {
  EASA = 'easa',
  UKCAA= 'ukcaa',
  TC = 'tc',
  FAA = 'faa',
  Military = 'military',
  Other = 'other',
  None = '',
  Generic = 'generic'
}
