import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HeapService {

  initializeHeap(heapApiKey) {
    if (!!heapApiKey) {
      ((<any>window).heap = (<any>window).heap || []),
        ((<any>window).heap.load = function (e, t) {
          ((<any>window).heap.appid = e), ((<any>window).heap.config = t = t || {});
          const r = t.forceSSL || 'https:' === document.location.protocol,
            a = document.createElement('script');
          (a.type = 'text/javascript'),
            (a.async = !0),
            (a.src = (r ? 'https:' : 'http:') + '//cdn.heapanalytics.com/js/heap-' + e + '.js');
          const n = document.getElementsByTagName('script')[0];
          n.parentNode.insertBefore(a, n);
          for (
            let o = function (e) {
              return function () {

                if ((<any>window).heap.push) {
                  (<any>window).heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
                }

              };
            },
            p = [
              'addEventProperties',
              'addUserProperties',
              'clearEventProperties',
              'identify',
              'resetIdentity',
              'removeEventProperty',
              'setEventProperties',
              'track',
              'unsetEventProperty'
            ],
            c = 0;
            c < p.length;
            c++
          ) {
            (<any>window).heap[p[c]] = o(p[c]);
          }
        });
      (<any>window).heap.load(heapApiKey, {
        disableTextCapture: true
      });
    }
  }

  logReservationId(id: string) {
    if ((<any>window).heap) {
      (<any>window).heap.addUserProperties({ 'ReservationId': id });
    }
  }
}
