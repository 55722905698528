<div class="wrapper">
  <div class="client-name">
    {{ (currentTrainingRequirement$ | async)?.client }}
  </div>

  <div class="client-info">
    <div id="customerName" class="client-info-customerName">
      {{ (currentTrainingRequirement$ | async)?.customer }}
    </div>
    <div
      id="certificate-holder"
      class="client-info-schedulerReservationId"
      *ngIf="regulationName === regulationAgencyName"
    >
      Certificate Holder: {{ (currentTrainingRequirement$ | async)?.certificateHolder }}
    </div>
    <div
      id="certificate-holder-code"
      class="client-info-schedulerReservationId"
      *ngIf="regulationName === regulationAgencyName"
    >
      Certificate Holder Code: {{ (currentTrainingRequirement$ | async)?.certificateHolderCode }}
    </div>
    <div class="client-info-schedulerReservationId">
      <div>Reservation #{{ (currentTrainingRequirement$ | async)?.schedulerReservationId }}</div>
    </div>
  </div>
</div>
