import { AnalyticsRecordManagerProperties } from './AnalyticsRecordManagerProperties';
import { AppInsightsBaseProperties } from './AppInsightsBaseProperties';

export class AppInsightsRecordManagerProperties extends AppInsightsBaseProperties {
  analytics_user_id: string;
  analytics_contact_id: string;
  analytics_online: boolean;
  analytics_event_type: string;
  analytics_session_id: string;

  constructor() {
    super();

    this.app_name = 'CustomerWebApp';
    this.page_name = '';
    this.message = '';
    this.CustomerWebApp = '';

    this.analytics_user_id = '';
    this.analytics_contact_id = '';
    this.analytics_online = false;
    this.analytics_event_type = '';
    this.analytics_session_id = '';
  }

  public static mapWith(
    appInsightsProperties: AppInsightsRecordManagerProperties,
    analyticMessage: AnalyticsRecordManagerProperties
  ): AppInsightsRecordManagerProperties {
    appInsightsProperties.analytics_user_id = analyticMessage.user_id;
    appInsightsProperties.analytics_session_id = analyticMessage.session_id;
    appInsightsProperties.analytics_online = analyticMessage.online;
    appInsightsProperties.analytics_event_type = analyticMessage.event_type;
    appInsightsProperties.analytics_contact_id = analyticMessage.contact_id;
    return appInsightsProperties;
  }
}
