export const countryList = [
  { name: 'Austria', id: 'AT' },
  { name: 'Belgium', id: 'BE' },
  { name: 'Bulgaria', id: 'BG' },
  { name: 'Croatia', id: 'HR' },
  { name: 'Cyprus', id: 'CY' },
  { name: 'Czech Republic', id: 'CZ' },
  { name: 'Denmark', id: 'DK' },
  { name: 'Estonia', id: 'EE' },
  { name: 'Finland', id: 'FI' },
  { name: 'France', id: 'FR' },
  { name: 'Germany', id: 'DE' },
  { name: 'Greece', id: 'GR' },
  { name: 'Hungary', id: 'HU' },
  { name: 'Iceland', id: 'IS' },
  { name: 'Ireland', id: 'IE' },
  { name: 'Italy', id: 'IT' },
  { name: 'Latvia', id: 'LV' },
  { name: 'Liechtenstein', id: 'LI' },
  { name: 'Lithuania', id: 'LT' },
  { name: 'Luxembourg', id: 'LU' },
  { name: 'Malta', id: 'MT' },
  { name: 'Netherlands', id: 'NL' },
  { name: 'Norway', id: 'NO' },
  { name: 'Poland', id: 'PL' },
  { name: 'Portugal', id: 'PT' },
  { name: 'Romania', id: 'RO' },
  { name: 'Slovakia', id: 'SK' },
  { name: 'Slovenia', id: 'SI' },
  { name: 'Spain', id: 'ES' },
  { name: 'Sweden', id: 'SE' },
  { name: 'Switzerland', id: 'CH' },
  { name: 'United Kingdom', id: 'GB' }
];
