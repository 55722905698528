import { DeviceDetectorService } from 'ngx-device-detector';

import { Component, Input } from '@angular/core';

import { BaseUIComponent } from '../base-ui-component';
import { HeaderService } from 'src/app/core/services/header.service';

export enum FormContentComponentLayout {
  Default,
  SubPage
}

@Component({
  selector: 'app-form-content',
  templateUrl: './form-content.component.html',
  styleUrls: ['./form-content.component.less']
})
export class FormContentComponent extends BaseUIComponent {
  @Input() layout: FormContentComponentLayout = FormContentComponentLayout.Default;

  public formContentComponentLayout = FormContentComponentLayout;
  isMobileSubpage: boolean;

  constructor(private deviceService: DeviceDetectorService, private headerService: HeaderService) {
    super();
    this.headerService.showHeader();
  }

  onInit() {
    this.isMobileSubpage =
      this.deviceService.isMobile() && this.layout === FormContentComponentLayout.SubPage;
  }

  onDestroy() {}
}
