import { Subscription } from 'rxjs';
import { OnDestroy, OnInit, Directive } from '@angular/core';

@Directive()
export abstract class BaseUIComponent implements OnInit, OnDestroy {
  subscriptions: Array<Subscription> = [];

  constructor() {}

  ngOnDestroy(): void {
    this.onDestroy();
    if (this.subscriptions) {
      this.subscriptions.forEach(sub => sub.unsubscribe());
    }
  }

  ngOnInit(): void {
    this.onInit();
  }

  abstract onInit(): void;

  abstract onDestroy(): void;

  subscribe(subscription: Subscription) {
    this.subscriptions.push(subscription);
  }
}
