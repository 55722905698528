import { initialize, LDClient, LDUser, LDOptions } from 'ldclient-js';
import { Subject } from 'rxjs';
import { Feature } from '../../models/Feature';
import { Injectable } from "@angular/core";

@Injectable()
export class LaunchDarklyService {
  ldClient: LDClient;

  public featureValueChanged = new Subject<Feature>();

  initialize(envKey: string, user: LDUser, options?: LDOptions) {
    return new Promise<void>((resolve, reject) => {
      this.ldClient = initialize(envKey, user, options);
      this.ldClient.on('ready', () => {
        this.onReady();
        resolve();
      });
    });
  }

  async identify(user: LDUser) {
    await this.ldClient.identify(user);
  }

  getFlag(featureName: string) {
    return this.ldClient.variation(featureName);
  }

  getAllFlags() {
    const allFlags = this.ldClient.allFlags();
    const features = new Array<Feature>();
    for (const feature of Object.keys(allFlags)) {
      features.push(new Feature(feature, allFlags[feature]));
    }
    return features;
  }

  private onReady() {
    this.ldClient.on('change', settings => {
      this.onFeatureFlagsChanged(settings);
    });
  }

  private onFeatureFlagsChanged(settings) {
    for (const feature of Object.keys(settings)) {
      this.featureValueChanged.next(new Feature(feature, settings[feature].current));
    }
  }
}
