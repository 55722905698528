import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { handleHttpError } from 'src/app/app.error-handler';

export abstract class BaseDataService {
  public resourceUrl: string;
  public handleHttpError: handleHttpError;

  protected http: HttpClient;

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(http: HttpClient,              
              handleError: handleHttpError) 
  {    
    this.http = http;     
    this.handleHttpError = handleError;    
  }
  
}
