import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanDeactivate,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { EasaTcaComponent } from 'src/app/tca/tca-form/pages';

export interface PendingChangesComponent {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class PendingChangesGuard implements CanDeactivate<EasaTcaComponent> {
  canDeactivate(
    component: EasaTcaComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (component.canDeactivate()) {
      if (this.canBlockChanges(nextState)) {
        return confirm(
          'Changes you made may not be saved. Press Cancel to go back and save these changes, or OK to lose these changes.'
        );
      }
    }
    return true;
  }

  private canBlockChanges(nextState: RouterStateSnapshot): boolean {
    if (nextState.root.firstChild !== undefined && nextState.root.firstChild.data !== undefined) {
      return nextState.root.firstChild.data['pendingChangesCheck'] === true;
    }
    return false;
  }
}
