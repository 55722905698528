import { LogAppender } from './log-appender';
import { AppInsightsService } from '@markpieszak/ng-application-insights';
import { AppInsightsSeverityLevel } from './app-insights-severity-level.enum';
import { LogLevel } from './log-level.enum';

export class AppInsightsLogAppender extends LogAppender {
  constructor(private appInsightsService: AppInsightsService) {
    super();
  }

  setLogLevel(logLevel: LogLevel) {
    this.logLevel = logLevel;
  }

  /**
   * Log to AppInsights.
   * @param logLevel The severity level to use. Minimum permitted will be the one from the AppSettings
   */
  log(logLevel: LogLevel, message: any, ...additional: any[]) {
    if (logLevel >= this.logLevel) {
      const properties: { [name: string]: string } = {};
      additional.forEach((additionalMessage, index) => {
        properties[index] = additionalMessage;
      });
      this.appInsightsService.trackTrace(message, properties, this.getLogLevel(logLevel) as number);
    }
  }

  private getLogLevel(logLevel: LogLevel): AppInsightsSeverityLevel {
    switch (logLevel) {
      case LogLevel.Trace:
        return AppInsightsSeverityLevel.Verbose;
      case LogLevel.Debug:
        return AppInsightsSeverityLevel.Verbose;
      case LogLevel.Info:
        return AppInsightsSeverityLevel.Information;
      case LogLevel.Log:
        return AppInsightsSeverityLevel.Information;
      case LogLevel.Log:
        return AppInsightsSeverityLevel.Warning;
      case LogLevel.Error:
        return AppInsightsSeverityLevel.Error;
      case LogLevel.Fatal:
        return AppInsightsSeverityLevel.Critical;
      default:
        return AppInsightsSeverityLevel.Information;
    }
  }
}
