import { AppSettings } from 'src/app/models/AppSettings';
import { AppSettingsService } from 'common-web-core';
import { AzureAppConfigurationService } from './azure-app-configuration.service';
import { Feature } from 'src/app/models/Feature';
import { Injectable } from '@angular/core';
import { LaunchDarklyService } from './launch-darkly.service';
import { Store } from '@ngxs/store';
import { ToggleFeatures } from 'src/app/data/toggle-features';
import { UpdateFeature } from 'src/app/state/app.state.action';
import { WaitingPanelService } from 'src/app/core/services';

@Injectable()
export class FeatureFlagService {
  constructor(private store: Store, 
              private launchDarklyService: LaunchDarklyService,
              private azureAppConfigurationService: AzureAppConfigurationService,
              private waitingPanelService: WaitingPanelService,
              private appSettingsService: AppSettingsService<AppSettings>) { }

  public async initialize() {
    this.waitingPanelService.show();
    let azureFeatureFlags = {};

    try {
      await this.azureAppConfigurationService.initialize(this.appSettingsService.settings().featureFlagSettings.azureAppConfigurationConnectionString);
      azureFeatureFlags = await this.azureAppConfigurationService.getAllFlags();
    } catch { }
    
    return this.launchDarklyService
      .initialize(this.appSettingsService.settings().featureFlagSettings.launchDarklyApiKey, {
        key: 'anon',
        anonymous: true
      })
      .then(() => {
        Object.keys(ToggleFeatures).forEach(featureKey => {
          const featureName = ToggleFeatures[featureKey];
          let featureValue: boolean | string | null = azureFeatureFlags[featureName];

          if (featureValue == null) {
            featureValue = this.launchDarklyService.getFlag(featureName);
          }

          this.store.dispatch(new UpdateFeature(new Feature(featureName, featureValue)));
        });

        this.launchDarklyService.featureValueChanged.subscribe(feature => {
          if (azureFeatureFlags[feature.name] == null) {
            this.store.dispatch(new UpdateFeature(feature));
          }
        });
      }).finally(() => {
        this.waitingPanelService.hide();
      });
  }

  public changeUser(user) {
    if (user !== 'Anonymous') {
      this.launchDarklyService.identify({
        key: user.email,
        name: user.name,
        email: user.email,
        anonymous: false,
        custom: user.custom ? user.custom : {}
      });
    } else {
      this.launchDarklyService.identify({ key: 'anon', anonymous: true });
    }
  }
}
