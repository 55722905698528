export class User {
  id: string;
  email: string;
  name: string;
  givenName: string;
  surname: string;
}

export enum UserRoles {
  AccountExecutive = 'AE',
  TrainingManager = 'TM',
  Scheduler = 'SC',
  Customer = 'Customer'
}
