export class AccountExecutiveApproveCommand {
  RevisionNumber: number;
  AccountExecutiveName: string;
  AccountExecutiveEmail: string;
  AccountExecutiveUserId: string;
  AccountExecutiveComment: string;
  ApprovalStatus: string;
  TrainingManagerName: string;
  TrainingManagerEmail: string;
  TrainingManagerUserId: string;
  SchedulerName: string;
  SchedulerEmail: string;
  SchedulerUserId: string;
}
