<div class="actions" *ngIf="mode !== editMode.OkCancel">
  <div class="advance">
    <daui-button [icon]="buttonIcon.Delete" [type]="buttonType.Flat" [attractive]="buttonAttractive.Heavy"
      (click)="onDelete()" *ngIf="mode === editMode.Edit"></daui-button>
  </div>
  <div class="default">
    <daui-button id="cancel" [type]="buttonType.Flat" (click)="onCancel()">Back</daui-button>
    <daui-button *ngIf="layout != 'send'" [type]="buttonType.Flat" [attractive]="buttonAttractive.Regular"
      (click)="onSave()" [disabled]="!isSaveEnabled">
      Save
    </daui-button>
    <daui-button *ngIf="layout === 'send'" [type]="buttonType.Flat" [attractive]="buttonAttractive.Regular"
      (click)="onSend()">
      Send
    </daui-button>
  </div>
</div>
<div class="actions" *ngIf="mode === editMode.OkCancel">
  <div class="advance">
    &nbsp;
  </div>
  <div class="default">
    <daui-button id="cancel" [type]="buttonType.Flat" (click)="onCancel()">Cancel</daui-button>
    <daui-button [type]="buttonType.Flat" [attractive]="buttonAttractive.Regular" (click)="onSave()"
      [disabled]="!isSaveEnabled">
      Ok
    </daui-button>
  </div>
</div>
<daui-dialog class="dialogEdit" [isShow]="isShowDialogDelete">
  <div class="dialogEdit-wrapper">
    <div class="dialogEdit-wrapper-body">
      Are you sure you want<br />
      to delete?
    </div>
  </div>
  <daui-action-bar [hasSecondary]="true" primaryButtonText="Delete" secondaryButtonText="Cancel"
    (secondary)="onDialogDeleteCancel()" (primary)="onDialogDeleteOk()">
  </daui-action-bar>
</daui-dialog>

<daui-dialog class="dialogEdit" [isShow]="isShowDialogSaveDelete">
  <div class="dialogEdit-wrapper">
    <div class="dialogEdit-wrapper-body">
      No options have been selected.<br /><br />
      Do you want to delete this item?
    </div>
  </div>
  <daui-action-bar [hasSecondary]="true" secondaryButtonText="Cancel" primaryButtonText="Delete"
    (secondary)="onDialogDeleteCancel()" (primary)="onDialogDeleteOk()">
  </daui-action-bar>
</daui-dialog>