import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonAttractive, ButtonIcon, ButtonType, DialogBehavior, DialogSize } from 'common-web-ui';

@Component({
  selector: 'app-presets-help-dialog',
  templateUrl: './presets-help-dialog.component.html',
  styleUrls: ['./presets-help-dialog.component.less']
})
export class PresetsHelpDialogComponent implements OnInit {

  @Input() isVisible: boolean = false;
  @Output() onClose = new EventEmitter();

  behavior = DialogBehavior.Regular;

  buttonType = ButtonType;
  attractive = ButtonAttractive;
  buttonIcon = ButtonIcon;

  size = DialogSize.Large;

  constructor() { }

  ngOnInit() {
  }

  closeDialog() {

    this.onClose.emit();
  }

}
