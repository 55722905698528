<daui-button [icon]="buttonIcon.Delete" [type]="buttonType.Flat" [attractive]="buttonAttractive.Heavy"
  [disabled]="disabled" (click)="onClear()">Clear all options</daui-button>
<daui-dialog class="dialogEdit" [isShow]="isShowDialogClear">
  <div class="dialogEdit-wrapper">
    <div class="body">
      <div>Are you sure you want to clear all the current training options?</div>
      <div class="separator"></div>
      <div class="warning">
        <div class="icon"></div>
        <div class="text">This action is irreversible.</div>
      </div>
    </div>
  </div>
  <daui-action-bar [hasSecondary]="true" class="actions" [attractive]="dialogActionsAttractiveType.Heavy"
    [secondaryButtonText]="'Cancel'" [primaryButtonText]="'Clear'" [primaryDisabled]="false"
    (secondary)="onDialogClearCancel()" (primary)="onDialogClearOk()">
  </daui-action-bar>
</daui-dialog>