<app-form-content>
  <app-customer-information id="customerInformation"> </app-customer-information>
  <div class="form-wrapper">
    <div class="content">
      <app-form-card>
        <div class="status" [ngClass]="{
            approved:
              currentApprovalStatus === ApprovalStatus.APPROVED || !isApprovalFeatureVisible,
            'approval-pending':
              currentApprovalStatus === ApprovalStatus.PENDING && isApprovalFeatureVisible,
            rejected: currentApprovalStatus === ApprovalStatus.REJECTED && isApprovalFeatureVisible
          }">
          <div class="visual"></div>
          <div class="infos">
            <div class="send">
              <div *ngIf="!isApprovalFeatureVisible" class="state">TCA SENT</div>
              <div id="approvalStatus" *ngIf="isApprovalFeatureVisible" class="state">
                {{ currentApprovalStatusText }}
              </div>
            </div>
            <div class="reference">
              <div class="reference">
                RES #<span id="reservation-id">{{
                  currentTrainingRequirement.schedulerReservationId
                  }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="description" *ngIf="!isApprovalFeatureVisible">
          The TCA for reservation #{{ reservationId }} has been submitted.
        </div>
        <div class="description" *ngIf="isApprovalFeatureVisible">
          {{ currentStatusText }}
        </div>
        <div class="description note" *ngIf="!isApprovalFeatureVisible">
          <strong>Important:</strong> CAE will do its best to accommodate any requirements, but
          please understand all TCA’s are subject to review.
        </div>
        <span class="save-preset" *ngIf="!this.currentTrainingRequirement.isCustomTcaProvided">
          <h1 class="save-preset-title">Save as Preset</h1>
          <div class="save-preset-description">
            You can save the options selected from this TCA for future use
          </div>
          <app-save-preset class="save-preset-dialog" [currentTrainingRequirement]="currentTrainingRequirement"
            [isTcaLocked]="isTcaLocked()" [authority]="authority">
          </app-save-preset>
        </span>

        <app-state-actions [label]="label"></app-state-actions>
        <div class="supporting-documents" *ngIf="showSupportingDocumentsTC">
          <div class="supporting-documents-title">Supporting Documents Required</div>
          <div class="description" style="margin-top: 10px">
            <daui-indicator class="item" [type]="indicatorType.Warning"></daui-indicator>
            <div>
              Please note, you must <strong>send a copy of the following documents</strong> to your
              CAE representative:
            </div>
          </div>
          <ul>
            <li *ngFor="let item of visibleTCSupportingDocuments">{{ item.display }}</li>
          </ul>
        </div>
        <div class="supporting-documents" *ngIf="showSupportingDocumentsGeneric">
          <div class="supporting-documents-title">Supporting Documents Required</div>
          <div class="description" style="margin-top: 10px">
            <daui-indicator class="item" [type]="indicatorType.Warning"></daui-indicator>
            <div>
              Please note, you must <strong>send a copy of the following documents</strong> to your
              CAE representative:
            </div>
          </div>
          <ul>
            <li *ngFor="let item of visibleGenericSupportingDocuments">{{ item.display }}</li>
          </ul>
          <p>
            Approved company documentation is required a minimum of 10 days prior to training for
            any additional training specified in company operations manuals. For company OPC check,
            a constituted crew is required. It is the company’s responsibility to ensure CAE have
            the latest Operational Manuals AND forms for the company, by sending electronic copies
            to <a href="mailto:OM.UPDATES@CAE.COM">OM.UPDATES@CAE.COM</a> &
            <a href="mailto:FORMS.UPDATES@CAE.COM">FORMS.UPDATES@CAE.COM</a> respectively.
          </p>
          <p>
            For rating renewals, the ATO minimum requirements will be used unless written
            confirmation from the relevant NAA, stipulating the required alternatives, is provided.
            Please be aware that training records will be emailed within 3 to 5 business days upon
            completion of training, and original NAA paperwork forms given to pilot upon completion.
            The training and checking form cannot be amended without express permission of the
            company via the relevant Account Executive/Regional sales Manager/Program Manager.
          </p>
        </div>
      </app-form-card>
      <div id="approval-section" *ngIf="isApprovalFeatureVisible">
        <app-form-card *ngIf="hasApprovers">
          <div class="approval">
            <div class="title">Approval</div>
            <div class="items">
              <div *ngFor="let item of approvers$ | async">
                <div class="item" [ngClass]="{ approved: item.approvalStatus === ApprovalStatus.APPROVED }">
                  <div class="visual"></div>
                  <div class="label">{{ approverRoleDescriptions[item.role] }}</div>
                </div>
                <div class="email" *ngIf="
                    item.role !== ApproverRole.AccountExecutive &&
                    currentTrainingRequirement.userRole === UserRoles.AccountExecutive
                  " (click)="gotoEmailEdit(item.role)">
                  <span>{{ item.email }}</span>
                  <div class="edit-after"></div>
                </div>
                <div class="simsessions" *ngIf="
                    item.role == ApproverRole.Scheduler &&
                    currentTrainingRequirement.userRole === UserRoles.Scheduler &&
                    !isShowApprovalButton
                  " (click)="gotoReviewPage()">
                  <span>SIM sessions/dates</span>
                  <div class="edit-after"></div>
                </div>
              </div>
            </div>
          </div>
        </app-form-card>
        <div *ngIf="isShowApprovalButton" class="actions">
          <daui-button class="submit" id="reviewTcaButton" [attractive]="buttonAttractive.Regular"
            (click)="gotoReviewPage()" [disabled]="isApprovalButtonDisabled">
            Review and Approve TCA
          </daui-button>
        </div>
      </div>
    </div>
  </div>
</app-form-content>