/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomHttpInterceptor } from './http-inteceptor';
import { LoggingInterceptor } from './logging-interceptor';
import { AuthInterceptor } from './auth-interceptor';
import { WaitingInterceptor } from './waiting-interceptor';

/** Http interceptor providers in outside-in order */
export const HTTP_INTERCEPTORS_PROVIDERS = [
  { provide: HTTP_INTERCEPTORS, useClass: WaitingInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true }
];

export * from './waiting-interceptor';
export * from './auth-interceptor';
export * from './logging-interceptor';
export * from './http-inteceptor';
