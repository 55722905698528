import { ActivatedRoute, Router } from '@angular/router';
import { AddLicence, LogAnalyticEvent, UpdateCheckingAddOn, UpdateLicence, UpdateNote, UpdateSpecialAirports, UpdateTrainingAddOn } from 'src/app/state/trainingRequirement.actions';
import { ButtonAttractive, ButtonType } from 'common-web-ui';
import { Component, Input, OnInit } from '@angular/core';
import { DeletePreset, GetPreset, GetPresetsSummary } from 'src/app/state/preset.actions';
import { PresetState, PresetSummaryState } from 'src/app/state/preset.state';
import { Select, Store } from '@ngxs/store';

import { Authority } from 'src/app/models/Authority';
import { Observable } from 'rxjs';
import { Preset } from 'src/app/models/Preset';
import { PresetSummary } from 'src/app/models/PresetSummary';
import { PresetType } from 'src/app/models/PresetType';
import { TrainingRequirement } from 'src/app/models/TrainingRequirement';
import { withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-preset',
  templateUrl: './preset.component.html',
  styleUrls: ['./preset.component.less']
})
export class PresetComponent implements OnInit {

  readonly buttonType = ButtonType;
  readonly buttonAttractive = ButtonAttractive;

  @Input() hasTrainingOptions = false;
  @Input() isTCALocked = false;
  @Input() authority: Authority;
  @Input() currentTrainingRequirement: TrainingRequirement = new TrainingRequirement();

  showingHelpDialog = false;


  selectedPresetSummary: PresetSummary;
  selectedDeletePresetSummary: PresetSummary | null;
  isShowApplyPresetWarningDialog = false;
  showingPresets = false;
  showingDeleteDialog = false;
  isPresetDialogOpen = false;

  @Select(PresetState.getPreset)
  selectedPreset$: Observable<Preset>;

  @Select(PresetSummaryState.getPresetSummaryList)
  presetsSummary$: Observable<PresetSummary[]>;

  @Select(PresetState.deletePreset)
  presets$: Observable<Preset[]>;

  constructor(private store: Store, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {

  }

  showHelpDialog() {
    this.showingPresets = false;
    this.showingHelpDialog = true;
  }

  closeHelpDialog() {
    if (this.isPresetDialogOpen) {
      this.showingHelpDialog = false;
      this.showingPresets = true;
    } else
      this.showingHelpDialog = false;
  }

  clearSelectedPreset() {
    this.selectedPresetSummary = null;

  }

  selectionChange(presetSummary: PresetSummary) {
    this.selectedPresetSummary = presetSummary;

    this.isShowApplyPresetWarningDialog = false;
    this.showingPresets = false;

    if (!this.isTCALocked) {
      this.isShowApplyPresetWarningDialog = true;
    } else {
      this.router.navigate(['./locked'], { relativeTo: this.route });
    }

    this.store.dispatch(new LogAnalyticEvent('regular', 'TCA Preset Dropdown Selected'));
  }

  deleteSelection(presetSummary: PresetSummary) {
    this.selectedDeletePresetSummary = presetSummary;
  }

  async onPresetApply() {
    this.isShowApplyPresetWarningDialog = false;

    await new Promise((resolve) => {
      this.store
        .dispatch(
          new GetPreset(this.currentTrainingRequirement.customerId, this.selectedPresetSummary.id)
        )
        .pipe(withLatestFrom(this.selectedPreset$))
        .subscribe(([_, preset]) => {
          if (!!preset) {
            preset.checkingAddons.forEach((addon, index) => {
              this.store.dispatch(new UpdateCheckingAddOn(addon));
            });

            preset.trainingAddons.forEach((addon, index) => {
              this.store.dispatch(new UpdateTrainingAddOn(addon));
            });

            this.store.dispatch(new UpdateSpecialAirports(preset.specialAirports));

            this.store.dispatch(new UpdateNote(preset.note));

            if (preset.presetType == PresetType.Private) {
              preset.licences.forEach(licence => {
                if (this.currentTrainingRequirement.licences.find(x => x.id && x.id == licence.id)) {
                  this.store.dispatch(new UpdateLicence(licence))
                }
                else {
                  this.store.dispatch(new AddLicence(licence));
                }
              });
            }

            resolve(true);
          }
        });
    });

    this.store.dispatch(new LogAnalyticEvent('regular', 'TCA Preset Confirmation Continue'));
  }

  onPresetApplyCancel() {
    this.isShowApplyPresetWarningDialog = false;
    this.showingPresets = true;
    this.store.dispatch(new LogAnalyticEvent('regular', 'TCA Preset Confirmation Cancel'));
  }

  loadPresets() {
    this.store.dispatch(
      new GetPresetsSummary(
        this.currentTrainingRequirement.customerId,
        this.currentTrainingRequirement.clientId,
        this.authority.toString()
      )
    );
  }

  viewSavedPresets() {
    this.loadPresets();
    this.showingPresets = true;
    this.isPresetDialogOpen = true;
  }

  openDeleteDialog() {
    this.showingDeleteDialog = true;
    this.showingPresets = false;
  }

  async onDeleteClicked() {

    await new Promise((resolve) => {
      this.store
        .dispatch(new DeletePreset(this.currentTrainingRequirement.customerId, this.selectedDeletePresetSummary.id))
        .pipe(withLatestFrom(this.presets$))
        .subscribe(([_, presets]) => {
          if (!!presets) {
            this.selectedDeletePresetSummary = null;
            this.store.dispatch(
              new GetPresetsSummary(
                this.currentTrainingRequirement.customerId,
                this.currentTrainingRequirement.clientId,
                this.authority
              )
            );
          }
          resolve(true);
        });
    });


    this.showingDeleteDialog = false;
    this.showingPresets = true;
  }

  onCancelClicked() {
    this.showingDeleteDialog = false;
    this.showingPresets = true;
  }

}
