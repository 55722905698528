import { AnalyticsBaseProperties } from '../models/AnalyticsBaseProperties';
import { AnalyticsProperties } from '../models/AnalyticsProperties';
import { AnalyticsRecordManagerProperties } from '../models/AnalyticsRecordManagerProperties';
import { AppInsightService } from '../core/services/app-insight-service';
import { AppInsightsBaseProperties } from '../models/AppInsightsBaseProperties';
import { AppInsightsProperties } from '../models/AppInsightsProperties';
import { AppInsightsRecordManagerProperties } from '../models/AppInsightsRecordManagerProperties';
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { MessageGenericService } from './message-generic.service';

@Injectable({
  providedIn: 'root'
})
export class MessageService extends MessageGenericService {
  constructor(_appInsightService: AppInsightService, _datePipe: DatePipe) {
    super(_appInsightService, _datePipe);
  }

  pushAnalytics<T extends AnalyticsBaseProperties>(type: string, analyticMessage: T): void {
    this.appInsightService.logEvent(
      type,
      this.convertToAppInsightsProperties(null, analyticMessage)
    );
  }

  private convertToAppInsightsProperties(
    message: string,
    analyticMessage: AnalyticsBaseProperties = null
  ): AppInsightsBaseProperties {
    // common properties being set
    let appInsightsProperties = new AppInsightsBaseProperties();

    appInsightsProperties.app_name = 'CustomerWebApp';
    appInsightsProperties.CustomerWebApp = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    appInsightsProperties.page_name = '';
    appInsightsProperties.message = !!message ? message : 'Analytic Event';

    // TCA related analytics properties
    if (!!analyticMessage && analyticMessage instanceof AnalyticsProperties) {
      appInsightsProperties = AppInsightsProperties.mapWith(
        appInsightsProperties as AppInsightsProperties,
        analyticMessage
      );
    } else if (!!analyticMessage && analyticMessage instanceof AnalyticsRecordManagerProperties) {
      // Record Manager analytics properties
      appInsightsProperties = AppInsightsRecordManagerProperties.mapWith(
        appInsightsProperties as AppInsightsRecordManagerProperties,
        analyticMessage
      );
    }
    return appInsightsProperties;
  }

  /**
   * Application generic message to send to appinsights
   * @param type Type of message
   * @param message Content of message
   */
  push(type: string, message: string) {
    this.appInsightService.logEvent(type, this.convertToAppInsightsProperties(message, null));
  }

  /**
   * Application generic error (exception) to send to app insights.
   * @param error Error object containing details.
   */
  pushException(error: Error) {
    if (this.appInsightService) {
      this.appInsightService.logException(
        error,
        this.convertToAppInsightsProperties(error.message, null)
      );
    }
  }
}
