<app-form-card>
  <div class="container" [ngClass]="{ hidden: !(currentTrainingRequirement$ | async) }">
    <div class="wrapper">
      <div class="content">
        <app-licence
          class="licence"
          [readonly]="readonly"
          [currentTrainingRequirement]="currentTrainingRequirement$ | async"
          [state]="state"
        >
        </app-licence>
      </div>
    </div>
  </div>
</app-form-card>
