import { Schedule } from './Schedule';
import { PanelInfoState } from 'common-web-ui';

export enum OperatingManualReferenceType {
  None = 'None',
  Simple = 'Simple',
  Advanced = 'Advance'
}

export class Item {
  name: string;
  title: string;
  description: string;
  category: string;
  value: string | boolean;
  operatingManualReference: string;
  operatingManualReferenceType: OperatingManualReferenceType;
  active: boolean;
  state: PanelInfoState;
  schedule: Schedule;
  uiAvailability: string;
  note: string;
  metadata: string;

  constructor() {
    this.schedule = new Schedule();
  }
}
