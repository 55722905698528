import { HttpRequest } from '@angular/common/http';

export class RequestInfo {
  requestStartTime: Date;
  timeoutHandle: any;
  waitingAskedTime: Date;

  constructor(public request: HttpRequest<any>) {
    this.requestStartTime = new Date();
  }
}
