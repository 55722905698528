import {
  AutoCompleteComponent,
  CustomerInformationComponent,
  DatePickerComponent,
  DisclaimerComponent,
  FormCardActionsComponent,
  FormCardClearAllOptionsComponent,
  FormCardComponent,
  FormCardDeleteComponent,
  FormContentComponent,
  FormFieldListItemComponent,
  FormFooterComponent,
  HeaderComponent,
  LicenceComponent,
  NoteComponent,
  PilotInformationComponent,
  StateActionsComponent
} from './components';
import { CommonModule, DatePipe, TitleCasePipe } from '@angular/common';
import { CountryFormatPipe, OrdinalPipe } from './pipes';
import { FaaLicencePageComponent, NotePageComponent, TcaStatePageComponent } from './pages';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppCommonUIModule } from 'common-web-ui';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { ClearPresetsComponent } from './components/clear-presets/clear-presets.component';
import { CollapseComponent } from './components/collapse/collapse.component';
import { DocumentUploadComponent } from './components/document-upload/document-upload.component';
import { DocumentViewerComponent } from './components/document-viewer/document-viewer.component';
import { FormChangesWatcherDirective } from './directives';
import { Ng2CompleterModule } from 'ng2-completer';
import { NgModule } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { PresetComponent } from './components/preset/preset.component';
import { PresetTrainingOptionsItemSummaryComponent } from './components/preset-training-options-item-summary/preset-training-options-item-summary.component';
import { PresetsDialogComponent } from './components/presets-dialog/presets-dialog.component';
import { PresetsHelpDialogComponent } from './components/presets-help-dialog/presets-help-dialog.component';
import { SavePresetComponent } from './components/save-preset/save-preset.component';
import { WhatsNewBadgeComponent } from './components/whats-new-badge/whats-new-badge.component';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { EtcaToggleComponent } from './components/etca-toggle/etca-toggle.component';

const COMPONENTS = [
  DatePickerComponent,
  DisclaimerComponent,
  FormCardActionsComponent,
  FormCardComponent,
  FormContentComponent,
  FormFieldListItemComponent,
  FormFooterComponent,
  HeaderComponent,
  StateActionsComponent,
  AutoCompleteComponent,
  CustomerInformationComponent,
  NoteComponent,
  LicenceComponent,
  PilotInformationComponent,
  FormCardDeleteComponent,
  FormCardClearAllOptionsComponent,
  DocumentViewerComponent,
  CollapseComponent,
  DocumentUploadComponent,
  EtcaToggleComponent,
  PresetsDialogComponent,
  PresetTrainingOptionsItemSummaryComponent,
  PresetComponent,
  SavePresetComponent,
  WhatsNewBadgeComponent,
  PresetsHelpDialogComponent,
  ClearPresetsComponent
];

const PAGES = [TcaStatePageComponent, NotePageComponent, FaaLicencePageComponent];
const PIPES = [OrdinalPipe, CountryFormatPipe, SafeHtmlPipe];
@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, Ng2CompleterModule, AutocompleteLibModule, AppCommonUIModule, NgxPaginationModule],
  declarations: [FormChangesWatcherDirective, ...COMPONENTS, ...PAGES, ...PIPES, CollapseComponent],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormChangesWatcherDirective,
    ...COMPONENTS,
    ...PAGES,
    ...PIPES
  ],
  providers: [TitleCasePipe, DatePipe]
})
export class SharedModule { }
