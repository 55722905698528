import * as moment from 'moment';

import { AppSettingsService, AuthB2CService } from 'common-web-core';
import { AuthAuthorities, AuthHelper } from '../helpers/auth.helper';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';

import { AppSettings } from 'src/app/models/AppSettings';
import { ForceService } from './force.service';
import { LoggerService } from '../../logger/services/logger.service';
import { SetReturnUrl } from '../../state/login.actions';
import { Store } from '@ngxs/store';

export enum AuthenticationStatus {
  notAuthenticated,
  authenticated
};

export const B2C_TOKEN_NAME = 'b2c_token';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private oauth: Oauth;
  authHelper: AuthHelper;

  redirectUrl: string;
  accessTokenMinutesExpiry = 120; // in minutes
  accessTokenMinutesExpiryOffset = 15; // in minutes
  accessTokenVerificationInterval = 900000; // in milliseconds

  private authenticationChangedSubject = new BehaviorSubject<AuthenticationStatus>(AuthenticationStatus.notAuthenticated);
  private authB2CLogginSubscription = null;
  private destroy$ = new Subject<void>();
  constructor(
    private forceService: ForceService,
    private http: HttpClient,
    private store: Store,
    private router: Router,
    private zone: NgZone,
    private appSettingsService: AppSettingsService<AppSettings>,
    public authB2cService: AuthB2CService,
    private loggerService: LoggerService
  ) {


    // attempt to extract salesforce auth token if any. 
    // we do it at this point if not, the AuthB2CService will clear the hash
    const params = this.getUrlHashParams();

    this.oauth = JSON.parse(localStorage.getItem('salesforceOauth'));

    this.appSettingsService.appSettings$.subscribe((appSettings: AppSettings) => {
      this.authHelper = new AuthHelper(appSettings.authAADSettings, authB2cService);

      this.authHelper.b2cAccessToken = localStorage.getItem(B2C_TOKEN_NAME);

      if (params) {

        if (params['access_token']) {
          this.authHelper.currentAuthMode = AuthAuthorities.SalesForce;
          const salesForceOauth = this.getNewOauth();
          salesForceOauth.accessToken = params.access_token;
          salesForceOauth.refreshToken = params.id_token;
          salesForceOauth.instanceURL = params.instance_url;
          salesForceOauth.issuedAt = new Date().getTime();
          this.setOauth(salesForceOauth);
        }
        else if (params['aad_id_token']) {
          this.authHelper.currentAuthMode = AuthAuthorities.AzureAd;
          this.authHelper.handleCallback();
        }
      }

      //this.checkAuthenticated();
      router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          if (event.url === `/login`) {
            this.checkAuthenticated();
          }
        });

    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public get authenticationChanged$(): Observable<AuthenticationStatus> {
    if (this.authB2CLogginSubscription === null) {
      this.authB2CLogginSubscription = this.authB2cService.loggedIn$
        .pipe(takeUntil(this.destroy$))
        .subscribe(isLoggedIn => {
          if (isLoggedIn) {
            this.authB2cService.getAccessToken().then((token) => {

              this.authHelper.currentAuthMode = AuthAuthorities.AzureB2C;
              this.authHelper.isB2CAuthenticated = true;
              this.authHelper.b2cAccessToken = token;
              localStorage.setItem(B2C_TOKEN_NAME, token);
              if (this.authenticationChangedSubject.value != AuthenticationStatus.authenticated) {
                this.authenticationChangedSubject.next(AuthenticationStatus.authenticated);
              }
            });

          } else {

            this.authHelper.b2cAccessToken = '';
            if (this.authenticationChangedSubject.value != AuthenticationStatus.notAuthenticated) {
              this.authenticationChangedSubject.next(AuthenticationStatus.notAuthenticated);
            }
          }
        });
    }
    return this.authenticationChangedSubject.asObservable();
  }

  /**
   * This method gets hash parameters from the url if any
   * to check for any auth tokens
   * @returns 
   */
  private getUrlHashParams(): { [key: string]: string } {

    let params: { [key: string]: string } = {};

    if (location && location.hash) {

      // Create a key/value object with the params of the hash
      params = location.hash.substring(1).split('&').reduce((acc: any, cv: string) => {
        if (cv) {
          const splits = cv.split('=');
          if (splits.length == 2) {
            acc[splits[0]] = decodeURIComponent(splits[1]);
          }
        }
        return acc;
      }, {});

    }

    return params;
  }

  private checkAuthenticated() {
    if (this.isAuthenticated()) {
      if (this.authenticationChangedSubject.value != AuthenticationStatus.authenticated) {
        this.authenticationChangedSubject.next(AuthenticationStatus.authenticated);
      }
    } else {
      if (this.authenticationChangedSubject.value != AuthenticationStatus.notAuthenticated) {
        this.authenticationChangedSubject.next(AuthenticationStatus.notAuthenticated);
      }
    }
  }

  getNewOauth(): Oauth {
    return {
      accessToken: '',
      refreshToken: '',
      instanceURL: '',
      issuedAt: -1
    };
  }

  private sendTokenToSalesforce() {
    const payload = new HttpParams()
      .set('client_id', this.appSettingsService.settings().authSettings.clientId)
      .set('grant_type', 'refresh_token')
      .set('refresh_token', this.oauth.refreshToken);

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.post(
      `${this.appSettingsService.settings().authSettings.corsProxy}/hasPassTest/services/oauth2/token`,
      payload.toString(),
      {
        headers
      }
    );
  }

  getIssuedDate() {
    return this.oauth.issuedAt;
  }

  async refreshToken() {
    const { access_token } = (await this.sendTokenToSalesforce()
      .toPromise()
      .catch(error => this.loggerService.error('auth:refreshToken', error))) as any;
    this.oauth.accessToken = access_token;
    this.setOauth(this.oauth);
  }

  getRefreshToken() {
    return this.oauth.refreshToken;
  }

  setOauth(oauth: Oauth) {
    if (!oauth) {
      return;
    }
    localStorage.setItem('salesforceOauth', JSON.stringify(oauth));
    this.oauth = oauth;
  }

  setReturnUrl(returnUrl: string) {
    this.loggerService.info(`auth:setReturnUrl:returnUrl - ${returnUrl}`);
    sessionStorage.setItem('returnUrl', returnUrl);
    this.store.dispatch(new SetReturnUrl(returnUrl));
  }

  getReturnUrl() {
    return sessionStorage.getItem('returnUrl');
  }

  getAuthorizationToken() {
    if (this.authHelper.isAzureAd) {
      return this.authHelper.accessToken;
    }
    if (this.authHelper.isAzureB2C) {
      return this.authHelper.b2cAccessToken;
    }
    return !!this.oauth ? this.oauth.accessToken : '';
  }

  isAuthenticated() {
    if (this.authHelper.isAzureAd || this.authHelper.isAzureB2C) {
      return this.authHelper.isAuthenticated;
    }

    return this.oauth && this.oauth.accessToken ? true : false;
  }

  public validateTokenValid(returnUrl? : string): void {
    if (!this.authHelper.isAzureAd) {
      if (this.isTokenExpired()) {
        this.removeToken();
        this.zone.runOutsideAngular(() => {

          if(typeof returnUrl !== 'undefined') {
            this.setReturnUrl(returnUrl);
            this.router.navigate(['/login'], { queryParams: { returnUrl: returnUrl } });
          }
          else {
            this.router.navigate(['login']);
          }
          
        });
      }
    }
  }

  public JWTTokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }

  isTokenExpired(): boolean {
    if (this.authHelper.isAzureAd) {
      if (this.authHelper.accessToken) {
        return !this.authHelper.isAuthenticated;
      }
    }
    else if (this.authHelper.isAzureB2C) {
      if (this.authHelper.b2cAccessToken) {
        return this.JWTTokenExpired(this.authHelper.b2cAccessToken);
      }
    }
    else {
      if (this.oauth && this.oauth.accessToken && this.oauth.issuedAt) {
        const now = moment();
        const dateIssuedAt = new Date(this.oauth.issuedAt);
        const issuedAt = moment(dateIssuedAt);
        const expiresAtWithOffset = issuedAt.add(
          moment.duration(
            this.accessTokenMinutesExpiry - this.accessTokenMinutesExpiryOffset,
            'minutes'
          )
        );

        if (now.isBefore(expiresAtWithOffset)) {
          return false;
        }
      }
    }
    return true;
  }

  login(login_hint: string): any {
    this.loggerService.info('auth:login:login_hint', login_hint);
    this.loggerService.info('auth:login:returnUrl', this.getReturnUrl());
    if (this.authHelper.isAzureAd || this.authHelper.isAzureB2C) {
      this.authHelper.login(login_hint).then((val: boolean) => { console.log('Logged? ', val) });
    }

    else {
      this.forceService.login(login_hint).then(() => { });
    }
  }

  removeToken(): void {
    localStorage.removeItem('salesforceOauth');
    localStorage.removeItem(B2C_TOKEN_NAME);
    this.authHelper.b2cAccessToken = '';
    this.oauth = null;
  }

  logout() {
    if (this.authHelper.isAuthenticated) {
      if (this.authHelper.isAzureAd || this.authHelper.isAzureB2C) {

        this.authHelper.logout();
      } else {
        if (this.oauth) {
          if (this.oauth.accessToken) {
            const revokeTokenUrl = `${this.appSettingsService.settings().authSettings.revokeTokenUrl}${this.oauth.accessToken}`;
            this.http
              .get(revokeTokenUrl)
              .subscribe(data => this.loggerService.log('auth:logout', data));

            this.removeToken();

            window.location.href = this.appSettingsService.settings().authSettings.logoutUrl;
          }
        }
      }
    }

    const returnUrl = this.getReturnUrl();
    window.location.href = returnUrl || this.appSettingsService.settings().authSettings.loginUrl;
    localStorage.clear();
  }
}

export class Oauth {
  accessToken: string;
  refreshToken: string;
  instanceURL: string;
  issuedAt: number;
}
