import { catchError, first, map, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoginState } from 'src/app/state/login.state';
import * as environment from 'src/environments/environment';

import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';

import { ModuleTypes } from '../data/module-types';
import { AddOn } from '../models/Addon';
import {
  AnalyticsProperties,
  ErrorMessageAnalyticsProperties,
  FormVisibilityAnalyticsProperties,
  SubpageAnalyticsProperties,
  WarningMessageAnalyticsProperties
} from '../models/AnalyticsProperties';
import { Item } from '../models/Item';
import { Licence } from '../models/Licence';
import { ScheduleSessionCommand } from '../models/ScheduleSessionCommand';
import { SessionItem } from '../models/SessionItem';
import { SpecialAirport } from '../models/SpecialAirport';
import { TrainingRequirement } from '../models/TrainingRequirement';
import { AnalyticsService } from '../services/analytics-service';
import { TcaService } from '../services/data-layer/tca.service';
import { TcaValidationService, ValidationErrors } from '../services/tca-validation.service';
import { InitializeApprovers, ApprovalState } from '../tca/approval/store';
import { LogAnalyticEventTypes } from './app-analytic.state';
import {
  AddLicence,
  AddSchedule,
  AddSpecialAirport,
  ClearCurrentTrainingRequirement,
  ClearAllEasaOptions,
  ClearAllFaaOptions,
  DeleteCheckingAddOn,
  DeleteLicence,
  DeleteSpecialAirport,
  DeleteTrainingAddOn,
  DeleteNote,
  LoadTrainingRequirement,
  LoadTrainingRequirementSuccess,
  LogAnalyticEvent,
  SubmitTrainingRequirement,
  UpdateAirportErrors,
  UpdateCheckingAddOn,
  UpdateCheckType,
  UpdateCourseInformationErrors,
  UpdateLicence,
  UpdateLicenceErrors,
  UpdateNote,
  UpdateOmRefErrors,
  UpdateOperatedManualReference,
  UpdateSpecialAirport,
  UpdateTcaSubmitted,
  UpdateTrainingAddOn,
  UpdateTrainingRequirementSuccess,
  UpdateOtherCheckType,
  RemoveOtherCheckType,
  UpdateAircraftOperationCertificate,
  UpdateTrainingRequirement,
  StoreTrainingRequirement,
  UpdateSpecialAirports,
  UpdateFlightTimeInAircraftType,
  UpdateOperatingAircraftTypeSerialNumber,
  UpdateSeat,
  UpdatetTotalFlightTime,
  UpdateTrainingProfileErrors,
  UpdatetMultiEngineLandandInstrumentRating,
  ClearAllGenericOptions,
  ClearAllTCOptions,
  UpdateCustomTcaProvided,
  UpdateCustomTcaErrors
} from './trainingRequirement.actions';
import { ApproverRole } from '../models/Approver';
import { CourseCheckTypeOverride } from '../models/CourseCheckTypeOverride';
import { LoggerService } from '../logger/services/logger.service';
import { RegulationAgencyName } from '../models/RegulationAgencyName';

export interface TrainingRequirementStateModel {
  currentTrainingRequirement: TrainingRequirement;
  originalTrainingRequirementValue: string;
  validationErrors: ValidationErrors;
  error: string;
  isLoaded: boolean;
}

@State<TrainingRequirementStateModel>({
  name: 'trainingRequirement',
  defaults: {
    currentTrainingRequirement: new TrainingRequirement(),
    originalTrainingRequirementValue: JSON.stringify(new TrainingRequirement()),
    validationErrors: null,
    error: '',
    isLoaded: false
  }
})
@Injectable()
export class TrainingRequirementState {
  constructor(
    private router: Router,
    private ngZone: NgZone,
    private trainingRequirementService: TcaService,
    private trainingRequirementValidationService: TcaValidationService,
    private analyticsService: AnalyticsService,
    private store: Store,
    private authService: AuthService,
    private loggerService: LoggerService
  ) { }

  @Selector()
  static currentState(state: TrainingRequirementStateModel): TrainingRequirementStateModel {
    return state;
  }

  @Selector()
  static currentTrainingRequirement(state: TrainingRequirementStateModel) {
    return state.currentTrainingRequirement;
  }

  @Selector()
  static approvers(state: TrainingRequirementStateModel) {
    return state.currentTrainingRequirement.approvers;
  }

  @Selector()
  static errorMessage(state: TrainingRequirementStateModel) {
    return state.error;
  }

  @Selector()
  static validationErrors(state: TrainingRequirementStateModel) {
    return state.validationErrors;
  }

  @Selector()
  static checkingAddOnByName(addOnName: string) {
    return createSelector([TrainingRequirementState], (state: TrainingRequirementState) => {
      const selector = state['trainingRequirement'];
      if (!selector.currentTrainingRequirement) {
        return null;
      }
      return selector.currentTrainingRequirement.checkingAddons.find((addOn: AddOn) => {
        return addOn.name === addOnName;
      });
    });
  }

  @Selector()
  static trainingAddOnByName(moduleName: string) {
    return createSelector([TrainingRequirementState], (state: TrainingRequirementState) => {
      const selector = state['trainingRequirement'];
      if (!selector.currentTrainingRequirement) {
        return null;
      }
      return selector.currentTrainingRequirement.trainingAddons.find((item: Item) => {
        return item.name === moduleName;
      });
    });
  }

  @Selector()
  static note(state: TrainingRequirementStateModel) {
    if (!state.currentTrainingRequirement) {
      return null;
    }
    return state.currentTrainingRequirement.note;
  }

  @Selector()
  static trainingType(state: TrainingRequirementStateModel) {
    if (!state.currentTrainingRequirement) {
      return null;
    }
    return state.currentTrainingRequirement.trainingType;
  }

  @Selector()
  static specialAirports(state: TrainingRequirementStateModel) {
    if (!state.currentTrainingRequirement) {
      return null;
    }
    return state.currentTrainingRequirement.specialAirports;
  }

  @Selector()
  static licences(state: TrainingRequirementStateModel) {
    if (!state.currentTrainingRequirement) {
      return null;
    }
    return state.currentTrainingRequirement.licences;
  }

  @Selector()
  static accountExecutive() {
    return createSelector([TrainingRequirementState], (state: TrainingRequirementStateModel) => {
      return TrainingRequirementState.getAccountExecutive(state['trainingRequirement']);
    });
  }

  @Selector()
  static isTrainingRequirementModelDirty(state: TrainingRequirementStateModel): boolean {
    if (!state.currentTrainingRequirement) {
      return false;
    }

    return (
      state.originalTrainingRequirementValue !== JSON.stringify(state.currentTrainingRequirement)
    );
  }

  @Selector()
  static trainingRequirementTrainingType(state: TrainingRequirementStateModel) {
    return state.currentTrainingRequirement.trainingType;
  }

  private static getAccountExecutive(state: TrainingRequirementStateModel) {
    if (!!state.currentTrainingRequirement) {
      return state.currentTrainingRequirement.accountExecutive;
    }
  }

  @Selector()
  static trainingDocumentSources(state: TrainingRequirementStateModel) {
    return state.currentTrainingRequirement.trainingDocumentSources;
  }

  @Action(StoreTrainingRequirement)
  storeTrainingRequirement(
    { patchState, dispatch }: StateContext<TrainingRequirementStateModel>,
    action: StoreTrainingRequirement
  ) {
    patchState({
      currentTrainingRequirement: action.trainingRequirement,
      originalTrainingRequirementValue: JSON.stringify(action.trainingRequirement),
      isLoaded: true
    });
    dispatch(
      new InitializeApprovers(
        action.trainingRequirement.accountExecutive.accountExecutiveEmail,
        action.trainingRequirement.approvers
      )
    );
  }

  @Action(LoadTrainingRequirement)
  loadTrainingRequirement(
    { getState, dispatch }: StateContext<TrainingRequirementStateModel>,
    action: LoadTrainingRequirement
  ) {
    this.loggerService.info(
      `trainingRequirementState:loadTrainingRequirement - training requirement ${action.trainingRequirementId}`
    );
    const currentTrainingRequirement = getState().currentTrainingRequirement;
    if (
      (!!currentTrainingRequirement &&
        currentTrainingRequirement.id !== '0' &&
        action.trainingRequirementId === currentTrainingRequirement.id &&
        !action.isRefreshRequired) ||
      (!!currentTrainingRequirement && !action.trainingRequirementId)
    ) {
      this.loggerService.info(
        'trainingRequirementState:loadTrainingRequirement - loading from current state'
      );
      dispatch(
        new InitializeApprovers(
          currentTrainingRequirement.accountExecutive.accountExecutiveEmail,
          currentTrainingRequirement.approvers
        )
      );
      return;
    }

    if (
      !this.authService.isTokenExpired() &&
      !!this.trainingRequirementService &&
      !!action.trainingRequirementId
    ) {
      this.loggerService.info(
        'trainingRequirementState:loadTrainingRequirement - loading from api'
      );
      this.trainingRequirementService
        .getTcaPrefilledById(action.trainingRequirementId)
        .pipe(
          map(trainingRequirement => {
            dispatch(new LoadTrainingRequirementSuccess(trainingRequirement));
          }),
          catchError(err => {
            dispatch(new ClearCurrentTrainingRequirement());
            return err;
          })
        )
        .subscribe();
    }
  }

  @Action(LoadTrainingRequirementSuccess)
  loadTrainingRequirementSuccess(
    { dispatch, patchState }: StateContext<TrainingRequirementStateModel>,
    action: LoadTrainingRequirementSuccess
  ) {
    this.loggerService.info(
      `trainingRequirementState:loadTrainingRequirementSuccess - training requirement ${action.trainingRequirement.id}, revision ${action.trainingRequirement.revisionNumber} loaded with success`
    );
    patchState({
      currentTrainingRequirement: action.trainingRequirement,
      originalTrainingRequirementValue: JSON.stringify(action.trainingRequirement),
      isLoaded: true
    });
    dispatch(
      new InitializeApprovers(
        action.trainingRequirement.accountExecutive.accountExecutiveEmail,
        action.trainingRequirement.approvers
      )
    );
  }

  @Action(ClearCurrentTrainingRequirement)
  clearCurrentTrainingRequirement({ patchState }: StateContext<TrainingRequirementStateModel>) {
    patchState({
      currentTrainingRequirement: null,
      originalTrainingRequirementValue: null,
      isLoaded: false
    });
  }

  @Action(UpdateCheckingAddOn)
  updateCheckingAddOn(
    { patchState, getState }: StateContext<TrainingRequirementStateModel>,
    action: UpdateCheckingAddOn
  ) {
    const currentTrainingRequirement = getState().currentTrainingRequirement;

    const addOnIndex = currentTrainingRequirement.checkingAddons.findIndex((addOn: AddOn) => {
      return addOn.name === action.addOn.name;
    });

    if (addOnIndex > -1) {
      this.ResetAddOnOperatingManualReference(action.addOn.options);
      currentTrainingRequirement.checkingAddons[addOnIndex] = action.addOn;
    }

    patchState({
      currentTrainingRequirement: Object.assign({}, currentTrainingRequirement)
    });
  }

  @Action(DeleteCheckingAddOn)
  deleteCheckingAddOn(
    { patchState, getState }: StateContext<TrainingRequirementStateModel>,
    action: DeleteCheckingAddOn
  ) {
    const currentTrainingRequirement = getState().currentTrainingRequirement;

    const moduleIndex = currentTrainingRequirement.checkingAddons.findIndex((addOn: AddOn) => {
      return addOn.name === action.addOn.name;
    });

    if (moduleIndex > -1) {
      action.addOn.active = false;
      action.addOn.options.forEach(option => {
        switch (option.category) {
          case 'Dual_Seat':
            option.value = false;
            break;
          default:
            option.value = null;
            break;
        }
        option.operatingManualReference = null;
      });
      currentTrainingRequirement.checkingAddons[moduleIndex] = action.addOn;
    }

    patchState({
      currentTrainingRequirement: Object.assign({}, currentTrainingRequirement)
    });
  }

  @Action(UpdateTrainingAddOn)
  updateCheckingModule(
    { patchState, getState }: StateContext<TrainingRequirementStateModel>,
    action: UpdateTrainingAddOn
  ) {
    const currentTrainingRequirement = getState().currentTrainingRequirement;

    const moduleIndex = currentTrainingRequirement.trainingAddons.findIndex((addOn: AddOn) => {
      return addOn.name === action.item.name;
    });

    if (moduleIndex > -1) {
      this.ResetAddOnOperatingManualReference(action.item.options);
      currentTrainingRequirement.trainingAddons[moduleIndex] = action.item;
    }

    patchState({
      currentTrainingRequirement: Object.assign({}, currentTrainingRequirement)
    });
  }

  @Action(DeleteTrainingAddOn)
  deleteTrainingAddOn(
    { patchState, getState }: StateContext<TrainingRequirementStateModel>,
    action: DeleteTrainingAddOn
  ) {
    const currentTrainingRequirement = getState().currentTrainingRequirement;

    const moduleIndex = currentTrainingRequirement.trainingAddons.findIndex((addOn: AddOn) => {
      return addOn.name === action.item.name;
    });

    if (moduleIndex > -1) {
      action.item.active = false;
      action.item.options.forEach(option => {
        switch (option.category) {
          case 'Dual_Seat':
            option.value = false;
            break;
          default:
            option.value = null;
            break;
        }
        option.operatingManualReference = null;
      });
      currentTrainingRequirement.trainingAddons[moduleIndex] = action.item;
    }
    patchState({
      currentTrainingRequirement: Object.assign({}, currentTrainingRequirement)
    });
  }

  @Action(UpdateCheckType)
  updateCheckType(
    { patchState, getState }: StateContext<TrainingRequirementStateModel>,
    action: UpdateCheckType
  ) {
    const trainingRequirement = getState().currentTrainingRequirement;

    trainingRequirement.courseCheckTypeOverride.valueOverride = action.checkType;

    patchState({
      currentTrainingRequirement: Object.assign({}, trainingRequirement)
    });
  }

  @Action(UpdateOtherCheckType)
  updateOtherCheckType(
    { patchState, getState }: StateContext<TrainingRequirementStateModel>,
    action: UpdateOtherCheckType
  ) {
    const trainingRequirement = Object.assign({}, getState().currentTrainingRequirement);

    let courseCheckType = trainingRequirement.courseCheckTypesOverride.find(
      checkType => checkType.type === action.authority
    );

    if (!courseCheckType) {
      courseCheckType = new CourseCheckTypeOverride();
      courseCheckType.type = action.authority;
      trainingRequirement.courseCheckTypesOverride.push(courseCheckType);
    }
    courseCheckType.valueOverride = action.checkType;

    if (action.additionalInfo) {
      courseCheckType.additionalData = Object.assign(courseCheckType.additionalData || {}, action.additionalInfo);
    }

    const validationErrors = this.trainingRequirementValidationService.validate(
      trainingRequirement
    );

    patchState({
      validationErrors: validationErrors,
      currentTrainingRequirement: trainingRequirement
    });
  }

  @Action(RemoveOtherCheckType)
  removeOtherCheckType(
    { patchState, getState }: StateContext<TrainingRequirementStateModel>,
    action: RemoveOtherCheckType
  ) {
    const trainingRequirement = Object.assign({}, getState().currentTrainingRequirement);

    const courseCheckTypeIndex = trainingRequirement.courseCheckTypesOverride.findIndex(
      checkType => checkType.type === action.authority
    );

    trainingRequirement.courseCheckTypesOverride.splice(courseCheckTypeIndex, 1);

    const validationErrors = this.trainingRequirementValidationService.validate(
      trainingRequirement
    );

    patchState({
      validationErrors: validationErrors,
      currentTrainingRequirement: trainingRequirement
    });
  }

  @Action(AddLicence)
  addLicence(
    { patchState, getState }: StateContext<TrainingRequirementStateModel>,
    action: AddLicence
  ) {
    const currentTrainingRequirement = getState().currentTrainingRequirement;
    if (!currentTrainingRequirement.licences) {
      currentTrainingRequirement.licences = new Array<Licence>();
    }

    currentTrainingRequirement.licences.push(action.licence);

    patchState({
      currentTrainingRequirement: Object.assign({}, currentTrainingRequirement)
    });
  }

  @Action(UpdateLicence)
  updateLicence(
    { patchState, getState }: StateContext<TrainingRequirementStateModel>,
    action: UpdateLicence
  ) {
    const currentTrainingRequirement = getState().currentTrainingRequirement;

    const licenceIndex = currentTrainingRequirement.licences.findIndex((licence: Licence) => {
      return licence.id === action.licence.id;
    });

    if (licenceIndex > -1) {
      currentTrainingRequirement.licences[licenceIndex] = action.licence;
    }

    patchState({
      currentTrainingRequirement: Object.assign({}, currentTrainingRequirement)
    });
  }

  @Action(DeleteLicence)
  DeleteLicence(
    { dispatch, patchState, getState }: StateContext<TrainingRequirementStateModel>,
    action: DeleteLicence
  ) {
    const currentTrainingRequirement = getState().currentTrainingRequirement;

    const licenceIndex = currentTrainingRequirement.licences.findIndex((licence: Licence) => {
      return licence.id === action.licenceId;
    });

    dispatch(new RemoveOtherCheckType(currentTrainingRequirement.licences[licenceIndex].authority));

    if (licenceIndex > -1) {
      currentTrainingRequirement.licences.splice(licenceIndex, 1);
    }

    patchState({
      currentTrainingRequirement: Object.assign({}, currentTrainingRequirement)
    });
  }

  @Action(AddSpecialAirport)
  AddSpecialAirport(
    { patchState, getState }: StateContext<TrainingRequirementStateModel>,
    action: AddSpecialAirport
  ) {
    const currentTrainingRequirement = getState().currentTrainingRequirement;
    currentTrainingRequirement.specialAirports.push(action.specialAirport);

    patchState({
      currentTrainingRequirement: Object.assign({}, currentTrainingRequirement)
    });
  }

  @Action(UpdateSpecialAirport)
  UpdateSpecialAirport(
    { patchState, getState }: StateContext<TrainingRequirementStateModel>,
    action: UpdateSpecialAirport
  ) {
    const currentTrainingRequirement = getState().currentTrainingRequirement;

    const specialAirportIndex = currentTrainingRequirement.specialAirports.findIndex(
      (specialAirport: SpecialAirport) => {
        return specialAirport.value === action.oldCode;
      }
    );

    if (specialAirportIndex > -1) {
      currentTrainingRequirement.specialAirports[specialAirportIndex] = action.specialAirport;
    }

    patchState({
      currentTrainingRequirement: Object.assign({}, currentTrainingRequirement)
    });
  }

  @Action(UpdateSpecialAirports)
  UpdateSpecialAirports(
    { patchState, getState }: StateContext<TrainingRequirementStateModel>,
    action: UpdateSpecialAirports
  ) {
    const currentTrainingRequirement = getState().currentTrainingRequirement;

    currentTrainingRequirement.specialAirports = action.specialAirports;

    patchState({
      currentTrainingRequirement: Object.assign({}, currentTrainingRequirement)
    });
  }

  @Action(DeleteSpecialAirport)
  DeleteSpecialAirport(
    { patchState, getState }: StateContext<TrainingRequirementStateModel>,
    action: DeleteSpecialAirport
  ) {
    const currentTrainingRequirement = getState().currentTrainingRequirement;

    const specialAirportIndex = currentTrainingRequirement.specialAirports.findIndex(
      (specialAirport: SpecialAirport) => {
        return specialAirport.value === action.specialAirportId;
      }
    );

    if (specialAirportIndex > -1) {
      currentTrainingRequirement.specialAirports.splice(specialAirportIndex, 1);
    }

    patchState({
      currentTrainingRequirement: Object.assign({}, currentTrainingRequirement)
    });
  }

  @Action(UpdateCustomTcaProvided)
  UpdateCustomTcaProvided(
    { patchState, getState }: StateContext<TrainingRequirementStateModel>,
    action: UpdateCustomTcaProvided
  ) {
    getState().currentTrainingRequirement.isCustomTcaProvided = action.isCustomTcaProvided;
    patchState({
      currentTrainingRequirement: Object.assign({}, getState().currentTrainingRequirement)
    });
  }

  @Action(UpdateTrainingRequirement)
  updateTrainingRequirement(
    { patchState, getState, dispatch }: StateContext<TrainingRequirementStateModel>,
    action: SubmitTrainingRequirement
  ) {
    const currentTrainingRequirement = getState().currentTrainingRequirement;
    currentTrainingRequirement.submissionDateTime = new Date().toUTCString();

    this.trainingRequirementService.putTrainingRequirement(currentTrainingRequirement, true).pipe(
      updatedTrainingRequirement => {
        updatedTrainingRequirement.pipe(first()).subscribe(trainingRequirement => {
          this.store.dispatch(new LogAnalyticEvent('regular', 'Save TCA for later'));
          patchState({
              currentTrainingRequirement: Object.assign({}, trainingRequirement)
            });
        });
        return updatedTrainingRequirement;
      },
      catchError(err => {
        patchState({
          error: err
        });
        return err;
      })
    );
  }

  @Action(SubmitTrainingRequirement)
  submitTrainingRequirement(
    { patchState, getState, dispatch }: StateContext<TrainingRequirementStateModel>,
    action: SubmitTrainingRequirement
  ) {
    const currentTrainingRequirement = getState().currentTrainingRequirement;
    let validationErrors = new ValidationErrors();
    if (currentTrainingRequirement.regulationAgency !== RegulationAgencyName.FAA) {
      validationErrors = this.trainingRequirementValidationService.validate(
        currentTrainingRequirement
      );
    }

    if (validationErrors.isValid) {
      currentTrainingRequirement.submissionDateTime = new Date().toUTCString();

      this.trainingRequirementService
        .updateTrainingRequirement(currentTrainingRequirement, action.submitCommandEnabled)
        .pipe(
          updatedTrainingRequirement => {
            updatedTrainingRequirement.pipe(first()).subscribe(trainingRequirement => {
              this.store.dispatch(new LogAnalyticEvent('regular', 'TCA Form Submit'));
              if (Object.keys(trainingRequirement).length > 0) {
                // dispatch Success event if trainingRequirement is not an empty object
                dispatch(new UpdateTrainingRequirementSuccess(trainingRequirement, action.route));
              }
              else {
                //if trainingRequirement is an empty object just log the error.
                console.log("Fail to Submit TCA Form");
                this.loggerService.error('updateTrainingRequirement: Fail to Submit TCA Form');
              }
            });
            return updatedTrainingRequirement;
          },
          catchError(err => {
            patchState({
              error: err
            });
            return err;
          })
        );
    } else {
      patchState({
        ...getState(),
        validationErrors: validationErrors
      });
    }
  }

  @Action(UpdateCustomTcaErrors)
  UpdateCustomTcaErrors(
    { patchState }: StateContext<TrainingRequirementStateModel>,
    action: UpdateCustomTcaErrors
  ) {
    const validationErrors = this.trainingRequirementValidationService.validateCustomTcaErrors(
      action.isCustomTcaFileMissing
    );
    patchState({
      validationErrors: validationErrors
    });
  }

  @Action(UpdateLicenceErrors)
  updateLicenceErrors(
    { patchState }: StateContext<TrainingRequirementStateModel>,
    action: UpdateLicenceErrors
  ) {
    const validationErrors = this.trainingRequirementValidationService.validateLicence(
      action.licence,
      null,
      action.validateExpiryDate
    );
    patchState({
      validationErrors: validationErrors
    });
  }

  @Action(UpdateOmRefErrors)
  updateOmRefErrors(
    { patchState }: StateContext<TrainingRequirementStateModel>,
    action: UpdateOmRefErrors
  ) {
    const validationErrors = this.trainingRequirementValidationService.validateOmRef(
      action.items,
      action.pageName
    );
    patchState({
      validationErrors: validationErrors
    });
  }

  @Action(UpdateAirportErrors)
  updateAirportErrors(
    { patchState }: StateContext<TrainingRequirementStateModel>,
    action: UpdateAirportErrors
  ) {
    const validationErrors = this.trainingRequirementValidationService.validateAirport(
      action.items,
      action.pageName,
      action.validateOM,
      action.validateTrainingType
    );
    patchState({
      validationErrors: validationErrors
    });
  }

  @Action(UpdateTrainingProfileErrors)
  updateTrainingProfileErrors(
    { patchState }: StateContext<TrainingRequirementStateModel>,
    action: UpdateTrainingProfileErrors
  ) {
    const validationErrors = this.trainingRequirementValidationService.validateTrainingProfile(
      action.trainingEventRequirement
    );
    patchState({
      validationErrors: validationErrors
    });
  }

  @Action(UpdateCourseInformationErrors)
  updateCourseInformationErrors(
    { patchState }: StateContext<TrainingRequirementStateModel>,
    action: UpdateCourseInformationErrors
  ) {
    const validationErrors = this.trainingRequirementValidationService.validate(
      action.trainingEventRequirement
    );
    patchState({
      validationErrors: validationErrors
    });
  }

  @Action(UpdateTrainingRequirementSuccess)
  updateTrainingRequirementSuccess(
    { patchState, dispatch }: StateContext<TrainingRequirementStateModel>,
    action: UpdateTrainingRequirementSuccess
  ) {
    dispatch(new LoadTrainingRequirement(action.trainingRequirement.id, true));
    patchState({
      validationErrors: new ValidationErrors()
    });
    this.ngZone.run(() => {
      const approvers = this.store.selectSnapshot(ApprovalState.approvers);
      const user = this.store.selectSnapshot(LoginState.userId);

      if (user) {
        const approver = approvers.find(
          a => a.email === (<any>user).email || a.userId === (<any>user).email
        );
        if (!!approver && approver.role === ApproverRole.AccountExecutive) {
          this.router.navigate(['tca/' + action.trainingRequirement.id]);
        } else {
          this.router.navigate(['./confirmation'], { relativeTo: action.route });
        }
      }
      else {
        this.router.navigate(['./confirmation'], { relativeTo: action.route });
      }

    });
  }

  @Action(UpdateNote)
  UpdateNote(
    { patchState, getState }: StateContext<TrainingRequirementStateModel>,
    action: UpdateNote
  ) {
    getState().currentTrainingRequirement.note = action.note;
    patchState({
      currentTrainingRequirement: Object.assign({}, getState().currentTrainingRequirement)
    });
  }

  @Action(DeleteNote)
  DeleteNote(
    { patchState, getState }: StateContext<TrainingRequirementStateModel>,
    action: DeleteNote
  ) {
    getState().currentTrainingRequirement.note = '';
    patchState({
      currentTrainingRequirement: Object.assign({}, getState().currentTrainingRequirement)
    });
  }

  @Action(ClearAllEasaOptions)
  ClearAllEasaOptions(
    { patchState, getState }: StateContext<TrainingRequirementStateModel>,
    action: ClearAllEasaOptions
  ) {
    const currentTrainingRequirement = getState().currentTrainingRequirement;

    currentTrainingRequirement.specialAirports = [];

    currentTrainingRequirement.checkingAddons.forEach(item => {
      item.active = false;
      item.options.forEach(option => {
        if (option.category === 'Dual_Seat') {
          option.value = false;
        } else {
          option.value = null;
        }
        option.operatingManualReference = null;
      });
    });

    currentTrainingRequirement.trainingAddons.forEach(item => {
      item.active = false;
      item.options.forEach(option => {
        if (option.category === 'Dual_Seat') {
          option.value = false;
        } else {
          option.value = null;
        }
        option.operatingManualReference = null;
      });
    });

    currentTrainingRequirement.note = '';

    patchState({
      currentTrainingRequirement: Object.assign({}, getState().currentTrainingRequirement)
    });
  }

  @Action(ClearAllTCOptions)
  ClearAllTCOptions(
    { patchState, getState }: StateContext<TrainingRequirementStateModel>,
    action: ClearAllTCOptions
  ) {
    const currentTrainingRequirement = getState().currentTrainingRequirement;

    currentTrainingRequirement.specialAirports = [];

    currentTrainingRequirement.checkingAddons.forEach(item => {
      item.active = false;
      item.options.forEach(option => {

        option.value = false;
        option.operatingManualReference = null;
      });
    });

    currentTrainingRequirement.trainingAddons.forEach(item => {
      item.active = false;
      item.options.forEach(option => {
        option.value = false; option.value = null;
        option.operatingManualReference = null;
      });
    });

    currentTrainingRequirement.note = '';

    patchState({
      currentTrainingRequirement: Object.assign({}, getState().currentTrainingRequirement)
    });
  }

  @Action(ClearAllGenericOptions)
  ClearAllGenericOptions(
    { patchState, getState }: StateContext<TrainingRequirementStateModel>,
    action: ClearAllGenericOptions
  ) {
    const currentTrainingRequirement = getState().currentTrainingRequirement;

    currentTrainingRequirement.specialAirports = [];

    currentTrainingRequirement.checkingAddons.forEach(item => {
      item.active = false;
      item.options.forEach(option => {

        option.value = false;
        option.operatingManualReference = null;
      });
    });

    currentTrainingRequirement.trainingAddons.forEach(item => {
      item.active = false;
      item.options.forEach(option => {
        option.value = false; option.value = null;
        option.operatingManualReference = null;
      });
    });

    currentTrainingRequirement.note = '';

    patchState({
      currentTrainingRequirement: Object.assign({}, getState().currentTrainingRequirement)
    });
  }

  @Action(ClearAllFaaOptions)
  ClearAllFaaOptions(
    { patchState, getState }: StateContext<TrainingRequirementStateModel>,
    action: ClearAllFaaOptions
  ) {
    const currentTrainingRequirement = getState().currentTrainingRequirement;

    currentTrainingRequirement.checkingAddons.forEach(item => {
      item.options.forEach(option => {
        option.value = false;
      });
    });

    currentTrainingRequirement.trainingAddons.forEach(item => {
      item.options.forEach(option => {
        if (option.name === 'RVRDistance') {
          option.value = '';
        } else {
          option.value = false;
        }
      });
    });

    currentTrainingRequirement.note = '';

    patchState({
      currentTrainingRequirement: Object.assign({}, getState().currentTrainingRequirement)
    });
  }

  @Action(UpdateOperatedManualReference)
  updateOperatedManualReference(
    { patchState, getState }: StateContext<TrainingRequirementStateModel>,
    action: UpdateOperatedManualReference
  ) {
    const trainingRequirement = Object.assign({}, getState().currentTrainingRequirement);
    trainingRequirement[`operatingManual${action.id}`] = action.value;
    patchState({
      ...getState(),
      currentTrainingRequirement: trainingRequirement
    });
  }

  @Action(UpdateTcaSubmitted)
  updateTcaSubmitted(
    { patchState, getState }: StateContext<TrainingRequirementStateModel>,
    action: UpdateTcaSubmitted
  ) {
    getState().currentTrainingRequirement.submissionStatus = action.submissionStatus;
    patchState({
      currentTrainingRequirement: Object.assign({}, getState().currentTrainingRequirement),
      originalTrainingRequirementValue: JSON.stringify(getState().currentTrainingRequirement)
    });
  }

  @Action(AddSchedule)
  addSchedule(
    { dispatch, patchState, getState }: StateContext<TrainingRequirementStateModel>,
    action: AddSchedule
  ) {
    const command = this.buildAddScheduleCommand(
      action.revisionNumber,
      action.addOnSchedules,
      action.moduleType
    );
    this.trainingRequirementService
      .addSchedule(action.trainingRequirementId, command)
      .pipe(
        tap(() => {
          dispatch(new LogAnalyticEvent('regular', 'Add Schedule TCA'));
          dispatch(new LoadTrainingRequirement(action.trainingRequirementId, true));
        })
      )
      .subscribe();
    this.ngZone.run(() => {
      this.router.navigate([action.returnUrl, { scrollToAnchor: action.moduleType }]);
    });
  }

  @Action(UpdatetTotalFlightTime)
  UpdatetTotalFlightTime(
    { patchState, getState }: StateContext<TrainingRequirementStateModel>,
    action: UpdatetTotalFlightTime
  ) {
    const currentTrainingRequirement = getState().currentTrainingRequirement;

    currentTrainingRequirement.totalFlightTime = action.totalFlightTime;

    patchState({
      currentTrainingRequirement: Object.assign({}, getState().currentTrainingRequirement)
    });
  }

  @Action(UpdateFlightTimeInAircraftType)
  UpdateFlightTimeInAircraftType(
    { patchState, getState }: StateContext<TrainingRequirementStateModel>,
    action: UpdateFlightTimeInAircraftType
  ) {
    const currentTrainingRequirement = getState().currentTrainingRequirement;

    currentTrainingRequirement.flightTimeInAircraftType = action.flightTimeInAircraftType;

    patchState({
      currentTrainingRequirement: Object.assign({}, getState().currentTrainingRequirement)
    });
  }

  @Action(UpdatetMultiEngineLandandInstrumentRating)
  UpdatetMultiEngineLandandInstrumentRating(
    { patchState, getState }: StateContext<TrainingRequirementStateModel>,
    action: UpdatetMultiEngineLandandInstrumentRating
  ) {
    const currentTrainingRequirement = getState().currentTrainingRequirement;

    currentTrainingRequirement.hasMultiEngineLandandInstrumentRating = action.multiEngineLandInstrumentRating;

    patchState({
      currentTrainingRequirement: Object.assign({}, getState().currentTrainingRequirement)
    });
  }

  @Action(UpdateSeat)
  UpdateSeat(
    { patchState, getState }: StateContext<TrainingRequirementStateModel>,
    action: UpdateSeat
  ) {
    const currentTrainingRequirement = getState().currentTrainingRequirement;

    currentTrainingRequirement.overrideSeat = action.overrideSeat;

    patchState({
      currentTrainingRequirement: Object.assign({}, getState().currentTrainingRequirement)
    });
  }

  @Action(UpdateOperatingAircraftTypeSerialNumber)
  UpdateOperatingAircraftTypeSerialNumber(
    { patchState, getState }: StateContext<TrainingRequirementStateModel>,
    action: UpdateOperatingAircraftTypeSerialNumber
  ) {
    const currentTrainingRequirement = getState().currentTrainingRequirement;

    currentTrainingRequirement.operatingAircraftTypeSerialNumber = action.operatingAircraftTypeSerialNumber;

    patchState({
      currentTrainingRequirement: Object.assign({}, getState().currentTrainingRequirement)
    });
  }

  @Action(LogAnalyticEvent)
  trackEvent({ getState }: StateContext<TrainingRequirementStateModel>, action: LogAnalyticEvent) {
    const currentTrainingRequirement = getState().currentTrainingRequirement;
    const currentUser = this.store.selectSnapshot(LoginState.userId);
    if (!!currentTrainingRequirement) {
      switch (action.eventType.toLocaleLowerCase()) {
        case LogAnalyticEventTypes.SubPage:
          this.analyticsService.logAnalytic(
            action.eventName,
            this.GetTcaSubpageAnalyticProperties(
              action.pageName,
              !!currentUser ? currentUser.id : '',
              currentTrainingRequirement.clientId,
              currentTrainingRequirement.customerId,
              currentTrainingRequirement.id,
              currentTrainingRequirement.revisionNumber,
              !!action.approverRole ? action.approverRole : 'N/A'
            )
          );
          break;
        case LogAnalyticEventTypes.Visibility:
          this.analyticsService.logAnalytic(
            action.eventName,
            this.GetTcaFormVisibilityAnalyticProperties(
              action.status,
              !!currentUser ? currentUser.id : '',
              currentTrainingRequirement.clientId,
              currentTrainingRequirement.customerId,
              currentTrainingRequirement.id,
              currentTrainingRequirement.revisionNumber,
              !!action.approverRole ? action.approverRole : 'N/A'
            )
          );
          break;
        case LogAnalyticEventTypes.ErrorMessage:
          this.analyticsService.logAnalytic(
            action.eventName,
            this.GetTcaErrorMessageAnalyticProperties(
              action.pageName,
              action.errorName,
              action.errorMessage,
              !!currentUser ? currentUser.id : '',
              currentTrainingRequirement.clientId,
              currentTrainingRequirement.customerId,
              currentTrainingRequirement.id,
              currentTrainingRequirement.revisionNumber,
              !!action.approverRole ? action.approverRole : 'N/A'
            )
          );
          break;
        case LogAnalyticEventTypes.WarningMessage:
          this.analyticsService.logAnalytic(
            action.eventName,
            this.GetTcaWarningMessageAnalyticProperties(
              action.pageName,
              action.errorName,
              action.errorMessage,
              !!currentUser ? currentUser.id : '',
              currentTrainingRequirement.clientId,
              currentTrainingRequirement.customerId,
              currentTrainingRequirement.id,
              currentTrainingRequirement.revisionNumber,
              !!action.approverRole ? action.approverRole : 'N/A'
            )
          );
          break;
        default:
          this.analyticsService.logAnalytic(
            action.eventName,
            this.GetTcaAnalyticProperties(
              !!currentUser ? currentUser.id : '',
              currentTrainingRequirement.clientId,
              currentTrainingRequirement.customerId,
              currentTrainingRequirement.id,
              currentTrainingRequirement.revisionNumber,
              !!action.approverRole ? action.approverRole : 'N/A'
            )
          );
          break;
      }
    }
  }
  @Action(UpdateAircraftOperationCertificate)
  updateAircraftOperationCertificate(
    { getState, patchState }: StateContext<TrainingRequirementStateModel>,
    action: UpdateAircraftOperationCertificate
  ) {
    const currentTrainingRequirement = getState().currentTrainingRequirement;
    currentTrainingRequirement.aircraftOperatingCertificate = action.aircraftOperationCertificate;
    patchState({
      currentTrainingRequirement: Object.assign({}, getState().currentTrainingRequirement)
    });
  }

  GetTcaAnalyticProperties(
    userId: string,
    clientId: string,
    customerId: string,
    trainingReqId: string,
    revision_number: number,
    userRole: string
  ): AnalyticsProperties {
    return new AnalyticsProperties({
      client_id: clientId,
      customer_id: customerId,
      platform: 'Web',
      res_num: String(trainingReqId),
      app_name: 'TCAWebapp',
      user_id: userId,
      user_email: userId,
      editor_user_id: userId,
      revision_number: revision_number,
      app_version: environment.buildNumber,
      approver_role: userRole
    });
  }

  GetTcaFormVisibilityAnalyticProperties(
    status: string,
    userId: string,
    clientId: string,
    customerId: string,
    trainingReqId: string,
    revision_number: number,
    userRole: string
  ): FormVisibilityAnalyticsProperties {
    return new FormVisibilityAnalyticsProperties({
      client_id: clientId,
      customer_id: customerId,
      platform: 'Web',
      res_num: String(trainingReqId),
      app_name: 'TCAWebapp',
      user_id: userId,
      user_email: userId,
      editor_user_id: userId,
      status: status,
      revision_number: revision_number,
      app_version: environment.buildNumber,
      approver_role: userRole
    });
  }

  GetTcaSubpageAnalyticProperties(
    subpageName: string,
    userId: string,
    clientId: string,
    customerId: string,
    trainingReqId: string,
    revision_number: number,
    userRole: string
  ): SubpageAnalyticsProperties {
    return new SubpageAnalyticsProperties({
      client_id: clientId,
      customer_id: customerId,
      platform: 'Web',
      res_num: String(trainingReqId),
      app_name: 'TCAWebapp',
      user_id: userId,
      user_email: userId,
      editor_user_id: userId,
      subpage_name: subpageName,
      revision_number: revision_number,
      app_version: environment.buildNumber,
      approver_role: userRole
    });
  }

  GetTcaErrorMessageAnalyticProperties(
    pageName: string,
    errorName: string,
    errorMessage: string,
    userId: string,
    clientId: string,
    customerId: string,
    trainingReqId: string,
    revision_number: number,
    userRole: string
  ): ErrorMessageAnalyticsProperties {
    return new ErrorMessageAnalyticsProperties({
      client_id: clientId,
      customer_id: customerId,
      platform: 'Web',
      res_num: String(trainingReqId),
      app_name: 'TCAWebapp',
      user_id: userId,
      user_email: userId,
      editor_user_id: userId,
      page_name: pageName,
      error_name: errorName,
      error_message: errorMessage,
      revision_number: revision_number,
      app_version: environment.buildNumber,
      approver_role: userRole
    });
  }

  GetTcaWarningMessageAnalyticProperties(
    pageName: string,
    warningName: string,
    warningMessage: string,
    userId: string,
    clientId: string,
    customerId: string,
    trainingReqId: string,
    revision_number: number,
    userRole: string
  ): WarningMessageAnalyticsProperties {
    return new WarningMessageAnalyticsProperties({
      client_id: clientId,
      customer_id: customerId,
      platform: 'Web',
      res_num: String(trainingReqId),
      app_name: 'TCAWebapp',
      user_id: userId,
      user_email: userId,
      editor_user_id: userId,
      page_name: pageName,
      warning_name: warningName,
      warning_message: warningMessage,
      revision_number: revision_number,
      app_version: environment.buildNumber,
      approver_role: userRole
    });
  }

  ResetAddOnOperatingManualReference(items: Array<Item>) {
    items.forEach(element => {
      if (element.value === 'none' || element.value === false) {
        element.operatingManualReference = null;
      }
    });
  }

  private buildAddScheduleCommand(
    revisionNumber: number,
    addOn: AddOn,
    moduleType: ModuleTypes
  ): ScheduleSessionCommand {
    const result = new ScheduleSessionCommand();

    if (!!addOn && !!addOn.options) {
      result.ModuleType = moduleType;
      result.RevisionNumber = revisionNumber;
      result.Sessions = addOn.options
        .filter(o => this.hasOptionWithSchedule(o))
        .map(o => this.buildSessionItem(o));
    }

    return result;
  }

  private buildSessionItem(item: Item): SessionItem {
    const result = new SessionItem();

    if (!!item && !!item.schedule) {
      result.SelectedItem = item.name;
      result.Date = item.schedule.date;
      result.Session = item.schedule.session;
    }

    return result;
  }

  private hasOptionWithSchedule(option: Item): boolean {
    return (
      ((option.value !== 'none' && option.value !== false && !!option.value) ||
        option.value === true) &&
      !!option.schedule
    );
  }
}
