import * as _ from 'lodash';

import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { AppState } from 'src/app/state/app.state';
import { AuthB2CService } from 'common-web-core';
import { AuthService } from 'src/app/core/services';
import { BasePageComponent } from 'src/app/shared/pages';
import { LaunchDarklyService } from 'src/app/services/feature-flags/launch-darkly.service';
import { LoggerService } from 'src/app/logger/services/logger.service';
import { LoginState } from 'src/app/state/login.state';
import { RegulationAgencyName } from 'src/app/models/RegulationAgencyName';
import { Store } from '@ngxs/store';
import { TcaService } from 'src/app/services/data-layer/tca.service';
import { ThemeSkin } from 'common-web-ui';
import { ToggleFeatures } from 'src/app/data/toggle-features';

const LOADING_TIMEOUT_SECONDS = 15;

@Component({
  selector: 'app-login-page-main',
  templateUrl: './login-page-main.component.html',
  styleUrls: ['./login-page-main.component.less']
})
export class LoginPageMainComponent extends BasePageComponent implements OnInit {
  isAzureADEnabled = false;
  isAzureB2CEnabled = false;
  isAzureToSalesforce = false;
  returnUrl = '';
  showWaitingScreen = true;
  reservationId = '';
  auth_type = '';

  readonly themeSkin = ThemeSkin;

  constructor(private router: Router, private store: Store, private authService: AuthService, private route: ActivatedRoute, private oauthService: AuthB2CService, private tcaService: TcaService, private ldService: LaunchDarklyService, private loggerService: LoggerService) {
    super();

    this.returnUrl = decodeURIComponent(this.route.snapshot.queryParams.returnUrl);

    if (this.returnUrl) {

      const params = this.getQueryParams(this.returnUrl);

      this.auth_type = params.auth_type;

      if (params.auth_type == 'b2c') {
        this.oauthService.CustomRedirectState = this.returnUrl.replace('&auth_type=b2c', '').replace('?auth_type=b2c', '');
        this.oauthService.login();
      }
      else {

        // get reservation id
        const splits = this.returnUrl.split('/');
        const regulatoryAgencies = Object.keys(RegulationAgencyName).map(x => RegulationAgencyName[x].toString()).filter(x => x != 'none');
        regulatoryAgencies.push('tca');
        if (regulatoryAgencies.includes(splits[splits.length - 2])) {
          this.reservationId = splits[splits.length - 1];
        }
      }
    }
  }

  private getQueryParams(url: string): { [key: string]: string } {

    let params: { [key: string]: string } = {};

    if (url) {

      params = url.split(/[?&]/).reduce((acc: any, cv: string) => {
        if (cv) {
          const splits = cv.split('=');
          if (splits.length == 2) {
            acc[splits[0]] = decodeURIComponent(splits[1]);
          }
        }
        return acc;
      }, {});
    }

    return params;
  }

  async ngOnInit() {

    if (this.auth_type != 'b2c') {
      if (this.reservationId) {

        try {
          const customerId = await this.tcaService.getCustomerId(this.reservationId);
          // update launch darkly user to include the customer id
          const currentUser = this.store.selectSnapshot(LoginState.userId);
          const newLDUser = currentUser ? { ...currentUser, custom: { customer: customerId } } : { email: customerId, key: customerId, custom: { customer: customerId } };
          await this.ldService.identify(newLDUser);
          if (this.ldService.getFlag(ToggleFeatures.CustomerWebAppAzureB2CLogin)) {
            this.oauthService.CustomRedirectState = this.returnUrl;
            await this.oauthService.login();
          }
          else {
            this.showWaitingScreen = false;
          }
        }
        catch (e) {
          this.loggerService.error('An error occured while trying to get customerId', e);
          this.showWaitingScreen = false;
        }

      } else {
        this.showWaitingScreen = false;
      }
    }

    super.subscribe(
      this.store
        .select(AppState.isFeatureActive(ToggleFeatures.CustomerWebAppAzureADLogin))
        .subscribe(isEnabled => {
          this.isAzureADEnabled = isEnabled;
        })
    );

    super.subscribe(
      this.store
        .select(AppState.isFeatureActive(ToggleFeatures.CustomerWebAppAzureADToSalesforceLogin))
        .subscribe(isEnabled => {
          this.isAzureToSalesforce = isEnabled;
        })
    );

    // After 5 seconds, remove waiting screen if it is still being displayed
    setTimeout(() => {
      this.showWaitingScreen = false;
    }, LOADING_TIMEOUT_SECONDS * 1000);
  }

  onSubmit(values: any) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        login_hint: values.email
      }
    };

    if (this.isTrainingRequirementLogin() && !this.isAzureToSalesforce) {
      if (values.email.toLowerCase().endsWith('@cae.com')) {
        this.router.navigate(['login', 'selection'], navigationExtras);
        return;
      }
    }

    if (
      values.email.endsWith('cae.com') &&
      this.isAzureADEnabled &&
      !this.isTrainingRequirementLogin()
    ) {
      this.router.navigate(['/login-azure-ad'], navigationExtras);
    } else {
      this.router.navigate(['/login-salesforce'], navigationExtras);
    }
  }

  private isTrainingRequirementLogin(): boolean {
    const retUrl = this.authService.getReturnUrl();
    if (!!retUrl) {
      return _.includes(retUrl, '/tca') || _.includes(retUrl, '/crew-management');
    }

    return this.isAzureToSalesforce;
  }
}
