import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService, Oauth } from 'src/app/core/services/auth.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login-salesforce-page',
  templateUrl: './login-salesforce-page.component.html',
  styleUrls: ['./login-salesforce-page.component.less']
})
export class LoginSalesForcePageComponent implements OnInit, OnDestroy {
  salesForceOauth: Oauth;
  returnUrl: string;
  subscription: Subscription;

  constructor(private authService: AuthService, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.authService.login(params['login_hint']);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
