import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor() {}

  saveToLocalStorage(itemName: string, itemValue: string) {
    localStorage.setItem(itemName, itemValue);
  }

  getFromLocalStorage(itemName: string) {
    return localStorage.getItem(itemName);
  }

  deleteFromLocalStorage(itemName: string) {
    return localStorage.removeItem(itemName);
  }

  saveToSessionStorage(itemName: string, itemValue: string) {
    window.sessionStorage.setItem(itemName, itemValue);
  }

  getFromSessionStorage(itemName: string) {
    return window.sessionStorage.getItem(itemName);
  }

  deleteFromSessionStorage(itemName: string) {
    return window.sessionStorage.removeItem(itemName);
  }
}
