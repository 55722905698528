import { Action, State, StateContext } from '@ngxs/store';

import { AnalyticsBaseProperties } from '../models/AnalyticsBaseProperties';
import { AnalyticsService } from 'src/app/services/analytics-service';
import { Injectable } from '@angular/core';
import { SendLogAnalyticEvent } from './app-analytic.state.action';

export interface AppAnalyticStateModel {
  eventName: LogAnalyticEventNames;
  isSentSuccessfully: boolean;
  eventProperties: AnalyticsBaseProperties;
}

export enum LogAnalyticEventTypes {
  Unknown = '',
  SubPage = 'subpage',
  Visibility = 'visibility',
  ErrorMessage = 'errormessage',
  WarningMessage = 'warningmessage'
}

export enum LogAnalyticEventNames {
  Unknown = '',
  PageLoaded = 'Page Loaded',
  PageUnloaded = 'Page Unloaded',
  ButtonClicked = 'Button Clicked',
  FileDownloaded = 'File Downloaded',
  FileUploaded = 'File Added',
  FileDeleted = 'File Deleted',
  ElementUpdated = 'Element Updated'
}

@State<AppAnalyticStateModel>({
  name: 'appAnalytic',
  defaults: {
    eventName: LogAnalyticEventNames.Unknown,
    eventProperties: null,
    isSentSuccessfully: false
  }
})
@Injectable()
export class AppAnalyticState {
  constructor(private _analyticsService: AnalyticsService) { }

  @Action(SendLogAnalyticEvent)
  sendAnalyticEvent(
    { patchState }: StateContext<AppAnalyticStateModel>,
    action: SendLogAnalyticEvent<AnalyticsBaseProperties>
  ) {
    this._analyticsService.logAnalyticEvent(action.eventName, action.eventProperties);
    // not used, but if required last event is kept
    patchState({
      eventName: action.eventName,
      eventProperties: action.eventProperties,
      isSentSuccessfully: true
    });
  }
}
