import { Injector, NgModule } from '@angular/core';
import { LoggerModule, NGXLogger, NgxLoggerLevel } from 'ngx-logger';

import { AppInsightsLogAppender } from './models/app-insights.appender';
import { AppInsightsService } from '@markpieszak/ng-application-insights';
import { AppSettings } from '../models/AppSettings';
import { AppSettingsService } from 'common-web-core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { LoggerService } from './services/logger.service';
import { NgxLoggerAppender } from './models/ngx-logger.appender';

@NgModule({
  declarations: [],
  imports: [LoggerModule.forRoot({ level: NgxLoggerLevel.OFF })]
})
export class AppLoggerModule {
  constructor(
    private appInsightsService: AppInsightsService,
    private ngxLogger: NGXLogger,
    private loggerService: LoggerService,
    private appSettingsService: AppSettingsService<AppSettings>) 
  {
    this.appSettingsService.appSettings$.subscribe((appSettings: AppSettings) => {
      const defaultLogLevel = appSettings.loggerSettings.defaultLogLevel;
      const appInsightsLogLevel = appSettings.loggerSettings.appInsightsLogLevel;
      const ngxLoggerLogLevel = appSettings.loggerSettings.ngxLoggerLogLevel;
      this.initLoggerService(defaultLogLevel, appInsightsLogLevel, ngxLoggerLogLevel);
    });        
  }

  private initLoggerService(defaultLogLevel: string, appInsightsLogLevel: string, ngxLoggerLogLevel: string): void {
    if (!!defaultLogLevel) {
      this.loggerService.logLevel = defaultLogLevel;
    }
    this.loggerService.addAppender(new AppInsightsLogAppender(this.appInsightsService), appInsightsLogLevel);
    this.loggerService.addAppender(new NgxLoggerAppender(this.ngxLogger), ngxLoggerLogLevel);
  }
}
