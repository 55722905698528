import { Component, OnInit, Input } from '@angular/core';
import { PresetSummary } from 'src/app/models/PresetSummary';

@Component({
  selector: 'app-preset-training-options-item-summary',
  templateUrl: './preset-training-options-item-summary.component.html',
  styleUrls: ['./preset-training-options-item-summary.component.less']
})
export class PresetTrainingOptionsItemSummaryComponent implements OnInit {
  @Input() item: PresetSummary;

  constructor() {
  }
  ngOnInit() { }
}
