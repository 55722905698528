import { Item } from './Item';

export class AddOn {
  regulationAgency: string;
  name: string;
  title: string;
  description: string;
  active: boolean;
  options: Item[];

  constructor() {
    this.options = Array<Item>();
  }
}
