import { ModuleTypes } from '../data/module-types';
import { AddOn } from '../models/Addon';
import { ApproveCommand } from '../models/ApproveCommand';
import { ApproverRole } from '../models/Approver';
import { Item } from '../models/Item';
import { Licence } from '../models/Licence';
import { OMVersion } from '../models/OMVersion';
import { SpecialAirport } from '../models/SpecialAirport';
import { SubmissionStatus } from '../models/SubmissionStatus';
import { TrainingRequirement } from '../models/TrainingRequirement';
import { Authority } from '../models/Authority';
import { ActivatedRoute } from '@angular/router';
import { TrainingProfile } from '../models/TrainingProfile';

export class ClearCurrentTrainingRequirement {
  static readonly type = '[TrainingRequirement] Set Current TrainingRequirement';
  constructor() { }
}

export class StoreTrainingRequirement {
  static readonly type = '[TrainingRequirement] Store TrainingRequirement in store';
  constructor(public trainingRequirement: TrainingRequirement) { }
}

export class LoadTrainingRequirement {
  static readonly type = '[LoadTrainingRequirement] Load';
  constructor(public trainingRequirementId: string, public isRefreshRequired: boolean = false) { }
}

export class LoadTrainingRequirementSuccess {
  static readonly type = '[LoadTrainingRequirement] Load Success';
  constructor(public trainingRequirement: TrainingRequirement, public doRouting: boolean = false) { }
}

export class UpdateTrainingRequirement {
  static readonly type = '[TrainingRequirement] Update TrainingRequirement';
  constructor() { }
}

export class SubmitTrainingRequirement {
  static readonly type = '[TrainingRequirement] Submit TrainingRequirement';
  constructor(public submitCommandEnabled: boolean = false, public route: ActivatedRoute) { }
}

export class UpdateLicenceErrors {
  static readonly type = '[TrainingRequirement] Update Licence Errors';
  constructor(public licence: Licence, public validateExpiryDate: boolean = true) { }
}

export class UpdateCourseInformationErrors {
  static readonly type = '[TrainingRequirement] Update Course Information Errors';
  constructor(public trainingEventRequirement: TrainingRequirement) { }
}

export class UpdateTrainingProfileErrors {
  static readonly type = '[TrainingRequirement] Update Training Profile Errors';
  constructor(public trainingEventRequirement: TrainingRequirement) { }
}

export class UpdateOmVersionErrors {
  static readonly type = '[TrainingRequirement] Update OM Version Errors';
  constructor(public omVersions: Array<OMVersion>) { }
}

export class UpdateOmRefErrors {
  static readonly type = '[TrainingRequirement] Update OmRef Errors';
  constructor(public items: Array<Item>, public pageName: string) { }
}

export class UpdateAirportErrors {
  static readonly type = '[TrainingRequirement] Update Airport Errors';
  constructor(public items: Array<Item>, public pageName: string, public validateOM: boolean, public validateTrainingType: boolean = false) { }
}

export class UpdateTrainingRequirementSuccess {
  static readonly type = '[TrainingRequirement] Update TrainingRequirement Success';
  constructor(public trainingRequirement: TrainingRequirement, public route: ActivatedRoute) { }
}

export class UpdateCheckingAddOn {
  static readonly type = '[TrainingRequirement] Update checking add on';
  constructor(public addOn: AddOn) { }
}

export class DeleteCheckingAddOn {
  static readonly type = '[TrainingRequirement] Delete checking add on';
  constructor(public addOn: AddOn) { }
}

export class UpdateTrainingAddOn {
  static readonly type = '[TrainingRequirement] Update training add on';
  constructor(public item: AddOn) { }
}

export class DeleteTrainingAddOn {
  static readonly type = '[TrainingRequirement] Delete training add on';
  constructor(public item: AddOn) { }
}

export class UpdateCheckType {
  static readonly type = '[TrainingRequirement] Update check type';
  constructor(public checkType: string) { }
}

export class UpdateOtherCheckType {
  static readonly type = '[TrainingRequirement] Update other check type';
  constructor(public checkType: string, public authority: string, public additionalInfo?: { [key: string]: string }) { }
}

export class RemoveOtherCheckType {
  static readonly type = '[TrainingRequirement] Remove other check type';
  constructor(public authority: string) { }
}

export class AddLicence {
  static readonly type = '[TrainingRequirement] Add licence';
  constructor(public licence: Licence) { }
}

export class UpdateLicence {
  static readonly type = '[TrainingRequirement] Update licence';
  constructor(public licence: Licence) { }
}

export class DeleteLicence {
  static readonly type = '[TrainingRequirement] Delete licence';
  constructor(public licenceId: string) { }
}

export class AddSpecialAirport {
  static readonly type = '[TrainingRequirement] Add Special Airport';
  constructor(public specialAirport: SpecialAirport) { }
}

export class UpdateSpecialAirport {
  static readonly type = '[TrainingRequirement] Update Special Airport';
  constructor(public specialAirport: SpecialAirport, public oldCode: string) { }
}

export class UpdateSpecialAirports {
  static readonly type = '[TrainingRequirement] Update Special Airports';
  constructor(public specialAirports: SpecialAirport[]) { }
}

export class UpdateOperatedManualReference {
  static readonly type = '[TrainingRequirement] Update Operated Manual Reference';
  constructor(public id: string, public value: string) { }
}

export class ApproveTca {
  static readonly type = '[TrainingRequirement] Approve Tca';
  constructor() { }
}

export class Approve {
  static readonly type = '[TrainingRequirement] Approve';
  constructor(public approveCommand: ApproveCommand) { }
}

export class UpdateTcaStatus {
  static readonly type = '[TrainingRequirement] Approve Tca';
  constructor(public role: ApproverRole, public tcaStatus: string) { }
}

export class UpdateCustomTcaProvided {
  static readonly type = '[TrainingRequirement] Update CustomTcaProvided flag';
  constructor(public isCustomTcaProvided: boolean) { }
}

export class UpdateCustomTcaErrors {
  static readonly type = '[TrainingRequirement] Update custom TCA errors';
  constructor(public isCustomTcaFileMissing: boolean) { }
}

export class DeleteSpecialAirport {
  static readonly type = '[TrainingRequirement] Delete Special Airport';
  constructor(public specialAirportId: string) { }
}

export class UpdateNote {
  static readonly type = '[TrainingRequirement] Update Note';
  constructor(public note: string) { }
}

export class DeleteNote {
  static readonly type = '[TrainingRequirement] Delete Note';
  constructor() { }
}

export class ClearAllEasaOptions {
  static readonly type = '[TrainingRequirement] Delete all EASA options';
  constructor() { }
}

export class ClearAllFaaOptions {
  static readonly type = '[TrainingRequirement] Delete all FAA options';
  constructor() { }
}


export class ClearAllTCOptions {
  static readonly type = '[TrainingRequirement] Delete all TC options';
  constructor() { }
}


export class ClearAllGenericOptions {
  static readonly type = '[TrainingRequirement] Delete all Generic options';
  constructor() { }
}

export class ValidateTca {
  static readonly type = '[TrainingRequirement] Validate Tca';
  constructor() { }
}

export class UpdateTrainingRequirementHttp {
  static readonly type = '[TrainingRequirement] Update TrainingRequirement Http';
  constructor() { }
}

export class UpdateTcaSubmitted {
  static readonly type = '[TrainingRequirement] Update TCA Submitted';
  constructor(public submissionStatus: SubmissionStatus) { }
}

export class LogAnalyticEvent {
  static readonly type = '[TrainingRequirement] Log Analytic Event';
  constructor(
    public eventType: string,
    public eventName: string,
    public pageName?: string,
    public status?: string,
    public errorName?: string,
    public errorMessage?: string,
    public approverRole?: string
  ) { }
}
export class UpdateAircraftOperationCertificate {
  static readonly type = '[TrainingRequirement] Update Aircraft Operation Certificate';
  constructor(public aircraftOperationCertificate: string) { }
}

export class UpdatetTotalFlightTime {
  static readonly type = '[TrainingRequirement] Update reservation Total Flight Time';
  constructor(public totalFlightTime: number) { }
}

export class UpdatetMultiEngineLandandInstrumentRating {
  static readonly type = '[TrainingRequirement] Update reservation Multi Engine Land and Instrument Rating';
  constructor(public multiEngineLandInstrumentRating: boolean) { }
}

export class UpdateFlightTimeInAircraftType {
  static readonly type = '[TrainingRequirement] Update Flight Time of Selected Aircraft Type';
  constructor(public flightTimeInAircraftType: number) { }
}

export class UpdateSeat {
  static readonly type = '[TrainingRequirement] Update Seat';
  constructor(public overrideSeat: string) { }
}

export class UpdateOperatingAircraftTypeSerialNumber {
  static readonly type = '[TrainingRequirement] Update Operating Aircraft Serial Number';
  constructor(public operatingAircraftTypeSerialNumber: string) { }
}

export class UpdateTrainingProfile {
  static readonly type = '[TrainingRequirement] Update Training Profile';
  constructor(public trainingProfile: TrainingProfile) { }
}

export class AddSchedule {
  static readonly type = '[TrainingRequirement] Add Schedule';
  constructor(
    public trainingRequirementId: string,
    public revisionNumber,
    public addOnSchedules: AddOn,
    public returnUrl: string,
    public moduleType: ModuleTypes
  ) { }
}
