import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { MessageService } from './services/message.service';
import { LoggerService } from './logger/services/logger.service';

/** Type of the handleError function returned by HttpErrorHandler.createHandleError */
export type handleHttpError = <T>(
  operation?: string,
  result?: T
) => (error: HttpErrorResponse) => Observable<T>;

/** Handles HttpClient errors */
@Injectable()
export class HttpErrorHandler implements ErrorHandler {
  constructor(private loggerService: LoggerService, private messageService: MessageService) {}

  /** Create curried handleError function that already knows the service name */
  createHandleError = (serviceName = '') => <T>(operation = 'operation', result = {} as T) =>
    this.handleHttpError(serviceName, operation, result);

  /**
   * Returns a function that handles Http operation failures.
   * This error handler lets the app continue to run as if no error occurred.
   * @param serviceName = name of the data service that attempted the operation
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  handleHttpError<T>(serviceName = '', operation = 'operation', result = {} as T) {
    return (error: HttpErrorResponse): Observable<T> => {
      this.loggerService.error('httpError', error);

      const message =
        error.error instanceof ErrorEvent
          ? error.error.message
          : `server returned code ${error.status} with body "${JSON.stringify(error.error)}"`;

      this.messageService.pushException(
        new Error(`${serviceName}: ${operation} failed: ${message}`)
      );

      // Let the app keep running by returning a safe result.
      return of(result);
    };
  }

  handleError(error: any): void {
    this.messageService.pushException(new Error(error));
    this.loggerService.error('httpError', error);
  }
}
