import { Directive, OnInit, OnDestroy, EventEmitter, Optional, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription, BehaviorSubject } from 'rxjs';
import { FormState, FormChangesService } from 'src/app/core/services';

@Directive({
  selector: '[appFormChangesWatcher]'
})
export class FormChangesWatcherDirective implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  private currentDirtyValue = false;
  private areValuesDifferent = false;
  private formState: FormState = FormState.DefaultValues;
  private initialFormState: FormState = FormState.DefaultValues;
  private valueChangedSubject: BehaviorSubject<boolean>;
  private initialFormValues = '';
  private initialLoadCompleted = false;

  @Output() formDirtyChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() formDefaultValuesChanged: EventEmitter<boolean> = new EventEmitter();

  constructor(@Optional() private ngForm: NgForm, private formChangesService: FormChangesService) {
    this.valueChangedSubject = new BehaviorSubject(this.areValuesDifferent);
  }

  ngOnInit(): void {
    // Make sure we do have a form as a reference
    if (this.ngForm !== undefined && this.ngForm != null) {
      this.subscriptions.push(
        // Everytime a form control value changes, this callback is fired
        this.ngForm.valueChanges.subscribe(() => {
          // Are we actually loading the form with initial values?
          if (!this.ngForm.dirty && !this.initialLoadCompleted) {
            this.initialFormValues = this.sortAndStringify(this.ngForm.value);
            this.validateFormState();
            this.initialFormState = this.formState;
          } else {
            // Something changed in our form,
            // therefore we have to fetch current form values and compare them with initial state
            this.initialLoadCompleted = true;
            const currentFormValues = this.sortAndStringify(this.ngForm.value);
            const valuesDiffer = this.initialFormValues !== currentFormValues;

            this.setValuesDiffer(valuesDiffer);
            this.validateFormState();
          }
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.reset();
  }

  private reset(): void {
    this.initialFormValues = '';
    this.currentDirtyValue = false;
    this.areValuesDifferent = false;
  }

  private setValuesDiffer(differ: boolean) {
    this.areValuesDifferent = differ;
    this.valueChangedSubject.next(this.areValuesDifferent);

    // Do form values differ from initial state ?
    if (this.areValuesDifferent) {
      // First time form is dirty ?
      if (!this.currentDirtyValue) {
        this.formDirtyChanged.emit(true);
        this.currentDirtyValue = true;

        // Notify subscribers that form is now dirty.
        // Typically, this is where external services would want to subscribe and get status asynchronously
        // Alternatively, we can always listen to the event emitter available from this directive
      }

      this.formValueChanged.emit(true);
    } else {
      // Form values back to initial state, consider the form as being pristine
      this.formValueChanged.emit(true);
      this.formDirtyChanged.emit(false);
      this.ngForm.form.markAsPristine();
      this.currentDirtyValue = false;
    }
  }

  private validateFormState(): boolean {
    let hasDefaultValues = true;
    this.formState = FormState.DefaultValues;
    for (const key in this.ngForm.controls) {
      if (this.ngForm.controls.hasOwnProperty(key)) {
        const element = this.ngForm.controls[key];
        hasDefaultValues =
          element.value === 'none' ||
          element.value === false ||
          element.value === '' ||
          element.value === null ||
          element.value === 'False';

        if (!hasDefaultValues) {
          this.formState = FormState.ModifiedValues;
          break;
        }
      }
    }

    if (this.initialFormState === FormState.ModifiedValues && this.initialLoadCompleted) {
      if (hasDefaultValues) {
        this.formState = FormState.DefaultValues;
        this.ngForm.form.markAsDirty();
        if (this.formChangesService) {
          this.formChangesService.setFormChangeState(this.formState);
        }
        this.formDefaultValuesChanged.emit(true);
      } else {
        this.formState = FormState.ModifiedValues;
        if (this.formChangesService) {
          this.formChangesService.setFormChangeState(this.formState);
        }
        this.formDefaultValuesChanged.emit(false);
      }
    }

    return hasDefaultValues;
  }

  private sortAndStringify(source: any): string {
    return JSON.stringify(source, Object.keys(source).sort());
  }
}
