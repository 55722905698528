<div class="presets">

  <span class="content">
    You may select a preset to prefill the training options below.
  </span>
  <div class="d-flex align-items-start">
    <div class="d-flex align-items-center flex-column mt-1 mb-1">
      <daui-button style="width: 250px; " [type]="buttonType.Regular" [attractive]="buttonAttractive.Regular"
        (click)="viewSavedPresets()">
        VIEW SAVED PRESETS</daui-button>
      <app-whats-new-badge (clicked)="showHelpDialog()" class="mt-1"></app-whats-new-badge>
    </div>

    <div *ngIf="selectedPresetSummary" class="d-flex mt-1 ml-1 align-items-center">
      <b>Selected Preset: </b>
      <div class="ml-1">{{selectedPresetSummary.title}}</div>
    </div>
  </div>

  <daui-dialog class="dialogApplyPreset" *ngIf="!!selectedPresetSummary" [isShow]="isShowApplyPresetWarningDialog">
    <div class="dialogApplyPreset-wrapper">
      <div class="dialogApplyPreset-wrapper-title">
        The following Training Options will be used to prefill your TCA:
      </div>
      <div class="dialogApplyPreset-wrapper-body">
        <br />
        <div class="title">
          {{ selectedPresetSummary.title }}
        </div>
        <div class="description">
          <div class="options" *ngFor="let option of selectedPresetSummary.options">
            <div class="option-label" *ngIf="!!option">{{ option.type }}:</div>
            <div class="option-summary" *ngIf="!!option">
              {{ option.summary }}
            </div>
          </div>
        </div>
        <br />
        <div class="warning">
          <img class="warning-image" src="../../../../assets/images/icon-warning.svg" />
          <div>
            This action will override all the training options you previously selected.
          </div>
        </div>
      </div>
    </div>
    <daui-action-bar [hasSecondary]="true" secondaryButtonText='Cancel' [primaryButtonText]="'CONTINUE'"
      (primary)="onPresetApply()" (secondary)="onPresetApplyCancel()" [primaryDisabled]="false">
    </daui-action-bar>
  </daui-dialog>
  <daui-dialog class="dialogDeletePreset" [isShow]="showingDeleteDialog">
    <div class="dialogDeletePreset-wrapper">
      <h1>Delete Preset</h1>
      <div style="color:red; margin-top: 1em;">Are you sure you want delete this preset?
      </div>
      <div style="font-weight: bolder; color:red; margin-top: 1em;">This action cannot be
        undone</div>
      <daui-action-bar [hasSecondary]="true" [primaryButtonText]="'DELETE THIS PRESET'" secondaryButtonText="Cancel"
        (primary)="onDeleteClicked();" (secondary)="onCancelClicked();" [primaryDisabled]="false">
      </daui-action-bar>
    </div>
  </daui-dialog>
  <app-presets-dialog (onPresetSelected)="selectionChange($event)" (onDeletePresetSelected)="deleteSelection($event)"
    [presets]="presetsSummary$ | async" [isVisible]="showingPresets" [isDeleteVisible]="showingDeleteDialog"
    (onDeleteChange)="openDeleteDialog()" (onClose)="showingPresets = false; isPresetDialogOpen = false;"
    [clientId]="currentTrainingRequirement?.clientId" [currentTrainingRequirement]="currentTrainingRequirement"
    [authority]="authority" (onHelpSelected)="showHelpDialog()">
  </app-presets-dialog>
</div>

<app-presets-help-dialog [isVisible]="showingHelpDialog" (onClose)="closeHelpDialog()"></app-presets-help-dialog>