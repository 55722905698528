import { Injectable, SkipSelf, Self } from '@angular/core';
import { LogAppender } from '../models/log-appender';
import { LogLevel } from '../models/log-level.enum';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  appenders = new Array<LogAppender>();
  private _logLevel: LogLevel;

  constructor() {
    // default LogLevel if none is set through the app settings.
    this._logLevel = LogLevel.Info;
  }

  public set logLevel(newLogLevel: string) {
    this._logLevel = this.getLogLevel(newLogLevel);
  }

  addAppender(appender: LogAppender, appenderLogLevel?: string) {
    let customLogLevel: LogLevel;
    if (!!appenderLogLevel) {
      customLogLevel = this.getLogLevel(appenderLogLevel);
    } else {
      customLogLevel = this._logLevel;
    }
    this.appenders.push(appender);
    appender.setLogLevel(customLogLevel);
  }

  trace(message: any, ...additional: any[]) {
    this.logWithAppenders(LogLevel.Trace, message, additional);
  }

  debug(message: any, ...additional: any[]) {
    this.logWithAppenders(LogLevel.Debug, message, additional);
  }

  info(message: any, ...additional: any[]) {
    this.logWithAppenders(LogLevel.Info, message, additional);
  }

  log(message: any, ...additional: any[]) {
    this.logWithAppenders(LogLevel.Log, message, additional);
  }

  warn(message: any, ...additional: any[]) {
    this.logWithAppenders(LogLevel.Warn, message, additional);
  }

  error(message: any, ...additional: any[]) {
    this.logWithAppenders(LogLevel.Error, message, additional);
  }

  fatal(message: any, ...additional: any[]) {
    this.logWithAppenders(LogLevel.Fatal, message, additional);
  }

  private logWithAppenders(logLevel: LogLevel, message: any, ...additional: any[]) {
    this.appenders.forEach(appender => {
      appender.log(logLevel, message, additional);
    });
  }

  private getLogLevel(loglevel: string): LogLevel {
    switch (loglevel.toLocaleLowerCase()) {
      case 'trace':
        return LogLevel.Trace;
      case 'debug':
        return LogLevel.Debug;
      case 'info':
        return LogLevel.Info;
      case 'log':
        return LogLevel.Log;
      case 'warn':
        return LogLevel.Warn;
      case 'error':
        return LogLevel.Error;
      case 'fatal':
        return LogLevel.Fatal;
      default:
        return LogLevel.Info;
    }
  }
}
