import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ButtonIcon, ButtonType, ButtonAttractive } from 'common-web-ui';

@Component({
  selector: 'app-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.less']
})
export class CollapseComponent implements OnInit, OnChanges {


  @Input() title;

  @Input() collapsed;

  buttonIcon: ButtonIcon = ButtonIcon.Expand;
  readonly buttonType = ButtonType;
  readonly buttonAttractive = ButtonAttractive;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.collapsed && changes.collapsed.previousValue !== changes.collapsed.currentValue) {
      if (this.collapsed) {
        this.buttonIcon = ButtonIcon.Collapse;
      } else {
        this.buttonIcon = ButtonIcon.Expand;
      }
    }

  }

  ngOnInit() {

  }

  toggleCollapse(collapse?: boolean | undefined | null) {

    if (typeof collapse === 'undefined') {
      this.collapsed = !this.collapsed;
    } else {
      this.collapsed = collapse;
    }


    if (this.collapsed) {
      this.buttonIcon = ButtonIcon.Collapse;
    } else {
      this.buttonIcon = ButtonIcon.Expand;
    }
  }
}
