import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { ApprovalState } from '../tca/approval/store';
import { AuthGuard, PendingChangesGuard } from './guards';
import {
  AuthInterceptor,
  CustomHttpInterceptor,
  LoggingInterceptor,
  WaitingInterceptor
} from './interceptors';
import {
  AppInsightService,
  AuthService,
  FormChangesService,
  LocalStorageService,
  WaitingPanelService
} from './services';
import { CountriesService } from '../services/data-layer/countries.service';
import { LoggerService } from '../logger/services/logger.service';
import { HeaderService } from './services/header.service';
import { HeapService } from './services/heap.service';
import { AppSettings } from '../models/AppSettings';
import { AppSettingsService } from 'common-web-core';
import { AppInsightsService } from '@markpieszak/ng-application-insights';

const PRODIVERS_COMMON = [
  { provide: HTTP_INTERCEPTORS, useClass: WaitingInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
  DatePipe,
  AuthGuard,
  PendingChangesGuard,
  LocalStorageService,
  FormChangesService,
  AuthService,
  AppInsightService,
  WaitingPanelService,
  CountriesService,
  HeaderService,
  HeapService  
];

export function exceptionIfAlreadyLoaded(parentModule: any, moduleName: string) {
  if (parentModule) {
    const msg = `${moduleName} has already been loaded. Import in the AppModule only.`;
    throw new Error(msg);
  }
}

@NgModule({
  imports: [HttpClientModule],
  declarations: [],
  providers: PRODIVERS_COMMON
  // exports: []
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule,
              private appSettingsService: AppSettingsService<AppSettings>,
              private appInsightsService: AppInsightsService,
              private heapService: HeapService,) {
    exceptionIfAlreadyLoaded(parentModule, 'CoreModule');
    this.initCoreDependencies();
  }

  private initCoreDependencies(): void {
    this.appSettingsService.appSettings$.subscribe((appSettings: AppSettings) => {
      this.initAppSights(appSettings);      
      this.initHeap(appSettings);
    });
  }

  private initAppSights(appSettings: AppSettings): void {
    if (!!appSettings.appInsightsSettings && !!appSettings.appInsightsSettings.instrumentationKey) {
      this.appInsightsService.config = {
        instrumentationKey: appSettings.appInsightsSettings.instrumentationKey,
      };
      this.appInsightsService.init();
    }
  }

  private initHeap(appSettings: AppSettings): void {
    if (!!appSettings && !!appSettings.heapSettings) {      
      this.heapService.initializeHeap(appSettings.heapSettings.apiKey);
    }
  }
  
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: PRODIVERS_COMMON
    };
  }
}
