import * as _ from 'lodash';

import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { TrainingRequirement, TrainingType } from 'src/app/models/TrainingRequirement';
import {
  UpdateLicenceErrors,
  UpdateTrainingAddOn
} from 'src/app/state/trainingRequirement.actions';
import { ValidationErrorTypes, ValidationErrors } from 'src/app/services/tca-validation.service';

import { ActivatedRoute } from '@angular/router';
import { AddOn } from 'src/app/models/Addon';
import { AppState } from 'src/app/state/app.state';
import { Authority } from 'src/app/models/Authority';
import { AuthorityDescription } from 'src/app/models/AuthorityDescription';
import { Country } from 'src/app/models/Country';
import { DateValidationErrorTypes } from 'src/app/shared/components';
import { EditMode } from 'src/app/models/EditMode';
import { FormFieldState } from 'common-web-ui';
import { Licence } from 'src/app/models/Licence';
import { RegulationAgencyName } from 'src/app/models/RegulationAgencyName';
import { ToggleFeatures } from 'src/app/data/toggle-features';
import { TrainingRequirementState } from 'src/app/state/trainingRequirement.state';
import { countryList } from 'src/app/data/countries';
import { LicenseCategory } from 'src/app/data/licenseCategory';

enum FieldValidation {
  CertificateNumber,
  ExpiryDate,
  IssuingCountry
}

@Directive()
export abstract class BaseLicenceComponent implements OnInit, OnDestroy {
  public editMode = EditMode;
  mode: EditMode;
  readonly trainingType = TrainingType;
  licence = new Licence();
  certificateNumber: string;
  subscriptions: Array<Subscription> = [];
  selectedLicenceType: string;
  public name = 'License';

  get validationErrorTypes() {
    return ValidationErrorTypes;
  }
  get formFieldState() {
    return FormFieldState;
  }

  // Validation Properties
  licenceValidationErrors: ValidationErrors;
  certificateNumberState: FormFieldState;
  issuingCountryState: FormFieldState;
  expiryDateState: FormFieldState;
  licenseTypeState: FormFieldState;
  pbnPrivilegesState: FormFieldState = FormFieldState.Warning;
  dateValidationErrors: DateValidationErrorTypes;
  dateLastTCValidationErrors: DateValidationErrorTypes;

  fieldValidation = FieldValidation;

  // countries
  countryList: Array<Country> = [];
  countrySelectedValue: string;

  // PBN Feature
  @Select(AppState.isFeatureActive(ToggleFeatures.CustomerWebApp13398PbnPrivileges))
  isPbnPrivilegesFeatureEnabled$: Observable<boolean>;

  isPbnPrivilegesFeatureEnabled: boolean;
  isAddPbnInitialTrainingSelected: boolean;
  isPbnDialogVisible = false;
  isPerformanceBasedNavigation: boolean;
  anotherLicenceHasPbnInitialTraining = false;
  hasPBNLicence = false;
  prNavAddOn: AddOn = new AddOn();
  otherLicencesHasInitialTraining = false;
  hasEASAOrOtherLicences = false;

  @Select(TrainingRequirementState.licences)
  licences$: Observable<Array<Licence>>;

  @Select(TrainingRequirementState.validationErrors)
  validationErrors$: Observable<ValidationErrors>;

  @Select(TrainingRequirementState.currentTrainingRequirement)
  public currentTrainingRequirement$: Observable<TrainingRequirement>;
  public currentTrainingRequirement: TrainingRequirement;

  constructor(
    protected route: ActivatedRoute,
    protected store: Store,
    protected authorityDescription: AuthorityDescription
  ) { }

  ngOnInit(): void {

    this.onInit();

    this.subscribe(
      this.validationErrors$.subscribe(validationErrors => {
        if (!!validationErrors) {
          this.licenceValidationErrors = validationErrors;
        }
      })
    );

    this.initializeLicence();

    this.subscribe(
      this.isPbnPrivilegesFeatureEnabled$.subscribe(featureFlagValue => {
        this.isPbnPrivilegesFeatureEnabled = featureFlagValue;
        if (this.isPbnPrivilegesFeatureEnabled && !this.licence.isPerformanceBasedNavigation) {
          this.pbnPrivilegesState = FormFieldState.Warning;
        } else {
          this.pbnPrivilegesState = FormFieldState.Default;
        }
      })
    );

    this.store
      .selectOnce(TrainingRequirementState.trainingAddOnByName('PRNAV'))
      .subscribe(prNavAddOn => {
        this.prNavAddOn = prNavAddOn;
      });
  }


  protected initializeLicence() {
    if (this.route.snapshot.params.certificateNumber == null) {
      this.mode = EditMode.New;
      this.licence.isPerformanceBasedNavigation = true;
      this.licence.hasInitialTraining = false;
      this.setPageName(this.authorityDescription);

      this.subscriptions.push(
        this.licences$.subscribe(licences => {
          if (!!licences && licences.length > 0) {
            this.setLicenceFlag(licences);
            this.anotherLicenceHasPbnInitialTraining =
              licences.find(licence => {
                return licence.hasInitialTraining;
              }) != null;
          }
        })
      );
    } else {
      this.certificateNumber = this.route.snapshot.params.certificateNumber;
      this.subscriptions.push(
        this.licences$.subscribe(licences => {
          if (!!licences && licences.length > 0) {
            for (let index = 0; index < licences.length; index++) {
              if (licences[index].certificateNumber === this.certificateNumber) {
                this.mode = EditMode.Edit;
                this.licence = _.cloneDeep(licences[index]);
                this.licence.country = this.formatCountry(this.licence.country);
                this.countrySelectedValue = this.licence.country;
                this.selectedLicenceType = this.licence.licenceType.name;
                this.isPerformanceBasedNavigation = this.licence.isPerformanceBasedNavigation;
                this.name = 'License ' + (index + 1).toString();

                this.updateLicenceError(this.licence);
                this.updateErrorState();
                this.setPageName(this.authorityDescription, index);
              }
            }
            this.setLicenceFlag(licences);
          }
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.onDestroy();
    if (this.subscriptions) {
      this.subscriptions.forEach(sub => sub.unsubscribe());
    }
  }

  protected setPageName(authorityDescription: AuthorityDescription, index: number = 1) {
    if (authorityDescription.authority === Authority.None) {
      this.name = 'License ' + (index + 1).toString();
    } else {
      this.name = `${authorityDescription.description} License`;
    }
  }

  updateLicenceError(licence: Licence) {
    this.launchLicenceValidation(licence);
  }

  private launchLicenceValidation(licence: Licence): void {

    // Disable licence expired validations if we are in a generic form
    this.store.dispatch(new UpdateLicenceErrors(licence, !(this.currentTrainingRequirement && this.currentTrainingRequirement.regulationAgency == RegulationAgencyName.Generic)));
    this.updateErrorState();
  }

  updateErrorState() {
    if (
      !!this.licenceValidationErrors && this.licence.licenceCategoryType == LicenseCategory.Existing &&
      this.licenceValidationErrors.licenceErrors.includes(
        ValidationErrorTypes.LICENCE_NUMBER_REQUIRED
      )
    ) {
      this.certificateNumberState = FormFieldState.Error;
    } else {
      this.certificateNumberState = null;
    }

    if (
      !!this.licenceValidationErrors && this.licence.licenceCategoryType == LicenseCategory.Existing &&
      this.licenceValidationErrors.licenceErrors.includes(
        ValidationErrorTypes.ISSUING_COUNTRY_REQUIRED
      )
    ) {
      this.issuingCountryState = FormFieldState.Error;
    } else {
      this.issuingCountryState = null;
    }

    if (!!this.licenceValidationErrors) {
      if (
        this.licenceValidationErrors.licenceErrors.includes(
          ValidationErrorTypes.EXPIRY_DATE_REQUIRED
        )
      ) {
        this.expiryDateState = FormFieldState.Error;
      } else if (
        this.licenceValidationErrors.licenceErrors.includes(ValidationErrorTypes.LICENCE_EXPIRED)
      ) {
        this.expiryDateState = FormFieldState.Warning;
      } else {
        this.expiryDateState = null;
      }
    } else {
      this.expiryDateState = null;
    }

    if (
      !!this.licenceValidationErrors &&
      this.licenceValidationErrors.licenceErrors.includes(
        ValidationErrorTypes.LICENSE_TYPE_OTHER_DESCRIPTION_REQUIRED
      )
    ) {
      this.licenseTypeState = FormFieldState.Error;
    } else {
      this.licenseTypeState = null;
    }

    if (
      this.dateValidationErrors === DateValidationErrorTypes.DATE_INVALID ||
      this.dateValidationErrors === DateValidationErrorTypes.DATE_REQUIRED
    ) {
      this.licence.expiryDate = null;
    }

    if (
      this.dateLastTCValidationErrors === DateValidationErrorTypes.DATE_INVALID ||
      this.dateLastTCValidationErrors === DateValidationErrorTypes.DATE_REQUIRED
    ) {
      this.licence.lastTCDate = null;
    }
  }

  onPbnSelectionChange() {
    this.licence.isPerformanceBasedNavigation = !this.licence.isPerformanceBasedNavigation;
    if (this.licence.isPerformanceBasedNavigation) {
      this.licence.hasInitialTraining = false;
      this.pbnPrivilegesState = FormFieldState.Default;
    } else {
      if (
        this.prNavAddOn.options.filter(option => option.name === 'CAEATOPBN' && option.value)
          .length === 0 ||
        !this.hasEASAOrOtherLicences
      ) {
        this.isPbnDialogVisible = true;
        if (this.isPbnPrivilegesFeatureEnabled) {
          this.pbnPrivilegesState = FormFieldState.Warning;
        }
      }
    }
  }

  updatePbnOption() {
    const caeAtoPbnOption = this.prNavAddOn.options.find(option => option.name === 'CAEATOPBN');
    if (!!caeAtoPbnOption && !this.otherLicencesHasInitialTraining) {
      if (this.licence.hasInitialTraining) {
        caeAtoPbnOption.value = true;
        this.prNavAddOn.active = true;
        this.store.dispatch(new UpdateTrainingAddOn(this.prNavAddOn));
      }
    }
  }

  onDateValidationErrors(dateValidationErrors: DateValidationErrorTypes): void {
    this.dateValidationErrors = dateValidationErrors;
  }

  onLastTCDateValidationErrors(dateValidationErrors: DateValidationErrorTypes): void {
    this.dateLastTCValidationErrors = dateValidationErrors;
  }

  formatCountry(id) {
    const selectedCountry = countryList.find(country => country.id === id);
    return !!selectedCountry ? selectedCountry.name : id;
  }

  setSelectedDate(date: any) {
    this.licence.expiryDate = date;
    this.updateLicenceError(this.licence);
  }

  isExpiryDateValid() {
    const expiryDate = Date.parse(this.licence.expiryDate);
    const dateNow = Date.now();

    return expiryDate > dateNow;
  }

  setLicenceFlag(licences: Array<Licence>) {
    if (
      licences.filter(licence => licence.id !== this.licence.id && licence.hasInitialTraining)
        .length > 0
    ) {
      this.otherLicencesHasInitialTraining = true;
    } else {
      this.otherLicencesHasInitialTraining = false;
    }
    if (
      licences.filter(
        l =>
          l.id !== this.licence.id &&
          (l.authority === null ||
            l.authority.toLowerCase() === Authority.EASA ||
            l.authority.toLowerCase() === Authority.Other ||
            l.authority.toLowerCase() === '')
      ).length > 0
    ) {
      this.hasEASAOrOtherLicences = true;
    }
  }

  subscribe(subscription: Subscription) {
    this.subscriptions.push(subscription);
  }

  abstract onInit(): void;

  abstract onDestroy(): void;
}
