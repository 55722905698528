import { Injectable, Injector } from '@angular/core';

import { AppInsightsBaseProperties } from 'src/app/models/AppInsightsBaseProperties';
import { AppInsightsService } from '@markpieszak/ng-application-insights';
import { LoggerService } from '../../logger/services/logger.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AppInsightService {
  private appInsightsProperties: AppInsightsBaseProperties;

  private _sessionId = '';
  get sessionId(): string {
    return this._sessionId;
  }

  set sessionId(value: string) {
    this._sessionId = value;
  }

  constructor(private injector: Injector,
    private loggerService: LoggerService,
    private appInsightsService: AppInsightsService) {
    this.setAppInsightProperty = this.setAppInsightProperty.bind(this);
  }

  logEvent<T extends AppInsightsBaseProperties>(
    eventName: string,
    customizedAppInsightsProperties?: T,
    pageName?: string
  ) {
    if (!!customizedAppInsightsProperties) {
      this.appInsightsProperties = customizedAppInsightsProperties;
    } else {
      this.appInsightsProperties = new AppInsightsBaseProperties();
    }

    this.setAppInsightProperty(this.appInsightsProperties, pageName);
    this.appInsightsService.trackEvent(eventName, JSON.parse(JSON.stringify(this.appInsightsProperties)));
  }

  logException<T extends AppInsightsBaseProperties>(
    error: Error,
    customizedAppInsightsProperties?: T,
    pageName?: string
  ) {
    if (!!customizedAppInsightsProperties) {
      this.appInsightsProperties = customizedAppInsightsProperties;
    } else {
      this.appInsightsProperties = new AppInsightsBaseProperties();
    }

    this.setAppInsightProperty(this.appInsightsProperties, pageName);
    this.appInsightsService.trackException(error, JSON.parse(JSON.stringify(this.appInsightsProperties)));
  }

  private getPageNameFromUrl(url: string): string {
    let pageName = '';
    if (url) {
      const firstIndexOf = url.indexOf('/');
      const secondIndexOf = url.indexOf('/', firstIndexOf + 1);
      if (secondIndexOf !== -1) {
        pageName = url.substring(firstIndexOf + 1, secondIndexOf);
      } else {
        pageName = url.substring(firstIndexOf + 1, url.length);
      }

      if (pageName === '') {
        pageName = 'login';
      }
    }

    return pageName;
  }

  private setAppInsightProperty(
    appInsightsProperties: AppInsightsBaseProperties,
    pageName?: string
  ) {
    if (!!appInsightsProperties) {
      if (!!pageName && pageName !== '') {
        appInsightsProperties.page_name = pageName;
      } else {
        appInsightsProperties.page_name = this.getPageNameFromUrl(this.injector.get(Router).url);
      }
    }
    return appInsightsProperties;
  }
}
