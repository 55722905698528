import { AuthService, AuthenticationStatus, Oauth } from './core/services/auth.service';
import { Component, HostListener, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { NavigationModel, ThemeService } from 'common-web-ui';
import { Subscription, timer } from 'rxjs';

import { DeviceDetectorService } from 'ngx-device-detector';
import { LocationStrategy } from '@angular/common';
import { LogAnalyticEvent } from './state/trainingRequirement.actions';
import { LoginState } from './state/login.state';
import { Store } from '@ngxs/store';
import { StoreUserInfo } from './state/login.actions';
import { UserInfoService } from './services/data-layer/userinfo.service';
import { WaitingPanelService } from './core/services/waiting-panel.service';
import { filter } from 'rxjs/operators';
import { slideInAnimation } from './animations';
import { AppState } from './state/app.state';
import { ToggleFeatures } from './data/toggle-features';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  animations: [slideInAnimation]
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'CustomerPortalWeb';
  salesForceOauth: Oauth;
  isWaitingVisible = false;
  subscriptions: Array<Subscription> = [];
  isPopState = false;
  isNavigationVisible = true;
  isAuthenticated = false;

  plannedMaintenanceBannerText: string = "";
  navigationItems: Array<NavigationModel> = [];

  selectContextItems = [];
  customers = [];

  @HostListener('window:unload', ['$event'])
  unloadHandler() {
    this.store.dispatch(new LogAnalyticEvent('regular', 'TCA Form Close'));
  }

  @HostListener('window:visibilitychange', ['$event'])
  visiabilityHandler() {
    let status = '';
    switch (document.visibilityState) {
      case 'visible':
        status = 'Page Show';
        break;
      case 'hidden':
        status = 'Page Hide';
        break;
      default:
        break;
    }
    this.store.dispatch(new LogAnalyticEvent('visibility', 'TCA Form Visibility', null, status));
  }

  constructor(
    private router: Router,
    private store: Store,
    private waitingPanelService: WaitingPanelService,
    private locationStrategy: LocationStrategy,
    private renderer: Renderer2,
    private deviceService: DeviceDetectorService,
    private zone: NgZone,
    private authService: AuthService,
    private themeService: ThemeService,
    private userService: UserInfoService,
    private cdr: ChangeDetectorRef
  ) { }

  prepareRoute(outlet: RouterOutlet) {
    return (
      this.deviceService.isMobile() &&
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animation']
    );
  }

  ngOnInit() {
    this.themeService.InitThemeService();

    this.waitingPanelService.getIsVisible().subscribe(isVisible => {
      this.isWaitingVisible = isVisible;
      this.cdr.detectChanges();
      this.UpdateClassBody();
    });

    this.UpdateClassBody();

    this.locationStrategy.onPopState(() => {
      this.isPopState = true;
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && !this.isPopState) {
        window.scrollTo(0, 0);
        this.isPopState = false;
      }

      if (event instanceof NavigationEnd) {
        this.isPopState = false;
      }
    });

    this.zone.runOutsideAngular(() => {
      const verifyTokenInterval = this.authService.accessTokenVerificationInterval;
      const verifyTokenValidity$ = timer(verifyTokenInterval, verifyTokenInterval);
      this.subscriptions.push(
        verifyTokenValidity$.subscribe(() => this.authService.validateTokenValid())
      );
    });

    if (this.authService.authHelper) {
      this.isAuthenticated = this.authService.authHelper.isAuthenticated;

      // User is authenticated but the current user is not set
      if (this.authService.authHelper.isAuthenticated && !this.store.selectSnapshot(LoginState.userId)) {
        this.userService.getUserInfo(res => {
          this.store.dispatch(new StoreUserInfo(res));
          this.cdr.detectChanges();
        });
      }
    }

    this.subscriptions.push(
      this.authService.authenticationChanged$
        .pipe(filter(x => x === AuthenticationStatus.authenticated))
        .subscribe((authStatus: AuthenticationStatus) => {

          this.userService.getUserInfo(res => {
            this.store.dispatch(new StoreUserInfo(res));

            this.isAuthenticated = authStatus == AuthenticationStatus.authenticated;
            this.cdr.detectChanges();
            // check if we are redirecting from b2c login
            if (this.authService.authHelper.isAzureB2C && this.authService.authB2cService.CustomRedirectState) {
              this.router.navigate([`/${this.authService.authB2cService.CustomRedirectState}`])
            }
          });
        })
    );
    this.subscriptions.push(
         this.store
        .select(AppState.isFeatureActive(ToggleFeatures.CustomerWebAppPlannedMaintenanceBannerText))
        .subscribe(bannerText => {
          this.plannedMaintenanceBannerText = (bannerText == null || bannerText === false || bannerText.trim() === '') ? '' : bannerText;
          this.cdr.detectChanges();
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  UpdateClassBody() {
    this.isWaitingVisible ? this.renderer.addClass(document.body, 'isOverlay') : this.renderer.removeClass(document.body, 'isOverlay');
  }

  navigationSelectionChange(item: any) {
    this.router.navigate(['/' + item]);
  }
}
