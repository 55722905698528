import { Injectable, NgZone } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse
} from '@angular/common/http';
import { WaitingPanelService } from '../services/waiting-panel.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { RequestInfo } from './RequestInfo';

@Injectable({
  providedIn: 'root'
})
export class WaitingInterceptor implements HttpInterceptor {
  accessToken: string;
  showDateTime: Date;
  showTimerHandle: any;
  hideTimerHandle: any;

  activeRequestsWithTime = new Array<RequestInfo>();

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private waitingPanelService: WaitingPanelService
  ) { }

  private addRequest(req: HttpRequest<any>) {
    const requestInfo = new RequestInfo(req);
    this.activeRequestsWithTime.push(requestInfo);

    requestInfo.waitingAskedTime = new Date();
    this.waitingPanelService.show();
  }

  private removeRequest(req: HttpRequest<any>, isInstantRemove: boolean = false) {
    const requestInfo = this.activeRequestsWithTime.find(
      (requestInfo: RequestInfo) => requestInfo.request === req
    );
    if (!requestInfo) {
      return;
    }

    const hideDateTime = new Date();
    if (!requestInfo.waitingAskedTime) {
      clearTimeout(requestInfo.timeoutHandle);
    } else {
      if (hideDateTime.getTime() - requestInfo.waitingAskedTime.getTime() < 500) {
        setTimeout(() => {
          this.ngZone.run(() => {
            this.waitingPanelService.hide();
          });
        }, 500);
      } else {
        this.waitingPanelService.hide();
      }
    }

    const index = this.activeRequestsWithTime.indexOf(requestInfo);
    if (index > -1) {
      this.activeRequestsWithTime.splice(index, 1);
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.addRequest(req);

    return Observable.create(observer => {
      const subscription = next.handle(req).subscribe(
        event => {
          if (event instanceof HttpResponse) {
            this.removeRequest(req);
            observer.next(event);
          }
        },
        err => {
          this.removeRequest(req, true);
          observer.error(err);
        },
        () => {
          this.removeRequest(req);
          observer.complete();
        }
      );

      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
}
