<app-form-card>
  <div class="container" [ngClass]="{ hidden: !currentTrainingRequirement }">
    <h2>Notes</h2>
    <div class="subTitle" *ngIf="!readonly">
      You can add details for this training event.
    </div>
    <div class="wrapper">
      <div class="note">
        <app-form-card-delete
          *ngIf="currentTrainingRequirement.note"
          (delete)="onDelete()"
        ></app-form-card-delete>
        <div *ngIf="hasNote()" class="pre-line" (click)="goToNotePage()">
          {{ currentTrainingRequirement.note }}
        </div>
      </div>
      <div class="wrapper actions" *ngIf="!readonly">
        <daui-button
          id="addNote"
          [icon]="buttonIcon.Add"
          [type]="buttonType.Flat"
          [attractive]="buttonAttractive.Regular"
          (click)="goToNotePage()"
          *ngIf="!hasNote()"
        >
          Add Note
        </daui-button>
        <daui-button
          id="editNote"
          [icon]="buttonIcon.Edit"
          [type]="buttonType.Flat"
          [attractive]="buttonAttractive.Regular"
          (click)="goToNotePage()"
          *ngIf="hasNote()"
        >
          Edit Note
        </daui-button>
      </div>
    </div>
  </div>
</app-form-card>
