<daui-dialog [isShow]="isVisible" [size]="size" [behavior]="behavior">
  <div class="presets-help-dialog">

    <div class="close-section">
      <div class="icon close" (click)="closeDialog()"></div>
    </div>

    <h1>eTCA Presets</h1>

    <p>The Presets feature was designed to help customers store their TCA option selection into presets that can be
      easily reused.</p>
    <p>Presets are saved into two categories:</p>
    <ul class="my-2">
      <li>
        <div class="d-flex">
          <div class="font-weight-bold mr-1">Pilot-specific:</div>
          <div>These are presets that are available for a specific pilot.</div>
        </div>
      </li>
      <li>
        <div class="d-flex">
          <div class="font-weight-bold mr-1">Global:</div>
          <div>These presets that are available to all in the company.</div>
        </div>
      </li>
    </ul>
    <p>Filters are available to display the proper category (pilot-specific only or global only). You can also filter by
      All to see all the presets for the company.</p>
    <p>A search box is also included to help you locate a preset. It will search for the entered text in the Preset Name
      field.</p>
    <p>Clicking on the preset name will load its data into your TCA form. The data loaded include the Additional
      Training options, Additional Checking options, Special Airports, and Notes. In addition, for pilot-specific
      presets, the Licenses/Certificates section will also be loaded.</p>
    <p>Within the preset list, you also have the following functions:</p>
    <ul class="my-2">
      <li>
        <div class="d-flex my-1">
          <div class="font-weight-bold mr-1">Delete (<span class="icon">
              <daui-button [attractive]="attractive.Heavy" [icon]="buttonIcon.Delete" [type]="buttonType.Flat">
              </daui-button>
            </span>):</div>
          <div>You can use this to permanently delete a preset from the list.</div>
        </div>
      </li>
      <li>
        <div class="d-flex my-1">
          <div class="font-weight-bold mr-1">Details (<span class="icon">
              <daui-button [attractive]="attractive.Regular" [icon]="buttonIcon.Next" [type]="buttonType.Flat">
              </daui-button>
            </span>):</div>
          <div>You can use this to display more information about a preset.</div>
        </div>
      </li>
    </ul>

  </div>
</daui-dialog>