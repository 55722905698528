import { AppInsightsBaseProperties } from './AppInsightsBaseProperties';
import {
  AnalyticsProperties,
  ErrorMessageAnalyticsProperties,
  SubpageAnalyticsProperties,
  FormVisibilityAnalyticsProperties
} from './AnalyticsProperties';

export class AppInsightsProperties extends AppInsightsBaseProperties {
  analytics_user_id: string;
  analytics_customer_id: string;
  analytics_client_id: string;
  analytics_editor_user_id: string;
  analytics_res_num: string;
  analytics_platform: string;
  analytics_status: string;
  analytics_subpage_name: string;
  analytics_page_name: string;
  analytics_error_name: string;
  analytics_error_message: string;
  analytics_revision_number: number;

  constructor() {
    super();

    this.app_name = 'CustomerWebApp';
    this.page_name = '';
    this.message = '';
    this.CustomerWebApp = '';

    this.analytics_user_id = '';
    this.analytics_customer_id = '';
    this.analytics_client_id = '';
    this.analytics_editor_user_id = '';
    this.analytics_res_num = '';
    this.analytics_platform = '';
    this.analytics_status = '';
    this.analytics_subpage_name = '';
    this.analytics_page_name = '';
    this.analytics_error_name = '';
    this.analytics_error_message = '';
    this.analytics_revision_number = 0;
  }

  public static mapWith(
    appInsightsProperties: AppInsightsProperties,
    analyticMessage: AnalyticsProperties
  ): AppInsightsProperties {
    appInsightsProperties.analytics_client_id = analyticMessage.client_id;
    appInsightsProperties.analytics_customer_id = analyticMessage.customer_id;
    appInsightsProperties.analytics_editor_user_id = analyticMessage.editor_user_id;
    appInsightsProperties.analytics_platform = analyticMessage.platform;
    appInsightsProperties.analytics_res_num = analyticMessage.res_num;
    appInsightsProperties.analytics_user_id = analyticMessage.user_id;
    appInsightsProperties.analytics_error_message = (analyticMessage as ErrorMessageAnalyticsProperties).error_message;
    appInsightsProperties.analytics_error_name = (analyticMessage as ErrorMessageAnalyticsProperties).error_name;
    appInsightsProperties.analytics_page_name = (analyticMessage as ErrorMessageAnalyticsProperties).page_name;
    appInsightsProperties.analytics_subpage_name = (analyticMessage as SubpageAnalyticsProperties).subpage_name;
    appInsightsProperties.analytics_status = (analyticMessage as FormVisibilityAnalyticsProperties).status;
    appInsightsProperties.analytics_revision_number = analyticMessage.revision_number;

    return appInsightsProperties;
  }
}
