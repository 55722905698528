import * as _ from 'lodash';

import { ButtonIcon, ButtonType, DatePanelSize } from 'common-web-ui';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';

import { BaseUIComponent } from '../base-ui-component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { isValidDate } from '../../helpers/date-helper';
import moment from 'moment';

export enum DateValidationErrorTypes {
  NONE = '',
  DATE_REQUIRED = 'DateRequired',
  DATE_INVALID = 'DateInvalid'
}

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.less'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class DatePickerComponent extends BaseUIComponent {
  @Input() date: Date | string;
  @Input() dateControlId = 'date';
  @Input() dateControlName = 'date';
  @Input() dateControlClass = 'form-control';
  @Output() dateModelChange: EventEmitter<Date | string> = new EventEmitter();
  @Output() dateChange: EventEmitter<any> = new EventEmitter();
  @Output() validationErrors: EventEmitter<DateValidationErrorTypes> = new EventEmitter();
  @ViewChild('dateControl', { 'static': true }) dateControl: NgModel;

  buttonIcon = ButtonIcon;
  buttonType = ButtonType;
  isDatePickerVisible = true;
  dateSelected?: Date = null;

  get dauiDate() {

    if (!this.date) {
      return 'YYYY-MM-DD';
    }

    return moment(this.date).format('YYYY-MM-DD');
  }

  errors: DateValidationErrorTypes = DateValidationErrorTypes.NONE;

  readonly datePanelSize = DatePanelSize;

  constructor(private deviceDetectorSvc: DeviceDetectorService) {
    super();
  }

  onInit(): void {
    this.isDatePickerVisible = this.deviceDetectorSvc.isMobile();
    this.dateSelected = this.toDate(this.date, this.dateSelected);
  }

  get containerClass() {

    return 'date-picker-container ' + this.dateControlClass;
  }

  onDestroy(): void { }

  onDateModelChange(date: any): void {

    this.dateSelected = this.toDate(date, this.dateSelected);
    if (this.dateControl) {
      this.dateControl.control.markAsTouched();
      this.dateControl.control.markAsDirty();
    }

    this.validate(this.toDate(date));
    this.dateModelChange.emit(date);
  }

  onDateChange(event: any): void {
    this.dateChange.emit(event);
  }

  private toDate(date: any, alternateValue?: Date): Date | null {
    let result = alternateValue;
    if (_.isString(date)) {
      const parts = date.split('-');
      result = new Date(
        Number.parseInt(parts[0], 10),
        Number.parseInt(parts[1], 10) - 1,
        Number.parseInt(parts[2], 10)
      );
    } else if (_.isDate(date)) {
      result = date;
    }
    return result;
  }

  validate(date: Date | string | any | null): boolean {
    let result = true;

    if (!isValidDate(date)) {
      this.validationErrors.emit(DateValidationErrorTypes.DATE_REQUIRED);
      result = false;
    } else {
      this.validationErrors.emit(DateValidationErrorTypes.NONE);
    }

    return result;
  }
}
