<app-form-content [layout]="formContentComponentLayout.SubPage">
  <ng-form #f="ngForm" appFormChangesWatcher (formDirtyChanged)="formDirtyChanged($event)">
    <div class="form-wrapper">
      <div class="content">
        <app-form-card>
          <h5 class="page-header" *ngIf="mode === editMode.New">Add Certificate</h5>
          <h5 class="page-header" *ngIf="mode === editMode.Edit">Edit Certificate</h5>
          <h2>{{ faaLicenceName }}</h2>
          
          <div content>
           <daui-radio-button [checked]="licenceCategoryType == 'Existing'" 
                (change)="toggleLicenseCategory($event, 'Existing')"
                label="Existing Licence" name="LinceseCategoryType" id="LinceseCategoryType" checked="true">
                </daui-radio-button> 
            <daui-form-field [ngClass]="'custom-wrapper'" label="Certificate Number" [state]="certificateNumberState">
            <div messages>
              <div id="licenceNumberRequired" *ngIf="
                  licenceValidationErrors?.licenceErrors.includes(
                    validationErrorTypes.LICENCE_NUMBER_REQUIRED
                  )
                ">
                This field is required
              </div>
            </div>
            <div content>
              <input class="form-control" id="certificate-number" type="text" name="certificate-number"
                placeholder="Enter license number" [(ngModel)]="licence.certificateNumber" />
            </div>
          </daui-form-field>    
          </div>

          <daui-form-field label="Grade of Certificate" [state]="licenseTypeState">
            <div messages>
              <div id="licenseTypeOtherDescriptionRequired" *ngIf="
                  licenceValidationErrors?.licenceErrors.includes(
                    validationErrorTypes.LICENSE_TYPE_OTHER_DESCRIPTION_REQUIRED
                  )
                ">
                This field is required
              </div>
            </div>
            <div content>
              <daui-radio-button
                  *ngFor="let licenceType of licenceTypes"
                  [checked]="onLicenceTypeChange(licenceType)"
                  [name]="'licenceTypes'"
                  [label]="licenceType.value"
                  [id]="licenceType.id"
                  [parentForm]="f.form"
                  (change)="saveLicenceType(licenceType)"
                ></daui-radio-button>
                <div *ngIf="selectedLicenceType === otherLicenseType">
                  <input
                    class="form-control"
                    id="license-type-other-description"
                    type="text"
                    name="license-type-other-description"
                    placeholder="Please specify"
                    [(ngModel)]="licence.licenceType.value"
                  />
                </div>
            </div>
          </daui-form-field>

          <daui-form-field>
              <div content>
                  <daui-radio-button [checked]="licenceCategoryType == 'Future'" (change)="toggleLicenseCategory($event, 'Future')"
                  name="LinceseCategoryType" id="LinceseCategoryType" label="Future license" 
                  id="futureLicense" [parentForm]="f.form">
              </daui-radio-button>
              </div>   
          </daui-form-field>

          <app-form-card-actions [isSaveEnabled]="f.dirty || isFormDirty" [mode]="mode" (delete)="onDelete()" (cancel)="onCancel()"
            (save)="onSave()"></app-form-card-actions>
        </app-form-card>
      </div>
    </div>
  </ng-form>
</app-form-content>