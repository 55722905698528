import { SpecialAirport, SpecialAirportOption } from './SpecialAirport';

import { AccountExecutive } from './AccountExecutive';
import { AddOn } from './Addon';
import { Approver } from './Approver';
import { CourseCheckTypeOverride } from './CourseCheckTypeOverride';
import { Licence } from './Licence';
import { RatingObjective } from './RatingObjective';
import { RegulationAgencyName } from './RegulationAgencyName';
import { ReservationVariant } from './ReservationVariant';
import { SubmissionStatus } from './SubmissionStatus';
import { TrainingDocumentSource } from './TrainingDocumentSource';
import { TrainingProfile } from './TrainingProfile';
import { UserRoles } from './User';

export enum ApplicationRights {
  TcaRead = 'TcaRead',
  TcaReadWrite = 'TcaReadWrite',
  ApprovalRead = 'ApprovalRead',
  ApprovalReadWrite = 'ApprovalReadWrite'
}

export enum TrainingRequirementStatuses {
  None = '',
  Saved = 'Saved',
  Submitted = 'Submitted',
  Approved = 'Approved',
  Locked = 'Locked'
}

export enum TrainingType {
  Initial = 'INITIAL',
  Recurrent = 'RECURRENT'
}

export class TrainingRequirement {
  id: string;
  revisionNumber: number;
  course: string;
  courseId: string;
  location: string;
  customerId: string;
  customer: string;
  accountExecutive: AccountExecutive;
  clientId: string;
  client: string;
  aircraftId: string;
  aircraftModel: string;
  startDate: Date;
  endDate: Date;
  trainingType: string;
  operatingManualA: string;
  operatingManualB: string;
  operatingManualC: string;
  operatingManualD: string;
  seat: string;
  phasedTraining : string;
  overrideSeat: string;
  licences: Licence[];
  specialAirports: SpecialAirport[];
  specialAirportOptions: SpecialAirportOption[];
  checkingAddons: AddOn[];
  trainingAddons: AddOn[];
  note: string;
  submissionStatus: SubmissionStatus;
  courseCheckType: string;
  courseCheckTypeOverride: CourseCheckTypeOverride;
  courseCheckTypesOverride: Array<CourseCheckTypeOverride>;
  submissionDateTime: string;
  isCustomTcaProvided: boolean;
  approvers: Array<Approver>;
  appRights: string[];
  userRole: UserRoles;
  schedulerReservationId: string;
  status: TrainingRequirementStatuses;
  aircraftOperatingCertificate: string;
  regulationAgency: RegulationAgencyName;
  baseMonth: string;
  ratingObjectives: Array<RatingObjective>;
  picAutopilot: boolean;
  atpRequired: boolean;
  trainingDocumentSources: TrainingDocumentSource[];
  currentDutyPosition: string;
  desiredDutyPosition: string;
  overrideDesiredDutyPosition: string;
  certificateHolderCode: string;
  certificateHolder: string;
  totalFlightTime: number;
  flightTimeInAircraftType: number;
  operatingAircraftTypeSerialNumber: string;
  trainingProfile: TrainingProfile;
  hasMultiEngineLandandInstrumentRating: boolean;
  variants: ReservationVariant[];
  tcaRemarks?: string;
  customerComments?: string;
  opspecComment?: string;
  latestPresetDate?: Date;


  constructor() {
    this.id = '0';
    this.course = '';
    this.courseId = '';
    this.location = '';
    this.customerId = '';
    this.customer = '';
    this.accountExecutive = new AccountExecutive();
    this.clientId = '';
    this.client = '';
    this.aircraftId = '';
    this.aircraftModel = '';
    this.startDate = new Date();
    this.endDate = new Date();
    this.trainingType = '';
    this.operatingManualA = '';
    this.operatingManualB = '';
    this.operatingManualC = '';
    this.operatingManualD = '';
    this.seat = '';
    this.phasedTraining = '';
    this.overrideSeat = '';
    this.licences = new Array<Licence>();
    this.specialAirports = new Array<SpecialAirport>();
    this.checkingAddons = new Array<AddOn>();
    this.trainingAddons = new Array<AddOn>();
    this.note = '';
    this.submissionStatus = SubmissionStatus.NotSubmitted;
    this.courseCheckType = '';
    this.approvers = new Array<Approver>();
    this.appRights = ['TcaReadWrite'];
    this.userRole = UserRoles.Customer;
    this.schedulerReservationId = '';
    this.courseCheckTypeOverride = new CourseCheckTypeOverride();
    this.courseCheckTypesOverride = new Array<CourseCheckTypeOverride>();
    this.status = TrainingRequirementStatuses.None;
    this.aircraftOperatingCertificate = '';
    this.regulationAgency = RegulationAgencyName.EASA;
    this.ratingObjectives = [];
    this.picAutopilot = false;
    this.atpRequired = false;
    this.trainingDocumentSources = new Array<TrainingDocumentSource>();
    this.totalFlightTime = 0;
    this.flightTimeInAircraftType = 0;
    this.operatingAircraftTypeSerialNumber = '';
    this.variants = [];
    this.trainingProfile = new TrainingProfile();
    this.latestPresetDate = new Date();
    this.revisionNumber = 0;
  }
}
