import { Feature } from '../models/Feature';

export class UpdateFeature {
  static readonly type = '[Features] UpdateFeature';
  constructor(public feature: Feature) {}
}
export class UpdateFeatures {
  static readonly type = '[Features] UpdateFeatures';
  constructor(public features: Array<Feature>) {}
}
export class UpdateFeedbackSent {
  static readonly type = '[Features] Update Feedback Sent';
  constructor(public isFeedbackSent: boolean) {}
}
export class GetAirports {
  static readonly type = '[Airports] Get Airports';
  constructor() {}
}
export class GetCountries {
  static readonly type = '[Countries] Get Countries';
  constructor(public authority: string) {}
}
