import { AppSettings } from 'src/app/models/AppSettings';
import { AppSettingsService } from 'common-web-core';
import { BaseDataService } from 'src/app/core/services/base.service';
import { HttpClient } from '@angular/common/http';
import { HttpErrorHandler } from 'src/app/app.error-handler';
import { Injectable } from '@angular/core';

@Injectable()
export class UsersService extends BaseDataService {
  constructor(http: HttpClient, 
              httpErrorHandler: HttpErrorHandler,
              appSettingsService: AppSettingsService<AppSettings>) {
    super(http, httpErrorHandler.createHandleError('TcaService'));

    if (!!appSettingsService.settings()) {      
      this.resourceUrl = appSettingsService.settings().webApiSettings.api + '/users';      
    }  
  }
}
