import { AppSettings } from 'src/app/models/AppSettings';
import { AppSettingsService } from 'common-web-core';
import { BaseDataService } from 'src/app/core/services/base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpErrorHandler } from 'src/app/app.error-handler';
import { Injectable } from '@angular/core';
import { tap, timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DownloadService extends BaseDataService {
  downloadUrl: string;
  baseUrl: string;

  private readonly DOWNLOAD_TIMEOUT_IN_SECONDS = 5;

  constructor(http: HttpClient,
              httpErrorHandler: HttpErrorHandler,
              appSettingsService: AppSettingsService<AppSettings>) {
    super(http, httpErrorHandler.createHandleError('DownloadService'));
    
    if (!!appSettingsService.settings()) {      
      this.downloadUrl = appSettingsService.settings().webApiSettings.api + '/tca';      
      this.baseUrl = appSettingsService.settings().webApiSettings.api;
    }            
  }

  async getPdfByTcaId(id: string): Promise<Blob> {
    const url = `${this.downloadUrl}/${id}/reports/paperversion`;
    const headersPdf = new HttpHeaders({
      'Content-Type': 'application/pdf',
      Accept: 'application/pdf'
    });

    try {
      return await this.http
        .get<Blob>(url, { headers: headersPdf, responseType: 'blob' as 'json' })
        .pipe(
          timeout(this.DOWNLOAD_TIMEOUT_IN_SECONDS * 1000)
        )
        .toPromise();
    }
    catch (ex) {
      throw ex;
    }
  }

  downloadPdfFromEmail(tcaId: string) {    
    const reportUrl = `${this.baseUrl}/tca/${tcaId}/reports/paperversion`;

    return this.http
      .get<Blob>(reportUrl, { responseType: 'blob' as 'json', observe: 'response' })
      .pipe(
        tap(response => {
          const contentDispositionHeader = response.headers.get('Content-Disposition');
          let filename;
          if (contentDispositionHeader) {
            filename = decodeURIComponent(
              contentDispositionHeader
                .split(';')[1]
                .split('filename')[1]
                .split('=')[1]
                .trim()
            );
          }
          else {
            filename = `TCA-${tcaId}`;
          }

          const blob = new Blob([response.body], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = filename;
          const browser = navigator.userAgent.toLowerCase();
          if (browser.indexOf('firefox') > -1) {
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            link.click();
          }
        })
      );
  }
}
