/**
 * ForceJS - REST toolkit for Salesforce.com
 * Author: Christophe Coenraets @ccoenraets
 * Version: 0.7.2
 */
/* tslint:disable */

import { AppSettings } from 'src/app/models/AppSettings';
import { AppSettingsService } from 'common-web-core';
import { Injectable } from '@angular/core';
import { LoggerService } from '../../logger/services/logger.service';

@Injectable({
  providedIn: 'root'
})
export class ForceService {
  // Reference to the window
  window: any = window;

  // The login URL for the OAuth process
  // To override default, pass loginURL in init(props)
  loginURL: string;

  // The Connected App client Id. Default app id provided - Not for production use.
  // This application supports http://localhost:8200/oauthcallback.html as a valid callback URL
  // To override default, pass appId in init(props)
  appId: string;

  // The force.com API version to use.
  // To override default, pass apiVersion in init(props)
  apiVersion = 'v35.0';

  // Keep track of OAuth data (access_token, refresh_token, and instance_url)
  oauth: any;

  // By default we store fbtoken in sessionStorage. This can be overridden in init()
  tokenStore: any = {};

  // if page URL is http://localhost:3000/myapp/index.html, context is /myapp
  context: string;

  // if page URL is http://localhost:3000/myapp/index.html, serverURL is http://localhost:3000
  serverURL: string;

  // if page URL is http://localhost:3000/myapp/index.html, baseURL is http://localhost:3000/myapp
  baseURL: string;

  // Only required when using REST APIs in an app hosted on your own server to avoid cross domain policy issues
  // To override default, pass proxyURL in init(props)
  proxyURL: string;

  // if page URL is http://localhost:3000/myapp/index.html, oauthCallbackURL is http://localhost:3000/myapp/oauthcallback.html
  // To override default, pass oauthCallbackURL in init(props)
  oauthCallbackURL: string;

  // Reference to the Salesforce OAuth plugin
  // oauthPlugin: any;

  // Whether or not to use a CORS proxy. Defaults to false if app running in Cordova, in a VF page,
  // or using the Salesforce console. Can be overriden in init()
  useProxy: boolean;

  // data from salesforce describe
  constructor(private loggerService: LoggerService,
    private appSettingsService: AppSettingsService<AppSettings>) { }

  private initSettings(): void {
    this.loginURL = this.appSettingsService.settings().authSettings.providerAuthUrl;
    this.appId = this.appSettingsService.settings().authSettings.clientId;

    this.window = window;
    this.context = window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/'));
    this.serverURL =
      window.location.protocol +
      '//' +
      window.location.hostname +
      (window.location.port ? ':' + window.location.port : '');
    this.baseURL = this.serverURL + this.context;
    this.proxyURL = this.baseURL;
    this.oauthCallbackURL = this.serverURL + '/login';
    try {
      this.useProxy =
        this.window.cordova || this.window.SfdcApp || this.window.sforce ? false : true;
    } catch (e) {
      this.useProxy = true;
    }
  }

  parseQueryString = queryString => {
    const qs = decodeURIComponent(queryString),
      obj = {},
      params = qs.split('&');
    params.forEach(param => {
      const splitter = param.split('=');
      obj[splitter[0]] = splitter[1];
    });
    return obj;
  };

  /**
   * Login to Salesforce using OAuth. If running in a Browser, the OAuth workflow happens in a a popup window.
   * If running in Cordova container, it happens using the Mobile SDK 2.3+ Oauth Plugin
   */
  login = (login_hint: string) => {
    this.initSettings();
    return this.loginWithBrowser(login_hint);
  };

  loginWithBrowser = (login_hint: string) =>
    new Promise<void>((resolve, reject) => {
      this.loggerService.info(`force:loginWithBrowser:callbackUrl - ${this.oauthCallbackURL}`);
      let loginWindowURL =
        this.loginURL +
        '/services/oauth2/authorize?client_id=' +
        this.appId +
        '&redirect_uri=' +
        this.oauthCallbackURL +
        '&response_type=token&grant_type=refresh_token&prompt=login' +
        '&login_hint=';

      if (login_hint) {
        loginWindowURL += login_hint;
      }

      document.addEventListener('oauthCallback', evt => {
        this.loggerService.log('force:loginWithBrowser:oauthCallback', 'event' + evt);
        const event: any = evt;
        // Parse the OAuth data received from Salesforce
        let url = event.detail,
          queryString,
          obj;

        if (url.indexOf('access_token=') > 0) {
          queryString = url.substr(url.indexOf('#') + 1);
          obj = this.parseQueryString(queryString);
          this.oauth = obj;
          this.tokenStore.forceOAuth = JSON.stringify(this.oauth);
          resolve();
        } else if (url.indexOf('error=') > 0) {
          queryString = decodeURIComponent(url.substring(url.indexOf('?') + 1));
          obj = this.parseQueryString(queryString);
          reject(obj);
        } else {
          reject({ status: 'access_denied' });
        }
      });

      window.open(loginWindowURL, '_self', 'location=no');
    });
}
