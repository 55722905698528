import { AppSettings } from 'src/app/models/AppSettings';
import { AppSettingsService } from 'common-web-core';
import { BaseDataService } from 'src/app/core/services/base.service';
import { Feedback } from 'src/app/models/Feedback';
import { HttpClient } from '@angular/common/http';
import { HttpErrorHandler } from 'src/app/app.error-handler';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService extends BaseDataService {
  feedbackUrl: string;

  constructor(http: HttpClient, 
              httpErrorHandler: HttpErrorHandler,
              appSettingsService: AppSettingsService<AppSettings>) {
    super(http, httpErrorHandler.createHandleError('FeedbackService'));
      
    appSettingsService.appSettings$.subscribe((appSettings: AppSettings) => {            
      this.feedbackUrl = appSettings.webApiSettings.api;   
    });
  }

  sendFeedbackInfo(feedback: Feedback) {
    const url = `${this.feedbackUrl}/trainingrequirement/${feedback.trainingRequirementId}/feedback`;
    return this.http
      .post<Feedback>(url, feedback, this.httpOptions)
      .pipe(catchError(this.handleHttpError<Feedback>()));
  }
  
}
