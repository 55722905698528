import { AuthB2CService } from 'common-web-core';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { AuthAADSettings } from 'src/app/models/Settings/AuthAADSettings';
import { B2C_TOKEN_NAME } from '../services';

const AUTH_MODE_KEY = 'authMode';

export enum AuthAuthorities {
  Unknown = 0,
  SalesForce = 1,
  AzureAd = 2,
  AzureB2C = 3
}

export function getAdalConfig(authAADSettings: AuthAADSettings) {
  return {
    tenant: authAADSettings.tenant,
    clientId: authAADSettings.clientIdAd,
    redirectUri: authAADSettings.redirectUri,
    endpoints: {},
    navigateToLoginRequestUrl: false,
    cacheLocation: 'localStorage',
    extraQueryParameter: 'login_hint=' + JSON.parse(localStorage.getItem('login_hint'))
  };
}

export class AuthHelper {
  private _b2CLoggedIn: boolean;

  constructor(private authAADSettings: AuthAADSettings,
    private authB2cService?: AuthB2CService) {
  }

  private adalService: MsAdalAngular6Service = null;

  private _b2CAccessToken: string = '';

  private get authServiceInstance(): MsAdalAngular6Service | null {
    if (!this.adalService) {

      // We only create the adalService when we have a valid login_hint. 
      // We return null if login_hint is not yet provided.
      const loginHintFromLocalStorage = localStorage.getItem('login_hint');
      if (!loginHintFromLocalStorage || loginHintFromLocalStorage === 'undefined')
        return null;

      const loginHint = JSON.parse(loginHintFromLocalStorage);
      if (!loginHint)
        return null;

      this.adalService = new MsAdalAngular6Service(getAdalConfig(this.authAADSettings));

    }

    return this.adalService;
  }

  public get currentAuthMode(): AuthAuthorities {
    return <AuthAuthorities>JSON.parse(localStorage.getItem(AUTH_MODE_KEY));
  }

  public set currentAuthMode(authMode: AuthAuthorities) {
    localStorage.setItem(AUTH_MODE_KEY, JSON.stringify(authMode));
  }

  public get isAzureAd(): boolean {
    return this.currentAuthMode === AuthAuthorities.AzureAd;
  }

  public get isAzureB2C(): boolean {
    return this.currentAuthMode === AuthAuthorities.AzureB2C;
  }

  public get isSalesForce(): boolean {
    return this.currentAuthMode === AuthAuthorities.SalesForce;
  }

  public async login(login_hint: string): Promise<boolean> {
    localStorage.setItem('login_hint', JSON.stringify(login_hint));

    if (this.isAzureAd && this.authServiceInstance) {
      this.authServiceInstance.login();
      return true;
    }

    if (this.isAzureB2C && this.authB2cService) {
      return await this.authB2cService.login();
    }

    return false;

  }

  public async logout(): Promise<void> {

    if (this.isAzureAd && this.authServiceInstance) {
      this.authServiceInstance.logout();
    }

    if (this.isAzureB2C && this.authB2cService) {
      await this.authB2cService.logout();
      this.isB2CAuthenticated = false;
      localStorage.removeItem(B2C_TOKEN_NAME);
      this.b2cAccessToken = '';
    }

  }

  public get isAuthenticated(): boolean {

    if (this.isAzureB2C) {
      return this.isB2CAuthenticated;
    }

    return this.authServiceInstance ? this.authServiceInstance.isAuthenticated : false;
  }

  public set isB2CAuthenticated(value: boolean) {
    this._b2CLoggedIn = value;
  }

  public get isB2CAuthenticated(): boolean {

    if (this.authB2cService) {
      return this._b2CLoggedIn;
    }


    return false;
  }

  public get accessToken(): string {
    return this.authServiceInstance ? this.authServiceInstance.accessToken : '';
  }

  public get b2cAccessToken() {
    return this._b2CAccessToken || localStorage.getItem(B2C_TOKEN_NAME) || '';
  }

  public set b2cAccessToken(val: string) {
    this._b2CAccessToken = val;
  }



  public handleCallback(): void {

    if (this.authServiceInstance)
      this.authServiceInstance.handleCallback();
  }


}
