import { RouterModule, Routes, UrlSegment } from '@angular/router';

import { AppRoutePaths } from './core/constants';
import { AuthGuard } from 'src/app/core/guards';
import { CommonModule } from '@angular/common';
import { LoginAzureAdPageComponent } from './pages/login/login-azure-ad-page/login-azure-ad-page.component';
import { LoginPageComponent } from './pages/login/login-page/login-page.component';
import { LoginPageMainComponent } from './pages/login/login-page-main/login-page-main.component';
import { LoginSalesForcePageComponent } from './pages/login/login-salesforce-page/login-salesforce-page.component';
import { LoginSelectionPageComponent } from './pages/login/login-selection-page/login-selection-page.component';
import { NgModule } from '@angular/core';

export const routes: Routes = [
  { path: '', redirectTo: AppRoutePaths.TCA, pathMatch: 'full' },
  {
    path: AppRoutePaths.Login,
    component: LoginPageComponent,
    children: [
      { path: '', component: LoginPageMainComponent },
      { path: 'selection', component: LoginSelectionPageComponent }
    ]
  },
  { path: AppRoutePaths.LoginSalesforce, component: LoginSalesForcePageComponent },
  { path: AppRoutePaths.LoginAzureAD, component: LoginAzureAdPageComponent },
  {
    path: AppRoutePaths.Maintenance,
    loadChildren: () => import('./maintenance/maintenance.module').then(m => m.MaintenanceModule)
  },
  {
    path: AppRoutePaths.TCA,
    loadChildren: () => import('./etca/etca.module').then(m => m.EtcaModule),
    canActivate: [AuthGuard]
  },
  { path: '**', component: LoginPageComponent }
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot([...routes], { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
