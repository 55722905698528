import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { Airport } from 'src/app/models/Airport';
import { AppSettings } from 'src/app/models/AppSettings';
import { AppSettingsService } from 'common-web-core';
import { BaseDataService } from 'src/app/core/services/base.service';
import { HttpClient } from '@angular/common/http';
import { HttpErrorHandler } from 'src/app/app.error-handler';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AirportsService extends BaseDataService {
  baseUrl: string;

  airports = new Array<Airport>();

  constructor(http: HttpClient, 
              httpErrorHandler: HttpErrorHandler,
              appSettingsService: AppSettingsService<AppSettings>) {
    super(http, httpErrorHandler.createHandleError('AirportsService'));
    
    appSettingsService.appSettings$.subscribe((appSettings: AppSettings) => {           
      this.baseUrl = appSettings.webApiSettings.api + '/airports';      
    });
  }

  getAirports(): Observable<Array<Airport>> {
    const url = `${this.baseUrl}`;

    if (this.airports.length > 0) {
      return new BehaviorSubject(this.airports);
    }

    return this.http
      .get<Array<Airport>>(url)
      .pipe(
        tap(airports => {
          this.airports = airports;
        })
      )
      .pipe(catchError(this.handleHttpError<Array<Airport>>()));
  }
}
