import { Item, OperatingManualReferenceType } from '../models/Item';

import { AnalyticsService } from './analytics-service';
import { Authority } from '../models/Authority';
import { Injectable } from '@angular/core';
import { Licence } from '../models/Licence';
import { LicenseTypes } from '../data/licenseTypes';
import { LogAnalyticEvent } from '../state/trainingRequirement.actions';
import { OMVersion } from '../models/OMVersion';
import { Store } from '@ngxs/store';
import { TrainingRequirement, TrainingType } from '../models/TrainingRequirement';
import { validateEmail } from '../shared/helpers/email-helpers';
import { TrainingProfile } from '../models/TrainingProfile';
import { RegulationAgencyName } from '../models/RegulationAgencyName';
import { LicenseCategory } from '../data/licenseCategory';

export enum ValidationErrorTypes {
  NONE = '',
  NO_LICENCES = 'NoLicenses',
  LICENCE_NUMBER_REQUIRED = 'LicenceNumberRequired',
  ISSUING_COUNTRY_REQUIRED = 'IssuingCountryRequired',
  EXPIRY_DATE_REQUIRED = 'ExpiryDateRequired',
  LICENCE_EXPIRED = 'LicenceExpired',
  OM_REQUIRED = 'OmRequired',
  COURSE_INFO_SEAT_REQUIRED = 'CourseInformationSeatRequired',
  COURSE_INFO_CHECKTYPE_REQUIRED = 'CourseInformationCheckTypeRequired',
  LICENSE_TYPE_OTHER_DESCRIPTION_REQUIRED = 'LicenseTypeOtherDescriptionRequired',
  APPROVER_EMAIL_REQUIRED = 'ApproverEmailRequired',
  APPROVER_EMAIL_INVALID = 'ApproverEmailInvalid',
  AIRPORT_NAME_REQUIRED = 'AIRPORT_NAME_REQUIRED',
  AIRPORT_TYPE_REQUIRED = 'AIRPORT_TYPE_REQUIRED',
  ACP_COMPLETING_RIDE_CHECK_REQUIRED = 'ACP_COMPLETING_RIDE_CHECK_REQUIRED',
  PILOT_MONITORING_TIME_REQUIREMENT_REQUIRED = 'PILOT_MONITORING_TIME_REQUIREMENT_REQUIRED',
  CUSTOM_TCA_REQUIRED = 'CustomTcaRequired',
}

export class ValidationErrors {
  isValid = true;
  licenceErrors = new Array<string>();
  omRefErrors = new Array<string>();
  courseErrors = new Array<string>();
  approverEmailErrors = new Array<string>();
  airportErrors = new Array<string>();
  trainingProfileErrors = new Array<string>();
  customTcaErrors = new Array<string>();

  merge(validationErrors: ValidationErrors) {
    this.licenceErrors = this.licenceErrors.concat(validationErrors.licenceErrors);
    this.omRefErrors = this.omRefErrors.concat(validationErrors.omRefErrors);
    this.courseErrors = this.courseErrors.concat(validationErrors.courseErrors);
    this.approverEmailErrors = this.approverEmailErrors.concat(validationErrors.approverEmailErrors);
    this.airportErrors = this.airportErrors.concat(validationErrors.airportErrors);
    this.trainingProfileErrors = this.trainingProfileErrors.concat(validationErrors.trainingProfileErrors);
    this.customTcaErrors = this.customTcaErrors.concat(validationErrors.customTcaErrors);
    this.isValid = this.isValid && validationErrors.isValid;
  }

  addTrainingProfileError(errorType: string) {
    this.trainingProfileErrors.push(errorType);
    this.isValid = false;
  }

  addLicenseError(errorType: string) {
    this.licenceErrors.push(errorType);
    if (errorType !== ValidationErrorTypes.LICENCE_EXPIRED) {
      this.isValid = false;
    }
  }

  addOMRefErrors(errorType: string) {
    this.omRefErrors.push(errorType);
    this.isValid = false;
  }

  addApproverEmailErrors(errorType: string) {
    this.approverEmailErrors.push(errorType);
    this.isValid = false;
  }

  addCourseError(errorType: string) {
    this.courseErrors.push(errorType);
    this.isValid = false;
  }

  addAirportError(errorType: string) {
    this.airportErrors.push(errorType);
    this.isValid = false;
  }

  addCustomTcaError(errorType: string) {
    this.customTcaErrors.push(errorType);
    this.isValid = false;
  }

  reset() {
    this.isValid = true;
    this.licenceErrors = new Array<string>();
    this.omRefErrors = new Array<string>();
    this.courseErrors = new Array<string>();
  }
}

@Injectable({
  providedIn: 'root'
})
export class TcaValidationService {
  constructor(private analyticsService: AnalyticsService, private store: Store) { }

  public validate(trainingRequirement: TrainingRequirement): ValidationErrors {
    let validationErrors = new ValidationErrors();

    if (trainingRequirement.isCustomTcaProvided) {
      return validationErrors;
    }

    trainingRequirement.licences.forEach(licence => {

      const validateExpiryDate = (trainingRequirement.regulationAgency != RegulationAgencyName.TC || trainingRequirement.trainingType === TrainingType.Recurrent) && trainingRequirement.regulationAgency != RegulationAgencyName.Generic;

      const licenceValidationErrors = this.validateLicence(licence, 'TCA Page', validateExpiryDate);

      if (!licenceValidationErrors.isValid) {
        validationErrors = licenceValidationErrors;
      }
    });

    const omVersions = new Array<OMVersion>();

    // Disable Operating manual validations for TC TCA Forms since it's not applicable
    if (trainingRequirement.regulationAgency !== Authority.TC.toString() && trainingRequirement.regulationAgency !== Authority.Generic.toString() && this.activateValidation(trainingRequirement)) {
      omVersions.push({ name: 'operatingManualA', value: trainingRequirement.operatingManualA });
      omVersions.push({ name: 'operatingManualB', value: trainingRequirement.operatingManualB });
      omVersions.push({ name: 'operatingManualC', value: trainingRequirement.operatingManualC });
      omVersions.push({ name: 'operatingManualD', value: trainingRequirement.operatingManualD });
    }

    if (trainingRequirement.checkingAddons.length > 0) {
        trainingRequirement.checkingAddons.forEach(checkingAddon => {
          if (checkingAddon.active) {
            validationErrors.merge(this.validateOmRef(checkingAddon.options, 'TCA Page'));
          }
        });
    }

    if (trainingRequirement.trainingAddons.length > 0) {
        trainingRequirement.trainingAddons.forEach(trainingAddon => {
          if (trainingAddon.active) {
            validationErrors.merge(this.validateOmRef(trainingAddon.options, 'TCA Page'));
          }
        });
    }

    if (omVersions.length > 0) {
      omVersions.forEach(omVersion => {
        if (omVersion.value === '' || omVersion.value === null) {
          validationErrors.addOMRefErrors(ValidationErrorTypes.OM_REQUIRED + '-' + omVersion.name);
          this.logError('TCA Page', ValidationErrorTypes.OM_REQUIRED);
        }
      });
    }

    return validationErrors;
  }

  public activateValidation(trainingRequirement: TrainingRequirement): boolean {
    let enableValidation = false;
    if (trainingRequirement.trainingType === 'INITIAL') {
      trainingRequirement.courseCheckTypesOverride.forEach(checkType => {
        if (checkType.type.toLowerCase() === 'easa' && checkType.valueOverride === 'LST / OPC') {
          enableValidation = true;
        }
      });
    } else {
      enableValidation = true;
    }
    return enableValidation;
  }

  public validateOmRef(items: Array<Item>, pageName: string): ValidationErrors {
    const addonValidationErrors = new ValidationErrors();
    items.forEach(item => {
      if (!!item && item.operatingManualReferenceType !== OperatingManualReferenceType.None) {
        if (
          (item.operatingManualReferenceType === OperatingManualReferenceType.Advanced &&
            (!/\S/.test(item.operatingManualReference))) ||
          (item.operatingManualReferenceType === OperatingManualReferenceType.Simple &&
            (!/\S/.test(item.operatingManualReference) || !item.operatingManualReference) &&
            !!item.value &&
            item.value !== 'none')
        ) {
          addonValidationErrors.addOMRefErrors(ValidationErrorTypes.OM_REQUIRED + '-' + item.name);
          this.logError(pageName, ValidationErrorTypes.OM_REQUIRED);
        }
      }
    });

    return addonValidationErrors;
  }

  public validateCustomTcaErrors(isCustomTcaFileMissing: boolean): ValidationErrors {
    const customTcaErrors = new ValidationErrors();
    if (isCustomTcaFileMissing) {
      customTcaErrors.addCustomTcaError(ValidationErrorTypes.CUSTOM_TCA_REQUIRED);
    }
    return customTcaErrors;
  }

  public validateLicence(licence: Licence, pageName?: string, validateExpiryDate: boolean = true): ValidationErrors {
    const licenceValidationErrors = new ValidationErrors();
    let _pageName = '';
    !!pageName && pageName !== '' ? (_pageName = pageName) : (_pageName = 'License Page');
    if (
      licence.authority !== Authority.FAA &&
      licence.authority !== Authority.Military &&
      !!licence.expiryDate &&
      Date.parse(licence.expiryDate) < Date.now()
    ) {
      licenceValidationErrors.addLicenseError(ValidationErrorTypes.LICENCE_EXPIRED);
      this.logError(_pageName, ValidationErrorTypes.LICENCE_EXPIRED);
    }

   if (licence.authority !== Authority.Military && 
       licence.licenceCategoryType === LicenseCategory.Existing && 
       !licence.certificateNumber) {
        licenceValidationErrors.addLicenseError(ValidationErrorTypes.LICENCE_NUMBER_REQUIRED);
        this.logError(_pageName, ValidationErrorTypes.LICENCE_NUMBER_REQUIRED);
    }

    if (validateExpiryDate &&
      licence.authority !== Authority.FAA &&
      licence.authority !== Authority.Military &&
      (!licence.expiryDate || licence.expiryDate === '')
    ) {
      licenceValidationErrors.addLicenseError(ValidationErrorTypes.EXPIRY_DATE_REQUIRED);
      this.logError(_pageName, ValidationErrorTypes.EXPIRY_DATE_REQUIRED);
    }
    if (
      licence.authority !== Authority.FAA &&
      licence.authority !== Authority.Military &&
      licence.authority !== Authority.TC &&
      licence.authority !== Authority.Generic &&
      (!licence.country || licence.country === '')
    ) {
      licenceValidationErrors.addLicenseError(ValidationErrorTypes.ISSUING_COUNTRY_REQUIRED);
      this.logError(_pageName, ValidationErrorTypes.ISSUING_COUNTRY_REQUIRED);
    }

    if (
      licence.licenceType.name === LicenseTypes.Other &&
      (!licence.licenceType.value || licence.licenceType.value === '')
    ) {
      licenceValidationErrors.addLicenseError(
        ValidationErrorTypes.LICENSE_TYPE_OTHER_DESCRIPTION_REQUIRED
      );
      this.logError(_pageName, ValidationErrorTypes.LICENSE_TYPE_OTHER_DESCRIPTION_REQUIRED);
    }

    return licenceValidationErrors;
  }

  public validateApproversEmails(
    schedulerEmail: string,
    trainingManagerEmail: string,
    pageName?: string
  ): ValidationErrors {
    const approverValidationErrors = new ValidationErrors();
    if (!schedulerEmail) {
      approverValidationErrors.addApproverEmailErrors(
        ValidationErrorTypes.APPROVER_EMAIL_REQUIRED + '-SCEmail'
      );
      this.logError(pageName, ValidationErrorTypes.APPROVER_EMAIL_REQUIRED);
    } else if (!validateEmail(schedulerEmail)) {
      approverValidationErrors.addApproverEmailErrors(
        ValidationErrorTypes.APPROVER_EMAIL_INVALID + '-SCEmail'
      );
      this.logError(pageName, ValidationErrorTypes.APPROVER_EMAIL_INVALID);
    }
    if (!trainingManagerEmail) {
      approverValidationErrors.addApproverEmailErrors(
        ValidationErrorTypes.APPROVER_EMAIL_REQUIRED + '-TMEmail'
      );
      this.logError(pageName, ValidationErrorTypes.APPROVER_EMAIL_REQUIRED);
    } else if (!validateEmail(trainingManagerEmail)) {
      approverValidationErrors.addApproverEmailErrors(
        ValidationErrorTypes.APPROVER_EMAIL_INVALID + '-TMEmail'
      );
      this.logError(pageName, ValidationErrorTypes.APPROVER_EMAIL_INVALID);
    }
    return approverValidationErrors;
  }

  public validateAirport(
    items: Array<Item>,
    pageName: string,
    validateOm: boolean,
    validateTrainingType: boolean = false
  ): ValidationErrors {
    const airportValidationErrors = new ValidationErrors();
    items.forEach(item => {
      if (!!item && !item.value) {
        airportValidationErrors.addAirportError(ValidationErrorTypes.AIRPORT_NAME_REQUIRED);
        this.logError(pageName, ValidationErrorTypes.AIRPORT_NAME_REQUIRED);
      }
      if (!!item && !item.category && validateTrainingType) {
        airportValidationErrors.addAirportError(ValidationErrorTypes.AIRPORT_TYPE_REQUIRED);
        this.logError(pageName, ValidationErrorTypes.AIRPORT_TYPE_REQUIRED);
      }
      if (!!item && !item.operatingManualReference && validateOm) {
        airportValidationErrors.addOMRefErrors(ValidationErrorTypes.OM_REQUIRED);
        this.logError(pageName, ValidationErrorTypes.OM_REQUIRED);
      }
    });

    return airportValidationErrors;
  }

  public validateTrainingProfile(trainingRequirement: TrainingRequirement): ValidationErrors {

    const trainingProfile: TrainingProfile = trainingRequirement.trainingProfile;

    const validationErrors = new ValidationErrors();

    if (trainingProfile.operatingRegulation === '704') {

      if (!trainingProfile.acpCompletingCheckRideName || trainingProfile.acpCompletingCheckRideName.trim() === '') {
        validationErrors.addTrainingProfileError(ValidationErrorTypes.ACP_COMPLETING_RIDE_CHECK_REQUIRED);
        this.logError('TCA Page', ValidationErrorTypes.ACP_COMPLETING_RIDE_CHECK_REQUIRED);
      }
    }

    if (trainingProfile.hasPilotMonitoringTimeRequirements) {

      if (!trainingProfile.minimumGroundSchoolTime || trainingProfile.minimumGroundSchoolTime === 0) {
        validationErrors.addTrainingProfileError(ValidationErrorTypes.PILOT_MONITORING_TIME_REQUIREMENT_REQUIRED);
        this.logError('TCA Page', ValidationErrorTypes.PILOT_MONITORING_TIME_REQUIREMENT_REQUIRED);
      }
    }

    return validationErrors;
  }

  private logError(pageName: string, errorType: ValidationErrorTypes) {
    if (errorType.toLocaleLowerCase().includes('required')) {
      this.store.dispatch(
        new LogAnalyticEvent(
          'errormessage',
          'Error Message',
          pageName,
          null,
          errorType,
          'This field is required'
        )
      );
    } else if (errorType.toLocaleLowerCase().includes('nolicense')) {
      this.store.dispatch(
        new LogAnalyticEvent(
          'errormessage',
          'Error Message',
          pageName,
          null,
          errorType,
          'You must provide at least one licence. Click the button below to add a licence.'
        )
      );
    } else if (errorType.toLocaleLowerCase().includes('emailinvalid')) {
      this.store.dispatch(
        new LogAnalyticEvent(
          'errormessage',
          'Error Message',
          pageName,
          null,
          errorType,
          'This is not a valid email'
        )
      );
    } else if (errorType.toLocaleLowerCase().includes('expired')) {
      this.store.dispatch(
        new LogAnalyticEvent(
          'warningmessage',
          'Warning Message',
          pageName,
          null,
          errorType,
          'Rating is expired. Your AE will contact you for more information.'
        )
      );
    }
  }
}
