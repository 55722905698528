import { ActivatedRoute, Router } from '@angular/router';
import { ButtonAttractive, ButtonIcon, ButtonType } from 'common-web-ui';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DeleteNote, LogAnalyticEvent } from 'src/app/state/trainingRequirement.actions';
import { Observable, Subscription } from 'rxjs';
import { Select, Store } from '@ngxs/store';

import { RegulationAgencyName } from 'src/app/models/RegulationAgencyName';
import { TrainingRequirement } from 'src/app/models/TrainingRequirement';
import { TrainingRequirementState } from 'src/app/state/trainingRequirement.state';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.less']
})
export class NoteComponent implements OnInit, OnDestroy {
  @Input() readonly: boolean;
  currentTrainingRequirement: TrainingRequirement | null;
  @Select(TrainingRequirementState.currentTrainingRequirement)
  currentTrainingRequirement$: Observable<TrainingRequirement>;
  subscription: Subscription;

  readonly buttonType = ButtonType;
  readonly buttonAttractive = ButtonAttractive;
  readonly buttonIcon = ButtonIcon;

  constructor(private route: ActivatedRoute, private router: Router, private store: Store) {
    this.subscription = this.currentTrainingRequirement$.subscribe(trainingRequirement => {
      this.currentTrainingRequirement = trainingRequirement;
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  goToNotePage(number: string) {
    if (this.readonly) {
      return;
    }
    this.store.dispatch(
      new LogAnalyticEvent(
        'subpage',
        this.hasNote() ? 'TCA Subpage Edit' : 'TCA Subpage Add',
        'Note Page'
      )
    );
    this.router.navigate(['./note'], { relativeTo: this.route });
  }

  hasNote() {
    return (
      !!this.currentTrainingRequirement &&
      !!this.currentTrainingRequirement.note &&
      this.currentTrainingRequirement.note !== ''
    );
  }

  onDelete() {
    this.store.dispatch(new DeleteNote());
    this.store.dispatch(new LogAnalyticEvent('regular', 'TCA Delete Note'));
  }
}
