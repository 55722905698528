import { Injectable } from '@angular/core';
import { SegmentService } from 'ngx-segment-analytics';
import { MessageService } from './message.service';
import { AnalyticsBaseProperties } from '../models/AnalyticsBaseProperties';
import { AppInsights } from 'applicationinsights-js';
import { LoggerService } from '../logger/services/logger.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor(
    private _segment: SegmentService,
    private _messageService: MessageService,
    private loggerService: LoggerService
  ) {}

  logAnalytic<T extends AnalyticsBaseProperties>(event: string, propreties?: T) {
    this._segment.identify(propreties.user_id);
    this._segment.track(event, propreties);
    this._messageService.pushAnalytics(event, propreties);
  }

  logAnalyticEvent<T extends AnalyticsBaseProperties>(event: string, propreties?: T) {
    this._segment.identify(propreties.user_id);
    this._segment.track(event, propreties);
    AppInsights.trackEvent(event, JSON.parse(JSON.stringify(propreties)));
    this.loggerService.log(`analytics:logAnalyticEvent - event: ${JSON.stringify(propreties)}`);
  }
}
