import { ApproverRole, Approver } from 'src/app/models/Approver';
import { User } from 'src/app/models/User';
import { ActivatedRoute } from '@angular/router';

export class InitializeApprovers {
  static readonly type = '[Approval] Initialize Approvers';
  constructor(public accountExecutiveEmail: string, public approvers: Array<Approver>) {}
}

export class UpdateApproverEmail {
  static readonly type = '[Approval] Update Approvers Email';
  constructor(
    public trainingRequirementId: string,
    public revisionNumer: number,
    public approverRole: ApproverRole,
    public email: string
  ) {}
}

export class UpdateApproverUserInfo {
  static readonly type = '[Approval] Update Approvers Info';
  constructor(
    public trainingRequirementId: string,
    public revisionNumer: number,
    public approverRole: ApproverRole,
    public user: User
  ) {}
}

export class UpdateApproverComment {
  static readonly type = '[Approval] Update Approver Comments';
  constructor(public approverRole: ApproverRole, public comment: string) {}
}

export class AeApproval {
  static readonly type = '[Approval] AccountExecutive Approval';
  constructor(
    public trainingRequirementId: string,
    public revisionNumer: number,
    public route: ActivatedRoute
  ) {}
}

export class TmScApproval {
  static readonly type = '[Approval] TrainingManager or Scheduler Approval';
  constructor(
    public trainingRequirementId: string,
    public revisionNumer: number,
    public approverRole: ApproverRole,
    public route: ActivatedRoute
  ) {}
}

export class ClearApproval {
  static readonly type = '[Approval] Clear Approval State';
  constructor() {}
}
