import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ChangeLDUserInfo, GetUserInfo, GetUserInfoSuccess, SetReturnUrl, StoreUserInfo } from './login.actions';

import { AuthService } from '../core/services';
import { FeatureFlagService } from '../services/feature-flags/feature-flag.service';
import { Injectable } from '@angular/core';
import { User } from '../models/User';
import { UserInfoService } from '../services/data-layer/userinfo.service';

export interface LoginStateModel {
  returnUrl: string;
  currentUser: User;
  isLoaded: boolean;
}

@State<LoginStateModel>({
  name: 'login',
  defaults: {
    returnUrl: '',
    currentUser: null,
    isLoaded: false
  }
})
@Injectable()
export class LoginState {
  constructor(
    private userService: UserInfoService,
    private featureFlagService: FeatureFlagService,
    private authService: AuthService
  ) { }

  @Selector()
  static userId(state: LoginStateModel): User | null {
    return !!state ? state.currentUser : null;
  }

  @Selector()
  static isUserInfoLoaded(state: LoginStateModel): boolean {
    return !!state ? state.isLoaded : false;
  }

  @Action(SetReturnUrl)
  SetReturnUrl({ patchState }: StateContext<LoginStateModel>, action: SetReturnUrl) {
    patchState({
      returnUrl: action.returnUrl
    });
  }

  @Action(StoreUserInfo)
  StoreUserInfo(
    { patchState, dispatch }: StateContext<LoginStateModel>,
    action: StoreUserInfo
  ) {

    this.featureFlagService.changeUser(action.userInfo);

    patchState({
      currentUser: action.userInfo,
      isLoaded: true
    });

    if (!!action.userInfo && this.authService.isAuthenticated()) {
      dispatch(new GetUserInfoSuccess(action.userInfo));
    }
  }

  @Action(ChangeLDUserInfo)
  UpdateLDUserInfo(
    __: StateContext<LoginStateModel>,
    action: ChangeLDUserInfo
  ) {
    this.featureFlagService.changeUser(action.userInfo);
  }

  @Action(GetUserInfo)
  GetUserInfo({ getState, dispatch }: StateContext<LoginStateModel>) {
    if (!!getState() && !getState().currentUser && this.authService.isAuthenticated()) {
      this.userService.getUserInfo(res => {
        dispatch(new GetUserInfoSuccess(res));
      });
    }
  }

  @Action(GetUserInfoSuccess)
  GetUserInfoSuccess({ patchState }: StateContext<LoginStateModel>, action: GetUserInfoSuccess) {
    this.featureFlagService.changeUser(action.userInfo);
    patchState({
      currentUser: action.userInfo,
      isLoaded: true
    });
  }
}
