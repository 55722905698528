import { Component, Input } from '@angular/core';

import { BaseUIComponent } from '../base-ui-component';
import { PanelInfoState } from 'common-web-ui';

export enum FormFieldListItemState {
  Default = 0,
  Warning = 1,
  Error = 2
}
export enum FormFieldListItemMode {
  Navigation,
  Add
}

@Component({
  selector: 'app-form-field-list-item',
  templateUrl: './form-field-list-item.component.html',
  styleUrls: ['./form-field-list-item.component.less']
})
export class FormFieldListItemComponent extends BaseUIComponent {
  @Input() state: FormFieldListItemState;
  @Input() mode: FormFieldListItemMode;
  @Input() label: string;
  @Input() informations: any[];
  @Input() readonly: boolean;

  public formFieldListItemState = FormFieldListItemState;
  public formFieldListItemMode = FormFieldListItemMode;
  protected panelInfoState = PanelInfoState;
  public isShowPanelInformation: boolean;

  constructor() {
    super();
  }

  onInit() {
    this.isShowPanelInformation = Array.isArray(this.informations);
  }

  onDestroy() {}

  isReadonlyContent() {
    return this.readonly;
  }
}
