import { NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import { LogAppender } from './log-appender';
import { LogLevel } from './log-level.enum';

export class NgxLoggerAppender extends LogAppender {
  constructor(private ngxLogger: NGXLogger) {
    super();
  }

  setLogLevel(logLevel: LogLevel) {
    const lg = this.getLogLevel(logLevel);
    this.ngxLogger.updateConfig({ level: lg });
    this.logLevel = logLevel;
  }

  /**
   * Log to the console using NGXLogger.
   * @param logLevel The log level to use. Minimum permitted will be the one from the AppSettings
   */
  log(logLevel: LogLevel, message: any, ...additional: any[]) {
    if (logLevel >= this.logLevel) {
      switch (logLevel) {
        case LogLevel.Trace:
          this.ngxLogger.trace(message, ...additional);
          return;
        case LogLevel.Debug:
          this.ngxLogger.debug(message, ...additional);
          return;
        case LogLevel.Info:
          this.ngxLogger.info(message, ...additional);
          return;
        case LogLevel.Log:
          this.ngxLogger.log(message, ...additional);
          return;
        case LogLevel.Warn:
          this.ngxLogger.warn(message, ...additional);
          return;
        case LogLevel.Error:
          this.ngxLogger.error(message, ...additional);
          return;
        case LogLevel.Fatal:
          this.ngxLogger.fatal(message, ...additional);
          return;
        default:
          return;
      }
    }
  }

  private getLogLevel(logLevel: LogLevel): NgxLoggerLevel {
    switch (logLevel) {
      case LogLevel.Trace:
        return NgxLoggerLevel.TRACE;
      case LogLevel.Debug:
        return NgxLoggerLevel.DEBUG;
      case LogLevel.Info:
        return NgxLoggerLevel.INFO;
      case LogLevel.Log:
        return NgxLoggerLevel.LOG;
      case LogLevel.Log:
        return NgxLoggerLevel.WARN;
      case LogLevel.Error:
        return NgxLoggerLevel.ERROR;
      case LogLevel.Fatal:
        return NgxLoggerLevel.FATAL;
      default:
        return NgxLoggerLevel.OFF;
    }
  }
}
