import { Feedback } from '../models/Feedback';

export class SetFeedbackRating {
  static readonly type = '[Feedback] Set Feedback Rating';
  constructor(public feedback: Feedback) {}
}

export class SendFeedback {
  static readonly type = '[Feedback] Send Feedback';
  constructor(public feedback: Feedback) {}
}

export class SetFirstTimeSubmit {
  static readonly type = '[Feedback] Set FirstTime Submit';
  constructor(public email: string) {}
}

export class SetFirstTimeSubmitSuccess {
  static readonly type = '[Feedback] Set FirstTime Submit Success';
  constructor(public isFirstTimeSubmit: boolean) {}
}
