import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { AccountExecutiveApproveCommand } from 'src/app/models/AccountExecutiveApproveCommand';
import { AddOn } from 'src/app/models/Addon';
import { AppSettings } from 'src/app/models/AppSettings';
import { AppSettingsService } from 'common-web-core';
import { ApproveCommand } from 'src/app/models/ApproveCommand';
import { ApproverEmailUpdateCommand } from 'src/app/models/ApproverEmailUpdateCommand';
import { BaseDataService } from 'src/app/core/services/base.service';
import { CourseCheckTypeOverride } from 'src/app/models/CourseCheckTypeOverride';
import { HttpErrorHandler } from 'src/app/app.error-handler';
import { Injectable } from '@angular/core';
import { Item } from 'src/app/models/Item';
import { Licence } from '../../models/Licence';
import { LoggerService } from 'src/app/logger/services/logger.service';
import { ScheduleSessionCommand } from 'src/app/models/ScheduleSessionCommand';
import { SubmitTrainingRequirementCommand } from 'src/app/models/SubmitTrainingRequirementCommand';
import { TrainingRequirement } from '../../models/TrainingRequirement';

@Injectable()
export class TcaService extends BaseDataService {
  TcasUrl: string;

  constructor(
    http: HttpClient,
    httpErrorHandler: HttpErrorHandler,
    private loggerService: LoggerService,
    appSettingsService: AppSettingsService<AppSettings>) 
  {
    super(http, httpErrorHandler.createHandleError('TcaService'));    
    
    appSettingsService.appSettings$.subscribe((appSettings: AppSettings) => {
      this.TcasUrl = appSettings.webApiSettings.api+'/TrainingRequirement';      
    });

  }

  getIsSubmittedByUserEmail(email: string): Observable<boolean> {
    const url = `${this.TcasUrl}/userHasSubmittedTCA`;
    const params = new HttpParams().set('submittedByEmail', email);

    return this.http
      .get<boolean>(url, { params: params })
      .pipe(
        map(httpResponse => httpResponse),
        catchError(this.handleHttpError<boolean>())
      );
  }

  getTcaPrefilledById(id: string): Observable<TrainingRequirement> {
    const url = `${this.TcasUrl}/${id}/prefilled`;
    const obs = new Subject<TrainingRequirement>();

    this.http.get<TrainingRequirement>(url).subscribe(
      (trainingRequirement: TrainingRequirement) => {
        this.updateTrainingRequirementForUI(trainingRequirement);
        obs.next(trainingRequirement);
      },
      err => {
        catchError(this.handleHttpError<TrainingRequirement>());
      }
    );

    return obs;
  }

  async getCustomerId(id: string): Promise<string> {
    const url = `${this.TcasUrl}/${id}/customerid`;
    return await this.http.get<string>(url).toPromise();
  }

  putTrainingRequirement(
    trainingRequirement: TrainingRequirement,
    submitCommandEnabled: boolean = false
  ): Observable<TrainingRequirement> {
    const tr = JSON.parse(JSON.stringify(trainingRequirement));
    const trainingRequirementToUpload = this.updateAddOnValues(tr);

    if (submitCommandEnabled) {
      const url = `${this.TcasUrl}/${trainingRequirement.id}`;

      const command = this.createSubmitTrainingRequirementCommand(trainingRequirementToUpload);
      return this.http.put<TrainingRequirement>(url, command, this.httpOptions).pipe(
        tap(() => this.loggerService.info(`tca:saveRequirement - ${trainingRequirement.id}`)),
        map((response: TrainingRequirement) => {
          return response;
        }),
        catchError(this.handleHttpError<TrainingRequirement>())
        ); 
    }
  }

  updateTrainingRequirement(
    trainingRequirement: TrainingRequirement,
    submitCommandEnabled: boolean = false
  ): Observable<TrainingRequirement> {
    const tr = JSON.parse(JSON.stringify(trainingRequirement));
    const trainingRequirementToUpload = this.updateAddOnValues(tr);

    if (submitCommandEnabled) {
      const url = `${this.TcasUrl}/${trainingRequirement.id}/SubmitTca`;
      const command = this.createSubmitTrainingRequirementCommand(trainingRequirementToUpload);
      return this.http.post<TrainingRequirement>(url, command, this.httpOptions).pipe(
        tap(() =>
          this.loggerService.info(`tca:updateTrainingRequirement - ${trainingRequirement.id}`)
        ),
        map(() => trainingRequirement),
        catchError(this.handleHttpError<TrainingRequirement>())
      );
    } else {
      const url = `${this.TcasUrl}/${trainingRequirement.id}`;
      return this.http
        .put<TrainingRequirement>(url, trainingRequirementToUpload, this.httpOptions)
        .pipe(
          tap(() =>
            this.loggerService.info(`tca:updateTrainingRequirement - ${trainingRequirement.id}`)
          ),
          map(() => trainingRequirement),
          catchError(this.handleHttpError<TrainingRequirement>())
        );
    }
  }

  private createSubmitTrainingRequirementCommand(
    trainingRequirement: TrainingRequirement
  ): SubmitTrainingRequirementCommand {
    const command = new SubmitTrainingRequirementCommand();

    if (!!trainingRequirement) {
      command.id = trainingRequirement.id;
      command.revisionNumber = trainingRequirement.revisionNumber;
      command.course = trainingRequirement.course;
      command.courseId = trainingRequirement.courseId;
      command.location = trainingRequirement.location;
      command.customerId = trainingRequirement.customerId;
      command.customer = trainingRequirement.customer;
      command.accountExecutive = trainingRequirement.accountExecutive;
      command.clientId = trainingRequirement.clientId;
      command.client = trainingRequirement.client;
      command.aircraftModel = trainingRequirement.aircraftModel;
      command.aircraftId = trainingRequirement.aircraftId;
      command.startDate = trainingRequirement.startDate;
      command.endDate = trainingRequirement.endDate;
      command.trainingType = trainingRequirement.trainingType;
      command.operatingManualA = trainingRequirement.operatingManualA;
      command.operatingManualB = trainingRequirement.operatingManualB;
      command.operatingManualC = trainingRequirement.operatingManualC;
      command.operatingManualD = trainingRequirement.operatingManualD;
      command.seat = trainingRequirement.seat;
      command.phasedTraining = trainingRequirement.phasedTraining;
      command.overrideSeat = trainingRequirement.overrideSeat;
      command.licences = trainingRequirement.licences;
      command.specialAirports = trainingRequirement.specialAirports;
      command.checkingAddons = trainingRequirement.checkingAddons;
      command.trainingAddons = trainingRequirement.trainingAddons;
      command.note = trainingRequirement.note;
      command.submissionStatus = trainingRequirement.submissionStatus;
      command.courseCheckType = trainingRequirement.courseCheckTypeOverride.value;
      if (!!trainingRequirement.courseCheckTypeOverride) {
        command.courseCheckType = trainingRequirement.courseCheckTypeOverride.valueOverride;
      }
      command.submissionDateTime = trainingRequirement.submissionDateTime;
      command.isCustomTcaProvided = trainingRequirement.isCustomTcaProvided;
      command.approvers = trainingRequirement.approvers;
      command.appRights = trainingRequirement.appRights;
      command.userRole = trainingRequirement.userRole;
      command.schedulerReservationId = trainingRequirement.schedulerReservationId;
      command.courseCheckTypesOverride = trainingRequirement.courseCheckTypesOverride.map(
        checkType => {
          const checkTypeOverride = new CourseCheckTypeOverride();
          checkTypeOverride.type = checkType.type;
          checkTypeOverride.value = checkType.value;
          checkTypeOverride.additionalData = checkType.additionalData;
          checkTypeOverride.valueOverride = checkType.valueOverride;
          return checkTypeOverride;
        }
      );
      command.aircraftOperatingCertificate = trainingRequirement.aircraftOperatingCertificate;
      command.trainingProfile = trainingRequirement.trainingProfile;
      command.regulationAgency = trainingRequirement.regulationAgency;
      command.currentDutyPosition = trainingRequirement.currentDutyPosition;
      command.desiredDutyPosition = trainingRequirement.desiredDutyPosition;
      command.overrideDesiredDutyPosition = trainingRequirement.overrideDesiredDutyPosition;
      command.baseMonth = trainingRequirement.baseMonth;
      command.operatingAircraftTypeSerialNumber = trainingRequirement.operatingAircraftTypeSerialNumber;
      command.totalFlightTime = trainingRequirement.totalFlightTime ? trainingRequirement.totalFlightTime.toString() : '';
      command.flightTimeInAircraftType = trainingRequirement.flightTimeInAircraftType ? trainingRequirement.flightTimeInAircraftType.toString() : '';
      command.hasMultiEngineLandandInstrumentRating = trainingRequirement.hasMultiEngineLandandInstrumentRating;
    }

    return command;
  }

  updateAeApproval(
    reservationId: string,
    accountExecutiveApproveCommand: AccountExecutiveApproveCommand
  ) {
    const url = `${this.TcasUrl}/${reservationId}/AccountExecutiveApprove`;

    return this.http
      .post<AccountExecutiveApproveCommand>(url, accountExecutiveApproveCommand, this.httpOptions)
      .pipe(
        map(httpResponse => httpResponse),
        catchError(this.handleHttpError<TrainingRequirement>())
      );
  }

  updateTmScApproval(reservationId: string, approveCommand: ApproveCommand) {
    const url = `${this.TcasUrl}/${reservationId}/Approve`;

    return this.http.post<ApproveCommand>(url, approveCommand, this.httpOptions).pipe(
      map(httpResponse => httpResponse),
      catchError(this.handleHttpError<TrainingRequirement>())
    );
  }

  updateApprovalEmail(reservationId: string, approveCommand: ApproverEmailUpdateCommand) {
    const url = `${this.TcasUrl}/${reservationId}/UpdateApproverEmail`;

    return this.http.post<ApproverEmailUpdateCommand>(url, approveCommand, this.httpOptions).pipe(
      map(httpResponse => httpResponse),
      catchError(this.handleHttpError<TrainingRequirement>())
    );
  }

  addSchedule(reservationId: string, scheduleCommand: ScheduleSessionCommand) {
    const url = `${this.TcasUrl}/${reservationId}/AddSession`;

    return this.http.post<ScheduleSessionCommand>(url, scheduleCommand, this.httpOptions).pipe(
      map(httpResponse => httpResponse),
      catchError(this.handleHttpError<TrainingRequirement>())
    );
  }

  private updateTrainingRequirementForUI(trainingRequirement: TrainingRequirement) {
    trainingRequirement.checkingAddons.forEach((addOn: AddOn) => {
      addOn.active = this.hasActiveOption(addOn);
    });

    trainingRequirement.trainingAddons.forEach((module: AddOn) => {
      module.active = this.hasActiveOption(module);
    });

    this.convertStringToBooleans(trainingRequirement.checkingAddons);
    this.convertStringToBooleans(trainingRequirement.trainingAddons);
    this.convertLicenseType(trainingRequirement.licences);
    this.resetOptionsStateProperty(trainingRequirement.checkingAddons);
    this.resetOptionsStateProperty(trainingRequirement.trainingAddons);

    return trainingRequirement;
  }

  private convertLicenseType(licenses: Licence[]): void {
    if (licenses !== undefined) {
      licenses.forEach(license => {
        if (!license.licenceType) {
          license.licenceType = { name: '', value: '' };
        }
      });
    }
  }

  private hasActiveOption(addOn: AddOn): boolean {
    if (!!addOn.options) {
      return (
        addOn.options.filter(
          module =>
            (!!module.value && this.hasDefaultValue(module)) || !!module.operatingManualReference
        ).length > 0
      );
    }
    return false;
  }

  private updateAddOnValues(trainingRequirement: TrainingRequirement): TrainingRequirement {
    trainingRequirement.checkingAddons = this.removeEmptyAddOns(trainingRequirement.checkingAddons);
    trainingRequirement.trainingAddons = this.removeEmptyAddOns(
      trainingRequirement.trainingAddons
    );

    return trainingRequirement;
  }

  private hasDefaultValue(module: Item): boolean {
    return (
      module.value !== 'none' &&
      module.value !== false &&
      module.value !== '' &&
      module.value !== null &&
      module.value !== 'False'
    );
  }

  private removeEmptyAddOns(addons: Array<AddOn>): Array<AddOn> {
    addons.forEach((addOn: AddOn) => {
      addOn.options = addOn.options.filter((module: Item) => {
        return this.hasDefaultValue(module);
      });
    });

    return addons.filter((addOn: AddOn) => {
      return addOn.options.length > 0;
    });
  }

  private convertStringToBooleans(addons: Array<AddOn>) {
    addons.forEach((addOn: AddOn) => {
      addOn.options.forEach((module: Item) => {
        if (module.value === 'True') {
          module.value = true;
        } else if (module.value === 'False') {
          module.value = false;
        }
      });
    });
  }

  private resetOptionsStateProperty(addons: Array<AddOn>) {
    addons.forEach(addon => {
      addon.options.forEach(option => {
        option.state = null;
      });
    });
  }

  public getAttachments(id: string): Promise<string[]> {
    const url = `${this.TcasUrl}/${id}/attachment`;

    return this.http.get<string[]>(url, this.httpOptions).pipe(
      catchError(this.handleHttpError<string[]>())
    ).toPromise();
  }

  public deleteAttachments(id: string, key: string): Promise<void> {
    const url = `${this.TcasUrl}/${id}/attachment/${key}`;

    return this.http.delete(url, this.httpOptions).pipe(
      catchError(this.handleHttpError<any>())
    ).toPromise();
  }
}
