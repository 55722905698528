import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AuthAuthorities, AuthHelper } from '../../../core/helpers/auth.helper';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { AppSettings } from 'src/app/models/AppSettings';
import { AppSettingsService } from 'common-web-core';
import { AppState } from 'src/app/state/app.state';
import { BasePageComponent } from 'src/app/shared/pages';
import { Store } from '@ngxs/store';
import { ToggleFeatures } from 'src/app/data/toggle-features';

@Component({
  selector: 'app-login-azure-ad-page',
  template: ''
})
export class LoginAzureAdPageComponent extends BasePageComponent implements OnInit, OnDestroy {
  authHelper = new AuthHelper(this.appSettingsService.settings().authAADSettings);
  private isAzureAdEnabled = false;

  constructor(private store: Store,
              private activatedRoute: ActivatedRoute,
              private appSettingsService: AppSettingsService<AppSettings>,) {
    super();
  }

  ngOnInit() {
    super.subscribe(
      this.store
        .select(AppState.isFeatureActive(ToggleFeatures.CustomerWebAppAzureADLogin))
        .subscribe(isEnabled => {
          this.isAzureAdEnabled = isEnabled;
          if (this.isAzureAdEnabled) {
            this.authHelper.currentAuthMode = AuthAuthorities.AzureAd;
            const login_hint = this.activatedRoute.snapshot.queryParams['login_hint'] || '';
            this.authHelper.login(login_hint);
          }
        })
    );
  }

  ngOnDestroy() {
    super.onDestroy();
  }
}
