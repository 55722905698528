import { ApproverComment } from './ApproverComment';

export enum ApproverRole {
  AccountExecutive = 'AE',
  TrainingManager = 'TM',
  Scheduler = 'SC'
}

export enum ApprovalStatus {
  APPROVED = 'Approved',
  PENDING = 'Pending',
  REJECTED = 'Rejected'
}

export class Approver {
  role: ApproverRole;
  comment: string;
  approverCommentLast: ApproverComment;
  comments: ApproverComment[];
  email = '';
  userId = '';
  name = '';
  approvalStatus: ApprovalStatus;
  time: boolean;

  constructor(role: ApproverRole) {
    this.role = role;
    this.approvalStatus = ApprovalStatus.PENDING;
  }
}
