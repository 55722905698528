import * as environment from '../environments/environment';

import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule, routes } from './app-routing.module';
import {
  AppSettingsService,
  AuthB2CService,
  LogService,
  LoggerModule,
  StorageService,
} from 'common-web-core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavigationStart, Router, RouterModule } from '@angular/router';

import { AngularResizedEventModule } from 'angular-resize-event';
import { AppCommonUIModule } from 'common-web-ui';
import { AppComponent } from './app.component';
import { AppLoggerModule } from './logger/app-logger.module';
import { AppSettings } from './models/AppSettings';
import { AppState } from './state/app.state';
import { ApplicationInsightsModule } from '@markpieszak/ng-application-insights';
import { AzureAppConfigurationService } from './services/feature-flags/azure-app-configuration.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from './core/core.module';
import { FeatureFlagService } from './services/feature-flags/feature-flag.service';
import { HttpErrorHandler } from './app.error-handler';
import { LaunchDarklyService } from './services/feature-flags/launch-darkly.service';
import { LoggerService } from './logger/services/logger.service';
import { LoginAzureAdPageComponent } from './pages/login/login-azure-ad-page/login-azure-ad-page.component';
import { LoginPageComponent } from './pages/login/login-page/login-page.component';
import { LoginPageMainComponent } from './pages/login/login-page-main/login-page-main.component';
import { LoginSalesForcePageComponent } from './pages/login/login-salesforce-page/login-salesforce-page.component';
import { LoginSelectionPageComponent } from './pages/login/login-selection-page/login-selection-page.component';
import { MessageService } from './services/message.service';
import { MsAdalAngular6Module } from 'microsoft-adal-angular6';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { SegmentModule } from 'ngx-segment-analytics';
import { SharedModule } from './shared/shared.module';
import { Store } from '@ngxs/store';
import { StoreModule } from './store/store.module';
import { Subscription } from 'rxjs';
import { TcaService } from './services/data-layer/tca.service';
import { ToggleFeatures } from './data/toggle-features';
import { UserInfoService } from './services/data-layer/userinfo.service';
import { UsersService } from './services/data-layer/users.service';

export function initializeAppDependencies(
  appSettingsService: AppSettingsService<AppSettings>,
  authB2cService: AuthB2CService,
  logService: LogService,
  featureFlagService: FeatureFlagService
) {
  return () =>
    appSettingsService.initApplicationSettings().then((appSettings: AppSettings) => {
      return Promise.all([
        authB2cService.initialize(appSettings.b2cSettings),
        logService.initialize(appSettings.loggerSettings),
        featureFlagService.initialize()
      ]);
    });
}
@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    LoginSalesForcePageComponent,
    LoginAzureAdPageComponent,
    LoginPageMainComponent,
    LoginSelectionPageComponent

  ],
  imports: [
    AppCommonUIModule,
    AngularResizedEventModule,
    SharedModule,
    BrowserModule,
    CoreModule.forRoot(),
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule,
    AppLoggerModule,
    ApplicationInsightsModule.forRoot({
      instrumentationKeySetLater: true
    }),
    ScrollToModule.forRoot(),
    SegmentModule.forRoot({
      apiKey: environment.segment.segmentApiKey,
      debug: true,
      loadOnInitialization: true
    }),
    MsAdalAngular6Module,
    LoggerModule
  ],
  providers: [
    AppSettingsService,
    AzureAppConfigurationService,
    LaunchDarklyService,
    FeatureFlagService,
    TcaService,
    UserInfoService,
    UsersService,
    MessageService,
    HttpErrorHandler,
    { provide: ErrorHandler, useClass: HttpErrorHandler },
    { provide: 'Window', useValue: window },
    StorageService,
    AuthB2CService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppDependencies,
      deps: [AppSettingsService, AuthB2CService, LogService, FeatureFlagService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  routerEventsSubscription: Subscription;

  constructor(private router: Router, private store: Store, private loggerService: LoggerService) {
    this.store
      .select(AppState.isFeatureActive(ToggleFeatures.CustomerWebAppMaintenanceMode))
      .subscribe(isMaintenanceActive => {
        if (isMaintenanceActive) {
          this.router.navigate(['/maintenance']);
          this.routerEventsSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
              this.router.navigate(['/maintenance']);
            }
          });
        } else if (!!this.routerEventsSubscription) {
          this.routerEventsSubscription.unsubscribe();
          this.routerEventsSubscription = null;
        }
      });

    this.loggerService.info(`app:version - ${environment.buildNumber}`);
  }
}
