import { AnalyticsBaseProperties } from '../models/AnalyticsBaseProperties';
import { LogAnalyticEventNames } from './app-analytic.state';

export class SendLogAnalyticEvent<T extends AnalyticsBaseProperties> {
  static readonly type = '[CustomerWebApp] Send Log Analytic Event';
  constructor(
    public eventName: LogAnalyticEventNames,
    public eventProperties: T = null
  ) { }
}
