import { trigger, group, transition, animate, style, query } from '@angular/animations';

export const slideInAnimation = trigger('routeAnimations', [
  transition('* => isForm', slideToRight()),
  transition('isForm => *', slideToLeft()),
  transition('* => isPageSelection', slideToRight()),
  transition('isPageSelection => *', slideToLeft())
]);
export function slideToLeft() {
  return [
    query(':enter, :leave', [
      style({
        position: 'relative',
        top: 0,
        left: 0,
        width: '100%'
      })
    ]),
    query(':enter', [style({ left: '-100%' })]),
    group([
      query(':leave', [animate('200ms ease', style({ left: '100%' }))]),
      query(':enter', [animate('200ms ease', style({ left: '0%' }))])
    ])
  ];
}
export function slideToRight() {
  return [
    query(':enter, :leave', [
      style({
        position: 'relative',
        top: 0,
        right: 0,
        width: '100%'
      })
    ]),
    query(':enter', [style({ right: '-100%' })]),
    group([
      query(':leave', [animate('200ms ease', style({ right: '100%' }))]),
      query(':enter', [animate('200ms ease', style({ right: '0%' }))])
    ])
  ];
}
