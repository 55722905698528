import { AnalyticsBaseProperties } from './AnalyticsBaseProperties';

export class AnalyticsRecordManagerProperties extends AnalyticsBaseProperties {
  public contact_id: string;
  public online: boolean;
  public event_type: string;
  public session_id: string;

  constructor() {
    super();
    this.app_name = 'CustomerWebApp';
    this.contact_id = '';
    this.event_type = '';
    this.online = false;
    this.session_id = '';
    this.user_id = '';
    this.user_email = '';
  }
}

export class AnalyticsRecordManagerButtonClickedProperties extends AnalyticsRecordManagerProperties {
  button_id: string;
  button_name: string;
  page_id: string;
  page_name: string;
  assessment_id: string;
  training_record_id: string;

  constructor(init?: Partial<AnalyticsRecordManagerButtonClickedProperties>) {
    super();
    Object.assign(this, init);
  }
}

export class AnalyticsRecordManagerPageProperties extends AnalyticsRecordManagerProperties {
  page_id: string;
  page_name: string;
  assessment_id: string;
  training_record_id: string;
  reservation_number: string;

  constructor(init?: Partial<AnalyticsRecordManagerPageProperties>) {
    super();
    Object.assign(this, init);
  }
}

export class AnalyticsRecordManagerFileDownloadProperties extends AnalyticsRecordManagerProperties {
  file_id: string;
  file_name: string;
  training_record_id: string;
  reservation_number: string;

  constructor(init?: Partial<AnalyticsRecordManagerFileDownloadProperties>) {
    super();
    Object.assign(this, init);
  }
}

export class AnalyticsRecordManagerFileUploadProperties extends AnalyticsRecordManagerProperties {
  file_id: string;
  file_name: string;
  training_record_id: string;
  reservation_number: string;

  constructor(init?: Partial<AnalyticsRecordManagerFileUploadProperties>) {
    super();
    Object.assign(this, init);
  }
}

export class AnalyticsRecordManagerFileDeletedProperties extends AnalyticsRecordManagerProperties {
  file_id: string;
  training_record_id: string;
  reservation_number: string;

  constructor(init?: Partial<AnalyticsRecordManagerFileDeletedProperties>) {
    super();
    Object.assign(this, init);
  }
}

export class AnalyticsRecordManagerElementUpdatedProperties extends AnalyticsRecordManagerProperties {
  initial_value_id: string;
  initial_value_name: string;
  updated_value_id: string;
  updated_value_name: string;
  page_id: string;
  page_name: string;
  training_record_id: string;
  reservation_number: string;

  constructor(init?: Partial<AnalyticsRecordManagerElementUpdatedProperties>) {
    super();
    Object.assign(this, init);
  }
}
