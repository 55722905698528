import {
  ButtonAttractive,
  ButtonIcon,
  ButtonType
} from 'common-web-ui';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BaseUIComponent } from '../base-ui-component';
import { EditMode } from 'src/app/models/EditMode';

@Component({
  selector: 'app-form-card-clear-all-options',
  templateUrl: './form-card-clear-all-options.component.html',
  styleUrls: ['./form-card-clear-all-options.component.less']
})
export class FormCardClearAllOptionsComponent extends BaseUIComponent {
  @Input() disabled = false;
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() clear: EventEmitter<any> = new EventEmitter();

  readonly dialogActionsAttractiveType = ButtonAttractive;

  isShowDialogClear = false;

  readonly buttonType = ButtonType;
  readonly buttonAttractive = ButtonAttractive;
  readonly buttonIcon = ButtonIcon;

  constructor() {
    super();
  }

  onInit() { }
  onDestroy() { }

  onDialogClearCancel() {
    this.cancel.emit();
    this.isShowDialogClear = false;
  }

  onDialogClearOk() {
    this.clear.emit();
    this.isShowDialogClear = false;
  }

  onClear() {
    this.isShowDialogClear = true;
  }
}
