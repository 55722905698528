import { ButtonAttractive, ButtonIcon, ButtonType, DialogBehavior, DialogSize, PanelInfoState } from 'common-web-ui';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { AttachmentService } from 'src/app/services/data-layer/attachment.service';
import { Select, Store } from '@ngxs/store';
import { TrainingRequirement } from 'src/app/models/TrainingRequirement';
import { TrainingRequirementState } from 'src/app/state/trainingRequirement.state';
import { UpdateCustomTcaErrors } from 'src/app/state/trainingRequirement.actions';

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.less']
})
export class DocumentUploadComponent implements OnInit, OnDestroy {
  @Input() readonly: boolean;
  @Input() title: string = "Attachments";
  @Input() fileId: string = "attachment";
  @Input() isCustomTca: boolean = false;
  @Input() isCustomTcaFileMissing: boolean = false;
  currentTrainingRequirement: TrainingRequirement | null;
  @Select(TrainingRequirementState.currentTrainingRequirement)
  currentTrainingRequirement$: Observable<TrainingRequirement>;
  subscription: Subscription;

  isOpen: boolean = true;
  readonly fileSize: number = 15;
  readonly buttonType = ButtonType;
  readonly buttonAttractive = ButtonAttractive;
  readonly buttonIcon = ButtonIcon;
  dialogBehavior = DialogBehavior;
  currentDialogSize = DialogSize;
  isShowDialog = false;
  panelInfoState = PanelInfoState;


  files: { file: File, status: "uploaded" | "pending" }[] = [];
  uploadStatus: boolean = false;
  statusIcon: "../../assets/images/ClearOutline.png" | "../../assets/images/SuccessOutline.png" | "";
  statusMessage: "Upload Successful" | "Upload UnSuccessful" | "" = "";
  invalidFileMessage = "";

  constructor(private attachmentService: AttachmentService) {

  }

  ngOnInit() {
    this.subscription = this.currentTrainingRequirement$.subscribe((trainingRequirement) => {
      if (!this.currentTrainingRequirement) {
        if (trainingRequirement) {
          this.attachmentService.getFiles(trainingRequirement.id, this.isCustomTca).then((files) => {
            this.files = [];

            files.forEach(file => {
              this.files.push({
                status: 'uploaded',
                file
              })
            })
          });
        }
      }

      this.currentTrainingRequirement = trainingRequirement;
    });
  }

  toggleUploadSection() {
    this.isOpen = !this.isOpen;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  selectFile() {
    document.getElementById(this.fileId).click();
  }

  async onFileSelected(event) {

    let file: File = event.target.files[0];

    if (this.files.some(f => f.file.name === file.name)) {
      this.isShowDialog = true;
      this.invalidFileMessage = "You cannot upload multiple files with the same name";
      return;
    }

    if (file.type != 'application/pdf') {
      this.isShowDialog = true;
      this.invalidFileMessage = "You can only upload PDF documents";
      return;
    }

    var filesize = (file.size / 1024) / 1024;

    if (filesize > this.fileSize) {
      this.isShowDialog = true;
      this.invalidFileMessage = `The file size must be less than ${this.fileSize}MB`;
      return;
    }

    this.files.push({ file, status: "pending" });

    event.preventDefault();
  }

  closeDialogClicked() {
    this.isShowDialog = false;
  }

  showUploadButton() {
    return this.files.find(x => x.status == 'pending');
  }

  async uploadFiles() {
    try {
      await this.attachmentService.upload(
        this.currentTrainingRequirement.id,
        this.files.filter(x => x.status == 'pending').map(x => x.file),
        this.isCustomTca
      );
      this.files = this.files.map(x => ({ ...x, status: 'uploaded' }));
      this.statusIcon = '../../assets/images/SuccessOutline.png';
      this.statusMessage = "Upload Successful";
      this.uploadStatus = true;
    }
    catch {
      this.statusIcon = '../../assets/images/ClearOutline.png';
      this.statusMessage = "Upload UnSuccessful";
      this.uploadStatus = true;
    }

    setTimeout(() => {
      this.uploadStatus = false;
    }, 10000);
  }

  onDelete(key: string) {
    this.attachmentService.deleteFile(this.currentTrainingRequirement.id, key, this.isCustomTca).then(() => {
      this.files = this.files.filter(x => x.file.name != key);
    });
  }

  fileSizeKB(size) {
    if (size == 0) {
      return '-';
    }

    if (size < 1000000) {
      return Math.floor(size / 1000) + 'KB';
    } else {
      return Math.floor(size / 1000000) + 'MB';
    }
  }
}
