import { Authority } from "./Authority";

export abstract class AuthorityDescription{
  public readonly authority : Authority;
  public readonly description : string;

  constructor(authority: Authority, description: string){
    this.authority = authority;
    this.description = description;
  }
}
export class EasaAuthorityDescription extends AuthorityDescription{
  constructor(){
    super(Authority.EASA, "EASA");
  }
}

export class UKcaaAuthorityDescription extends AuthorityDescription{
  constructor(){
    super(Authority.UKCAA, "UK CAA");
  }
} 

export class TcAuthorityDescription extends AuthorityDescription{
  constructor(){
    super(Authority.TC, "Transport Canada");
  }
} 

export class FaaAuthorityDescription extends AuthorityDescription{
  constructor(){
    super(Authority.FAA, "FAA");
  }
} 

export class MilitaryAuthorityDescription extends AuthorityDescription{
  constructor(){
    super(Authority.Military, "Military");
  }
} 

export class OtherAuthorityDescription extends AuthorityDescription{
  constructor(){
    super(Authority.Other, "Other");
  }
} 

export class GenericAuthorityDescription extends AuthorityDescription{
  constructor(){
    super(Authority.Generic, "Generic");
  }
} 

export class NoneAuthorityDescription extends AuthorityDescription{
  constructor(){
    super(Authority.None, "");
  }
} 