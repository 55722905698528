import { Component, OnInit, OnDestroy } from '@angular/core';
import { TrainingRequirement } from 'src/app/models/TrainingRequirement';
import { TrainingRequirementState } from 'src/app/state/trainingRequirement.state';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { RegulationAgencyName } from 'src/app/models/RegulationAgencyName';
import { BaseUIComponent } from '../base-ui-component';
import { BasePageComponent } from '../../pages';
import { HeapService } from 'src/app/core/services/heap.service';

@Component({
  selector: 'app-customer-information',
  templateUrl: './customer-information.component.html',
  styleUrls: ['./customer-information.component.less']
})
export class CustomerInformationComponent extends BasePageComponent implements OnInit, OnDestroy {
  @Select(TrainingRequirementState.currentTrainingRequirement)
  currentTrainingRequirement$: Observable<TrainingRequirement>;

  regulationName: string;

  regulationAgencyName = RegulationAgencyName.FAA;

  constructor(private heapService: HeapService) {
    super();
  }

  isIE: boolean;
  ngOnInit() {
    this.isIE = this.isBrowserIE();

    super.subscribe(
      this.currentTrainingRequirement$.subscribe(ctr => {
        this.regulationName = ctr.regulationAgency;
        this.heapService.logReservationId(ctr.schedulerReservationId);
      })
    );
  }

  ngOnDestroy(): void {
    super.onDestroy();
  }

  isBrowserIE() {
    const match = navigator.userAgent.search(/(?:MSIE|Trident\/.*; rv:)/);
    let isIE = false;

    if (match !== -1) {
      isIE = true;
    }

    return isIE;
  }
}
