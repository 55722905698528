import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  SendFeedback,
  SetFeedbackRating,
  SetFirstTimeSubmit,
  SetFirstTimeSubmitSuccess
} from './feedback.state.action';

import { Feedback } from '../models/Feedback';
import { FeedbackService } from '../services/data-layer/feedback-service.service';
import { Injectable } from '@angular/core';
import { TcaService } from '../services/data-layer/tca.service';
import { UpdateFeedbackSent } from './app.state.action';

export interface FeedbackModel {
  feedback: Feedback;
  isValid: boolean;
  isFirstTimeSubmit: boolean;
}

@State<FeedbackModel>({
  name: 'feedbackRating',
  defaults: {
    feedback: new Feedback(),
    isValid: null,
    isFirstTimeSubmit: null
  }
})

@Injectable()
export class FeedbackState {
  constructor(
    private feedbackService: FeedbackService,
    private trainingRequirementService: TcaService
  ) { }

  @Selector()
  static feedback(state: FeedbackModel) {
    return state.feedback;
  }

  @Selector()
  static isValid(state: FeedbackModel) {
    return state.isValid;
  }

  @Selector()
  static isFirstTimeSubmit(state: FeedbackModel) {
    return state.isFirstTimeSubmit;
  }

  @Action(SetFirstTimeSubmit)
  setFirstTimeSubmit({ dispatch }: StateContext<FeedbackModel>, action: SetFirstTimeSubmit) {
    this.trainingRequirementService
      .getIsSubmittedByUserEmail(action.email)
      .subscribe((response: boolean) => {
        dispatch(new SetFirstTimeSubmitSuccess(!response));
      });
  }

  @Action(SetFirstTimeSubmitSuccess)
  setFirstTimeSubmitSuccess(
    { patchState }: StateContext<FeedbackModel>,
    action: SetFirstTimeSubmitSuccess
  ) {
    patchState({
      isFirstTimeSubmit: action.isFirstTimeSubmit
    });
  }

  @Action(SetFeedbackRating)
  setFeedbackRating({ patchState }: StateContext<FeedbackModel>, action: SetFeedbackRating) {
    patchState({
      feedback: action.feedback
    });
  }  

  @Action(SendFeedback)
  sendFeedback({ dispatch }: StateContext<FeedbackModel>, action: SendFeedback) {
    dispatch(new SetFeedbackRating(action.feedback));
    dispatch(new UpdateFeedbackSent(true));
    this.feedbackService.sendFeedbackInfo(action.feedback).subscribe();
  }
  
}
