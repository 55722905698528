import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum FormState {
  DefaultValues = 'Default',
  ModifiedValues = 'Modified'
}

@Injectable({
  providedIn: 'root'
})
export class FormChangesService {
  private formChangesSubject: Subject<FormState> = new Subject();
  formStateChanged$ = this.formChangesSubject.asObservable();

  constructor() {}

  public setFormChangeState(state: FormState): void {
    this.formChangesSubject.next(state);
  }
}
