<daui-dialog [isShow]="isVisible" [size]="size" [behavior]="behavior">
  <div class="presets-dialog">

    <div class="close-section">
      <div class="help-button" (click)="openHelpDialog()">
        <div class="mark">?</div>
      </div>
      <div class="icon close" (click)="closeDialog()"></div>
    </div>

    <h1>Saved Presets</h1>

    <div class="preset-filter-container">

      <div class="w-100">
        <daui-form-field label="">
          <ng-container content>
            <p>Filter Saved Presets:</p>
            <daui-segmented-group class="item" [selectedIndex]="selectedIndex" (selectionChanged)="
                          selectedFilterChanged($event)
                        ">
              <daui-segmented class="filter-button" label="For this pilot"></daui-segmented>
              <daui-segmented class="filter-button" label="Customer Presets"></daui-segmented>
              <daui-segmented class="filter-button" label="All"></daui-segmented>
            </daui-segmented-group>
          </ng-container>
        </daui-form-field>
      </div>


      <daui-search-input [text]="search" (clear)="onClear()" (keyup)="onSearchInputKeyUp($event)"
        (textChange)="onSearch($event)" class="search-input" placeholder="Search"></daui-search-input>


    </div>

    <div class="preset-results">
      <table class="presets-table">
        <thead>
          <tr>
            <th></th>
            <th *ngFor="let c of columnDefinition">{{c.label}}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let preset of filteredPresets | paginate: { itemsPerPage: 6, currentPage: page }">
            <tr (click)="selectPreset(preset); ">
              <td class="">
                <daui-button *ngIf="openPresetId != preset.id" [attractive]="attractive.Regular"
                  [icon]="buttonIcon.Next" [type]="buttonType.Flat"
                  (click)="openDetails(preset); $event.stopPropagation()"></daui-button>
                <daui-button *ngIf="openPresetId == preset.id" [attractive]="attractive.Regular"
                  [icon]="buttonIcon.Collapse" [type]="buttonType.Flat"
                  (click)="closeDetails(); $event.stopPropagation()"></daui-button>
              </td>
              <td *ngFor="let c of columnDefinition">
                <div [ngStyle]="{'display': 'flex', 'justify-content': c.justify }"> {{ c.format ?
                  c.format(preset[c.id])
                  : preset[c.id]}}</div>

              </td>
              <td class="delete-action">
                <daui-button [attractive]="attractive.Heavy" [icon]="buttonIcon.Delete" [type]="buttonType.Flat"
                  (click)="deleteIconClicked(preset); $event.stopPropagation()"></daui-button>
              </td>
            </tr>
            <tr class="preset-details" *ngIf="preset.id == openPresetId">
              <td [attr.colSpan]="columnDefinition.length + 2">
                <app-preset-training-options-item-summary [item]="preset">
                </app-preset-training-options-item-summary>
              </td>

            </tr>
          </ng-container>



        </tbody>
      </table>

    </div>
    <div class="pagination">
      <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="page = $event"></pagination-controls>
    </div>


  </div>
</daui-dialog>