import { ButtonAttractive, ButtonIcon, ButtonType } from 'common-web-ui';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BaseUIComponent } from '../base-ui-component';
import { EditMode } from 'src/app/models/EditMode';
import { FormCardActionsLayout } from '../form-card-actions/form-card-actions.component';

@Component({
  selector: 'app-form-card-delete',
  templateUrl: './form-card-delete.component.html',
  styleUrls: ['./form-card-delete.component.less']
})
export class FormCardDeleteComponent extends BaseUIComponent {
  @Input() mode = EditMode.Edit;
  @Input() layout: FormCardActionsLayout = 'save';
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();

  public editMode = EditMode;
  isShowDialogDelete: boolean;
  isShowDialogSaveDelete: boolean;

  readonly buttonType = ButtonType;
  readonly buttonAttractive = ButtonAttractive;
  readonly buttonIcon = ButtonIcon;

  constructor() {
    super();
  }

  onInit() { }
  onDestroy() { }

  onDialogDeleteCancel() {
    this.cancel.emit();
    this.isShowDialogDelete = false;
    this.isShowDialogSaveDelete = false;
  }

  onDialogDeleteOk() {
    this.delete.emit();
    this.isShowDialogDelete = false;
    this.isShowDialogSaveDelete = false;
  }

  onDelete() {
    this.isShowDialogDelete = true;
  }
}
