import { LicenseCategory } from '../data/licenseCategory';
import { Authority } from './Authority';

export class Licence {
  id: string;
  certificateNumber: string;
  expiryDate: string;
  lastTCDate: string;
  licenceCategoryType: LicenseCategory = LicenseCategory.Existing;
  licenceType: {
    name: string;
    value: string;
  };
  country: string;
  isPerformanceBasedNavigation: boolean;
  authority: Authority;
  hasInitialTraining: boolean;
  completedAdvancedUPRT: boolean | null = null;

  constructor() {
    this.licenceType = { name: '', value: '' };
  }
}
