import * as _ from 'lodash';

import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, Oauth } from 'src/app/core/services';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { AppState } from 'src/app/state/app.state';
import { AuthAuthorities } from '../../../core/helpers/auth.helper';
import { BasePageComponent } from 'src/app/shared/pages';
import { HeaderService } from 'src/app/core/services/header.service';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { ToggleFeatures } from 'src/app/data/toggle-features';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.less']
})
export class LoginPageComponent extends BasePageComponent implements OnInit, OnDestroy {
  salesForceOauth: Oauth;
  returnUrl: string;
  subscription: Subscription;
  isAuthenticated = false;
  isAzureADEnabled = false;
  isAzureB2CEnabled = true;
  isAzureToSalesforce = false;
  @Input() emailValue: string;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private store: Store,
    private headerService: HeaderService,

  ) {
    super();
    this.headerService.hideHeader();
  }

  ngOnInit() {
    super.subscribe(
      this.store
        .select(AppState.isFeatureActive(ToggleFeatures.CustomerWebAppAzureADLogin))
        .subscribe(isEnabled => {
          this.isAzureADEnabled = isEnabled;
        })
    );

    super.subscribe(
      this.store
        .select(AppState.isFeatureActive(ToggleFeatures.CustomerWebAppAzureB2CLogin))
        .subscribe(isEnabled => {
          this.isAzureB2CEnabled = isEnabled;
        })
    );

    super.subscribe(
      this.store
        .select(AppState.isFeatureActive(ToggleFeatures.CustomerWebAppAzureADToSalesforceLogin))
        .subscribe(isEnabled => {
          this.isAzureToSalesforce = isEnabled;
        })
    );

    super.subscribe(
      this.route.fragment
        .pipe(
          map(fragment => new URLSearchParams(fragment)),
          map(params => ({
            access_token: params.get('access_token'),
            id_token: params.get('refresh_token'),
            instance_url: params.get('instance_url'),
            error: params.get('error'),
            aad_id_token: params.get('id_token')
          }))
        )
        .subscribe(res => {
          if (res.access_token) {
            this.authService.authHelper.currentAuthMode = AuthAuthorities.SalesForce;
            this.salesForceOauth = this.authService.getNewOauth();
            this.salesForceOauth.accessToken = res.access_token;
            this.salesForceOauth.refreshToken = res.id_token;
            this.salesForceOauth.instanceURL = res.instance_url;
            this.salesForceOauth.issuedAt = new Date().getTime();
            this.authService.setOauth(this.salesForceOauth);
          } else if (res.aad_id_token) {
            this.authService.authHelper.currentAuthMode = AuthAuthorities.AzureAd;
            this.authService.authHelper.handleCallback();
          }
        })
    );

    this.isAuthenticated = this.authService.isAuthenticated();

    if (this.isAuthenticated) {
      const retUrl = this.authService.getReturnUrl();
      if (retUrl) {
        this.router.navigate([retUrl]).then((result) => {
          if (!result) {
            // Retry navigation
            // Navigation here can fail when AuthGard is checking
            this.router.navigate([retUrl]);
          }
        });

      } else {
        this.router.navigate(['/tca']);
      }
    } else {
      super.subscribe(
        this.store
          .select(AppState.isFeatureActive(ToggleFeatures.CustomerWebAppAzureADLogin))
          .subscribe(isEnabled => {
            if (isEnabled) {
              this.router.navigate(['/login']);
            } else {
              this.router.navigate(['/login-salesforce']);
            }
          })
      );
    }

    return;
  }

  ngOnDestroy() {
    super.onDestroy();
  }
}
