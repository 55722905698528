import { HttpClient, HttpParams } from '@angular/common/http';

import { AppSettings } from 'src/app/models/AppSettings';
import { AppSettingsService } from 'common-web-core';
import { BaseDataService } from 'src/app/core/services/base.service';
import { HttpErrorHandler } from 'src/app/app.error-handler';
import { Injectable } from '@angular/core';
import { Preset } from 'src/app/models/Preset';
import { PresetSummary } from 'src/app/models/PresetSummary';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomerService extends BaseDataService {
  constructor(http: HttpClient, 
              httpErrorHandler: HttpErrorHandler,
              appSettingsService: AppSettingsService<AppSettings>) {
    super(http, httpErrorHandler.createHandleError('CustomerService'));

    appSettingsService.appSettings$.subscribe((appSettings: AppSettings) => {      
      this.resourceUrl = appSettings.webApiSettings.api + '/customer';      
    });

  }

  getPresets(CustomerId: string, clientId: string, authority: string) {
    const url = `${this.resourceUrl}/${CustomerId}/presets`;
    let params = new HttpParams();
    params = params.append('clientId', clientId);
    params = params.append('authority', authority);

    return this.http
      .get<Preset[]>(url, { params: params })
      .pipe(catchError(this.handleHttpError<Array<Preset>>()));
  }

  getPresetsSummary(CustomerId: string, clientId: string, authority: string) {
    const url = `${this.resourceUrl}/${CustomerId}/presets/summary`;
    let params = new HttpParams();
    params = params.append('clientId', clientId);
    params = params.append('authority', authority);

    return this.http
      .get<PresetSummary[]>(url, { params: params })
      .pipe(catchError(this.handleHttpError<Array<PresetSummary>>()));
  }

  getPreset(CustomerId: string, presetId: string) {
    const url = `${this.resourceUrl}/${CustomerId}/presets/${presetId}`;

    return this.http.get<Preset>(url).pipe(catchError(this.handleHttpError<Preset>()));
  }

  addPreset(CustomerId: string, preset: Preset) {
    const url = `${this.resourceUrl}/${CustomerId}/presets`;

    return this.http
      .post<Preset[]>(url, preset, this.httpOptions)
      .pipe(catchError(this.handleHttpError<Array<Preset>>()));
  }

  deletePreset(CustomerId: string, presetId: string) {
    const url = `${this.resourceUrl}/${CustomerId}/presets/${presetId}`;

    return this.http
      .delete<Preset[]>(url, this.httpOptions)
      .pipe(catchError(this.handleHttpError<Array<Preset>>()));
  }
}
