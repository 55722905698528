import { CheckTypeOption } from './CheckTypeOption';

export class CourseCheckTypeOverride {
  checkTypeOptions = new Array<CheckTypeOption>();
  value: string;
  valueOverride: string;
  type: string;
  additionalData: { [key: string]: string };

  public CourseCheckTypeOverride() {}
}
