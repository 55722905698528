import { Preset } from '../models/Preset';

export class GetPresets {
  static readonly type = '[Preset] Get Presets';
  constructor(public customerId: string, public clientId: string, public authority: string) { }
}

export class GetPresetsSummary {
  static readonly type = '[Preset] Get Presets Summary';
  constructor(public customerId: string, public clientId: string, public authority: string) { }
}

export class GetPreset {
  static readonly type = '[Preset] Get Preset';
  constructor(public customerId: string, public presetId: string) { }
}

export class AddPreset {
  static readonly type = '[Preset] Add Preset';
  constructor(public customerId: string, public preset: Preset) { }
}

export class DeletePreset {
  static readonly type = '[Preset] Delete Preset';
  constructor(public customerId: string, public presetId: string) { }
}
