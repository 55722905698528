import { BehaviorSubject, Observable } from 'rxjs';

import { Injectable } from '@angular/core';

export enum HeaderMode {
  Visible,
  Hidden
}

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private headerModeSubject = new BehaviorSubject<HeaderMode>(
    HeaderMode.Hidden
  );

  get headerMode$(): Observable<HeaderMode> {
    return this.headerModeSubject.asObservable();
  }

  hideHeader(): void {
    this.headerModeSubject.next(HeaderMode.Hidden);
  }

  showHeader(): void {
    this.headerModeSubject.next(HeaderMode.Visible);
  }
}
