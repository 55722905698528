<div
  class="wrapper"
  [ngClass]="{
    mobileDefault: !isMobileSubpage,
    mobileSubpage: isMobileSubpage
  }"
>
  <div class="content">
    <ng-content></ng-content>
    <app-form-footer></app-form-footer>
  </div>
</div>
