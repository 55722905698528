import { Action, Selector, State, StateContext, createSelector } from '@ngxs/store';
import {
  GetAirports,
  GetCountries,
  UpdateFeature,
  UpdateFeatures,
  UpdateFeedbackSent
} from './app.state.action';

import { Airport } from '../models/Airport';
import { AirportsService } from '../services/data-layer/airports.service';
import { AuthService } from '../core/services';
import { CountriesService } from '../services/data-layer/countries.service';
import { Country } from '../models/Country';
import { Feature } from '../models/Feature';
import { Injectable } from '@angular/core';

export interface AppStateModel {
  features: Array<Feature>;
  isFeedbackSent: boolean;
  airports: Array<Airport>;
  countries: Array<Country>;
}

@State<AppStateModel>({
  name: 'app',
  defaults: {
    features: [],
    isFeedbackSent: false,
    airports: [],
    countries: []
  }
})
@Injectable()
export class AppState {
  constructor(
    private authService: AuthService,
    private airportsService: AirportsService,
    private countriesService: CountriesService
  ) { }

  @Selector()
  static isFeatureActive(featureName: string) {
    return createSelector(
      [AppState],
      (state: AppState) => {
        const appState = state['app'];
        if (!appState || !appState.features) {
          return null;
        }
        const feature = appState.features.find(f => f.name === featureName);
        if (!!feature) {
          return feature.isActive;
        }
        return false;
      }
    );
  }

  @Selector()
  static feedbackSent(state: AppStateModel) {
    return state.isFeedbackSent;
  }

  @Selector()
  static airports(state: AppStateModel) {
    return state.airports;
  }

  @Selector()
  static countries(state: AppStateModel) {
    return state.countries;
  }

  @Action(UpdateFeatures)
  updateFeatures({ patchState, getState }: StateContext<AppStateModel>, action: UpdateFeatures) {
    const currentFeatures = getState().features.splice(0);
    action.features.forEach(feature => {
      let currentFeature = currentFeatures.find(f => f.name === feature.name);
      if (!currentFeature) {
        currentFeature = new Feature(feature.name, feature.isActive);
        currentFeatures.push(currentFeature);
      } else {
        currentFeature.isActive = feature.isActive;
      }
    });
    patchState({
      features: currentFeatures
    });
  }

  @Action(UpdateFeature)
  updateFeature({ patchState, getState }: StateContext<AppStateModel>, action: UpdateFeature) {
    const currentFeatures = getState().features.splice(0);
    const feature = currentFeatures.find(f => f.name === action.feature.name);
    if (!!feature) {
      feature.isActive = action.feature.isActive;
    } else {
      currentFeatures.push(action.feature);
    }
    patchState({
      features: currentFeatures
    });
  }

  @Action(UpdateFeedbackSent)
  updateFeedbackSent({ patchState }: StateContext<AppStateModel>, action: UpdateFeedbackSent) {
    patchState({
      isFeedbackSent: action.isFeedbackSent
    });
  }

  @Action(GetAirports)
  getAirports({ getState, patchState }: StateContext<AppStateModel>, action: GetAirports) {
    if (!!getState() && getState().airports.length !== 0) {
      return;
    }

    if (!this.authService.isTokenExpired()) {
      this.airportsService.getAirports().subscribe(airports => {
        patchState({
          airports: airports
        });
      });
    }
  }

  @Action(GetCountries)
  getCountries({ getState, patchState }: StateContext<AppStateModel>, action: GetCountries) {
    if (!!getState() && getState().countries.length !== 0) {
      return;
    }

    if (!this.authService.isTokenExpired()) {
      this.countriesService.getCountries(action.authority).subscribe(countries => {
        patchState({
          countries: countries
        });
      });
    }
  }
}
