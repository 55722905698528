const EMAIL_VALIDATOR_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function validateEmail(email: string): boolean {
  return validatorRegEx(EMAIL_VALIDATOR_REGEX, email);
}

function validatorRegEx(expression: RegExp, value: any): boolean {
  let isValid = true;

  if (!!expression && !!value) {
    const valueString = <string>value;
    if (!!valueString) {
      isValid = expression.test(valueString.toLowerCase());
    }
  }

  return isValid;
}
