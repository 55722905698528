export class ApproverEmailUpdateCommand {
  Name: string;
  Email: string;
  UserId: string;
  Role: string;
  RevisionNumber: number;

  constructor() {
    this.Name = '';
    this.Email = '';
    this.UserId = '';
    this.Role = '';
    this.RevisionNumber = 0;
  }
}
