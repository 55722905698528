import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngxs/store';
import { ButtonAttractive, ButtonIcon, ButtonType, DialogBehavior, DialogSize } from 'common-web-ui';
import { Authority } from 'src/app/models/Authority';
import { PresetSummary } from 'src/app/models/PresetSummary';
import { PresetType } from 'src/app/models/PresetType';
import { TrainingRequirement } from 'src/app/models/TrainingRequirement';
@Component({
  selector: 'app-presets-dialog',
  templateUrl: './presets-dialog.component.html',
  styleUrls: ['./presets-dialog.component.less']
})
export class PresetsDialogComponent implements OnInit, OnChanges {

  @Input() currentTrainingRequirement: TrainingRequirement;

  @Input() authority: Authority;

  @Input() isVisible: boolean = false;

  @Input() isDeleteVisible: boolean = false;

  @Input() presets: PresetSummary[] = [];

  @Input() clientId: string;

  @Output() onClose = new EventEmitter();
  @Output() onPresetSelected = new EventEmitter<PresetSummary>();
  @Output() onDeletePresetSelected = new EventEmitter<PresetSummary>();
  @Output() onDeleteChange = new EventEmitter();
  @Output() onHelpSelected = new EventEmitter();

  filteredPresets: PresetSummary[] = [];



  /**
   * The id of the preset that is currently open
   */
  openPresetId: string;

  search: string;

  columnDefinition: { id: string; label: string; format?: (value: any) => string; justify?: 'start' | 'center' | 'end' }[];

  behavior = DialogBehavior.Regular;

  size = DialogSize.Large;

  page = 1;

  selectedIndex = 2;

  buttonType = ButtonType;
  attractive = ButtonAttractive;
  buttonIcon = ButtonIcon;

  constructor(private datePipe: DatePipe, private store: Store) {
    this.columnDefinition = [{ label: 'Preset Name', id: 'title', justify: 'start' }, { label: 'Pilot Name', id: 'clientName', justify: 'center', format: this.formatClientName }, { label: 'Date', id: 'dateCreated', format: this.formatDate, justify: 'center' }];

  }
  ngOnChanges(changes: SimpleChanges): void {

    if (changes.presets && changes.presets.currentValue) {
      this.selectedFilterChanged(this.selectedIndex);
    }
  }

  ngOnInit() {
  }

  openDetails(preset: PresetSummary) {
    this.openPresetId = preset.id;
  }

  closeDetails() {
    this.openPresetId = "";
  }

  closeDialog() {
    this.search = '';
    this.selectedIndex = 2;
    this.page = 1;
    this.onClose.emit();
  }

  formatDate = (val) => {
    return this.datePipe.transform(val, 'dd MMM yyyy');
  }

  formatClientName = (val) => {
    return val ? val : "N/A (Customer Preset)";
  }

  selectPreset(preset: PresetSummary) {
    this.onPresetSelected.emit(preset);
  }

  onSearchInputKeyUp(event: any) {


    this.onSearch(event.target.value.trim().toLowerCase());
  }

  onSearch(text: string) {
    this.search = text.toLowerCase();
    this.selectedFilterChanged(this.selectedIndex);
  }

  onClear() {
    this.search = '';
    this.page = 1;
    this.selectedFilterChanged(this.selectedIndex);
  }

  openHelpDialog() {
    this.onHelpSelected.emit();
  }

  selectedFilterChanged(event: number) {

    this.openPresetId = "";
    switch (event) {
      case 0:
      case 2:
        {
          this.columnDefinition = [{ label: 'Preset Name', id: 'title', justify: 'start' }, { label: 'Pilot Name', id: 'clientName', justify: 'center', format: this.formatClientName }, { label: 'Date', id: 'dateCreated', format: this.formatDate, justify: 'center' }];

          if (event == 0) {
            this.filteredPresets = this.presets.filter(x => x.clientId == this.clientId && x.presetType == PresetType.Private);
          }
          if (event == 2) {
            this.filteredPresets = this.presets;
          }
        }
        break;
      case 1:
        {
          this.columnDefinition = [{ label: 'Preset Name', id: 'title', justify: 'start' }, { label: 'Date', id: 'dateCreated', format: this.formatDate, justify: 'center' }];
          this.filteredPresets = this.presets.filter(x => (x.presetType as PresetType) == PresetType.Global);
        }
        break;
    }

    this.selectedIndex = event;

    if (this.search && this.search.length > 1) {
      this.page = 1;
      this.filteredPresets = this.filteredPresets.filter(x => x.title.toLowerCase().includes(this.search) || (x.clientName && x.clientName.toLowerCase().includes(this.search)));
    }

  }

  deleteIconClicked(preset: PresetSummary) {
    this.onDeletePresetSelected.emit(preset);
    this.onDeleteChange.emit();
  }

}
