import { SpecialAirport, SpecialAirportOption } from './SpecialAirport';
import { AddOn } from './Addon';
import { PresetType } from './PresetType';
import { Licence } from './Licence';

export class Preset {
  id: string;
  title: string;
  description: string;
  lastUsed: Date;
  autosaved: boolean;
  presetType: PresetType | number;
  aircraftId: string;
  courseId: string;
  clientId: string;
  clientName?: string;
  licences: Licence[];
  authority: string;
  checkingAddons: AddOn[];
  trainingAddons: AddOn[];
  specialAirports: SpecialAirport[];
  note: string;

  constructor() {
    this.id = '';
    this.title = '';
    this.description = '';
    this.lastUsed = new Date();
    this.autosaved = false;
    this.presetType = PresetType.Global;
    this.aircraftId = '';
    this.courseId = '';
    this.clientId = '';
    this.authority = '';
    this.clientName = null;
    this.licences = [];
    this.checkingAddons = new Array<AddOn>();
    this.trainingAddons = new Array<AddOn>();
    this.specialAirports = new Array<SpecialAirport>();
    this.note = '';
  }
}
