import * as environment from '../../environments/environment';

import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { PresetState, PresetSummaryState, PresetsState } from '../state/preset.state';

import { AppAnalyticState } from '../state/app-analytic.state';
import { AppState } from '../state/app.state';
import { AppStoreKeys } from './store-keys.enum';
import { ApprovalState } from '../tca/approval/store';
import { CommonModule } from '@angular/common';
import { FeedbackState } from '../state/feedback.state';
import { LoginState } from '../state/login.state';
import { NgModule } from '@angular/core';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { TrainingRequirementState } from '../state/trainingRequirement.state';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forRoot(
      [
        LoginState,
        PresetsState,
        PresetSummaryState,
        PresetState,
        AppAnalyticState,
        TrainingRequirementState,
        AppState,
        FeedbackState,
        ApprovalState
      ] // ],
      // { developmentMode: !environment.production }
    ),
    NgxsStoragePluginModule.forRoot({
      storage: StorageOption.SessionStorage,
      key: [AppStoreKeys.App, AppStoreKeys.TrainingRequirement, AppStoreKeys.Approval]
    }),
    NgxsLoggerPluginModule.forRoot({ disabled: environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production })
  ],
  exports: [NgxsModule]
})
export class StoreModule { }
