import { Component } from '@angular/core';

import { BaseUIComponent } from '../base-ui-component';

@Component({
  selector: 'app-form-card',
  templateUrl: './form-card.component.html',
  styleUrls: ['./form-card.component.less']
})
export class FormCardComponent extends BaseUIComponent {
  constructor() {
    super();
  }

  onInit() {}
  onDestroy() {}
}
