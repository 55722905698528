import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AddPreset, DeletePreset, GetPreset, GetPresets, GetPresetsSummary } from './preset.actions';

import { CustomerService } from '../services/data-layer/customer.service';
import { Injectable } from '@angular/core';
import { Preset } from '../models/Preset';
import { PresetSummary } from '../models/PresetSummary';
import { PresetType } from '../models/PresetType';
import { tap } from 'rxjs/operators';

export interface PresetsStateModel {
  presets: Preset[];
}

export interface PresetSummaryStateModel {
  presetsSummary: PresetSummary[];
}

export interface PresetStateModel {
  preset: Preset;
}

@State<PresetsStateModel>({
  name: 'presets',
  defaults: {
    presets: []
  }
})
@Injectable()
export class PresetsState {
  constructor(private customerService: CustomerService) { }

  @Selector()
  static getPresetList(state: PresetsStateModel): Preset[] | null {
    return !!state ? state.presets : null;
  }

  @Action(GetPresets)
  GetPresets({ getState, setState }: StateContext<PresetsStateModel>, action: GetPresets) {
    return this.customerService
      .getPresets(action.customerId, action.clientId, action.authority)
      .pipe(
        tap(result => {
          const state = getState();
          setState({
            ...state,
            presets: result
          });
        })
      );
  }
}

@State<PresetSummaryStateModel>({
  name: 'presetSummary',
  defaults: {
    presetsSummary: []
  }
})
@Injectable()
export class PresetSummaryState {
  constructor(private customerService: CustomerService) { }

  @Selector()
  static getPresetSummaryList(state: PresetSummaryStateModel): PresetSummary[] | null {
    return !!state ? state.presetsSummary : null;
  }

  @Action(GetPresetsSummary)
  GetPresetsSummary(
    { getState, setState }: StateContext<PresetSummaryStateModel>,
    action: GetPresetsSummary
  ) {
    return this.customerService
      .getPresetsSummary(action.customerId, action.clientId, action.authority)
      .pipe(
        tap(result => {
          const processedResult = result
            .map(item => ({
              ...item,
              title: item.autosaved ? `${item.title} (autosaved)` : item.title
            }))
            .sort((a, b) => Number(a.autosaved) - Number(b.autosaved));

          const state = getState();
          setState({
            ...state,
            presetsSummary: processedResult
          });
        })
      );
  }
}

@State<PresetStateModel>({
  name: 'preset',
  defaults: {
    preset: null
  }
})
@Injectable()
export class PresetState {
  constructor(private customerService: CustomerService) { }

  @Selector()
  static getPreset(state: PresetStateModel): Preset | null {
    return !!state ? state.preset : null;
  }

  @Selector()
  static addPreset(state: PresetsStateModel): Preset[] | null {
    return !!state ? state.presets : null;
  }

  @Selector()
  static deletePreset(state: PresetsStateModel): Preset[] | null {
    return !!state ? state.presets : null;
  }

  @Action(GetPreset)
  GetPreset({ getState, setState }: StateContext<PresetStateModel>, action: GetPreset) {
    return this.customerService.getPreset(action.customerId, action.presetId).pipe(
      tap(result => {
        const state = getState();
        setState({
          ...state,
          preset: { ...result, presetType: result.presetType == 0 ? PresetType.Private : PresetType.Global }
        });
      })
    );
  }

  @Action(AddPreset)
  AddPreset({ getState, setState }: StateContext<PresetsStateModel>, action: AddPreset) {
    return this.customerService.addPreset(action.customerId, action.preset).pipe(
      tap(result => {
        const state = getState();
        setState({
          ...state,
          presets: result
        });
      })
    );
  }

  @Action(DeletePreset)
  deletePreset({ getState, setState }: StateContext<PresetsStateModel>, action: DeletePreset) {
    return this.customerService.deletePreset(action.customerId, action.presetId).pipe(
      tap(result => {
        const state = getState();
        setState({
          ...state,
          presets: result
        });
      })
    );
  }
}
