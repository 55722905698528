<div class="wrapper">
  <h2 *ngIf="!isFaaRegulated()">Licenses</h2>
  <h2 *ngIf="isFaaRegulated()">FAA Certificate</h2>
  <div class="wrapper" *ngIf="licences.length > 0">
    <div class="flat attractive edit" *ngFor="let item of licences; index as i">
      <div class="license">
        <app-form-card-delete class="action" (delete)="onDelete(item.authority)"></app-form-card-delete>

        <app-form-field-list-item (click)="goToLicenceEdit(item.certificateNumber, item.authority)"
          [label]="getLabel(item)" [mode]="formFieldListItemMode.Navigation" [state]="getState(item)"
          [informations]="informations" class="license-detail" [readonly]="readonly">
          
          <div class="license-detail-property" *ngIf="item.licenceCategoryType?.toLowerCase() === licenceTypeCategory.Future.toLowerCase()">
              <span class="property-title">Future Licence</span>
          </div>
          <div *ngIf="item.licenceCategoryType?.toLowerCase() !== licenceTypeCategory.Future.toLowerCase()">
           <div class="license-detail-property"
            *ngIf="item.authority?.toLowerCase() !== authority.FAA && item.authority?.toLowerCase() !== authority.TC">
            <span class="property-title">Issuing Country</span> -
            <span *ngIf="!!item.country">{{ item.country | countryFormat }}</span>
            <span *ngIf="!item.country">Not provided</span>
          </div>
          <div class="license-detail-property">
            <span class="property-title">{{
              item.authority?.toLowerCase() === authority.FAA ? 'Certificate' : 'License'
              }}
              Number</span>
            -
            <span *ngIf="!!item.certificateNumber">{{ item.certificateNumber }}</span>
            <span *ngIf="!item.certificateNumber">Not provided</span>
          </div>
          <div class="license-detail-property" *ngIf="item.authority?.toLowerCase() !== authority.Military">
            <span
              *ngIf="item.authority?.toLowerCase() !== authority.FAA && item.authority?.toLowerCase() !== authority.TC"
              class="property-title">License Type</span>
            <span *ngIf="item.authority?.toLowerCase() === authority.FAA" class="property-title">Grade of
              Certificate</span>
            <span *ngIf="item.authority?.toLowerCase() === authority.TC" class="property-title">Grade of License</span>
            - {{ item.licenceType.name }}
          </div>
          <div class="license-detail-property" *ngIf="
              item.authority?.toLowerCase() !== authority.FAA &&
              item.authority?.toLowerCase() !== authority.Military &&
              !!item.expiryDate 
            ">
            <span *ngIf="item.authority?.toLowerCase() !== authority.TC" class="property-title">Proficiency Type
              Expiry</span>
            <span *ngIf="item.authority?.toLowerCase() === authority.TC" class="property-title">Aviation Document Expiry
              Date</span>
            -
            {{ item.expiryDate | date: 'MMM-dd-yyyy' }}
          </div>
          <div class="license-detail-property"
            *ngIf="item.lastTCDate && _currentTrainingRequirement && _currentTrainingRequirement.trainingType != trainintType.Initial">
            <span class="property-title">Date of Last Initial or Recurrent</span>
            -
            {{ item.lastTCDate | date: 'MMM-dd-yyyy' }}
          </div>
          <div class="license-detail-property" *ngIf="showPBN(item)">
            <span class="property-title">PBN Endorsement</span> -
            {{ item.isPerformanceBasedNavigation ? 'Yes' : 'No' }}
          </div>
          <div class="license-detail-property"
            *ngIf="_currentTrainingRequirement && _currentTrainingRequirement.trainingType === trainintType.Initial && item.authority === authority.EASA">
            <span class="property-title">Completed Advanced UPRT Training </span> -
            {{ item.completedAdvancedUPRT ? 'Yes' : 'No' }}
          </div>
          </div>
          
        </app-form-field-list-item>
      </div>
    </div>
  </div>

  <div class="wrapper no-licenses" *ngIf="showErrorMessage()">
  <div class="subTitle">
    Existing or future
  </div>
    <div class="subTitle " *ngIf="showSubtitle() && licences.length === 0">
      Add the licence / certificate information for the training authorities.
    </div>
    <daui-panel-info class="mt-1" [state]="state">
      {{getNoLicenceErrorMessage()}}
    </daui-panel-info>
  </div>
  <div class="wrapper actions" *ngIf="!readonly && !(isOtherLicenseFeatureEnabled$ | async)">
    <daui-button id="addLicenceButton" [icon]="buttonIcon.Add" [type]="buttonType.Flat"
      [attractive]="buttonAttractive.Regular" (click)="goToLicenceAdd()">
      Add License
    </daui-button>
  </div>

  <div class="wrapper actions" *ngIf="
      !isFaaRegulated() &&
      !readonly &&
      !!(isOtherLicenseFeatureEnabled$ | async) &&
      (isLicenceEnabled['easa'] ||
        isLicenceEnabled['ukcaa'] ||
        isLicenceEnabled['tc'] ||
        isLicenceEnabled['faa'] ||
        isLicenceEnabled['military'] ||
        isLicenceEnabled['other'])
    ">
    <clr-dropdown>
      <daui-button [icon]="buttonIcon.Add" [type]="buttonType.Flat" [attractive]="buttonAttractive.Regular"
        clrDropdownTrigger>
        Add a license
      </daui-button>

      <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right" class="dropdown">
        <div clrDropdownItem (click)="goToLicenceAdd()" *ngIf="!readonly && !(isOtherLicenseFeatureEnabled$ | async)">
          Add License
        </div>
        <div clrDropdownItem (click)="isLicenceEnabled['easa'] ? goToLicenceAdd('easa') : null"
          *ngIf="!readonly && !!(isOtherLicenseFeatureEnabled$ | async)"
          [class.disabledItem]="!isLicenceEnabled['easa']">
          EASA
        </div>
        <div clrDropdownItem (click)="isLicenceEnabled['ukcaa'] ? goToLicenceAdd('ukcaa') : null"
          *ngIf="!readonly && !!(isOtherLicenseFeatureEnabled$ | async)"
          [class.disabledItem]="!isLicenceEnabled['ukcaa']">
          UK CAA
        </div>
        <div clrDropdownItem (click)="isLicenceEnabled['tc'] ? goToLicenceAdd('tc') : null"
          *ngIf="!readonly && !!(isOtherLicenseFeatureEnabled$ | async)" [class.disabledItem]="!isLicenceEnabled['tc']">
          Transport Canada
        </div>
        <div clrDropdownItem (click)="isLicenceEnabled['faa'] ? goToLicenceAdd('faa') : null"
          *ngIf="!readonly && !!(isOtherLicenseFeatureEnabled$ | async)"
          [class.disabledItem]="!isLicenceEnabled['faa']">
          FAA
        </div>
        <div clrDropdownItem (click)="isLicenceEnabled['military'] ? goToLicenceAdd('military') : null"
          *ngIf="!readonly && !!(isOtherLicenseFeatureEnabled$ | async) && _currentTrainingRequirement && _currentTrainingRequirement.regulationAgency !== regulatoryAgencyName.TC"
          [class.disabledItem]="!isLicenceEnabled['military']">
          Military
        </div>
        <div clrDropdownItem (click)="isLicenceEnabled['other'] ? goToLicenceAdd('other') : null"
          *ngIf="!readonly && !!(isOtherLicenseFeatureEnabled$ | async)"
          [class.disabledItem]="!isLicenceEnabled['other']">
          Other NAA
        </div>
      </clr-dropdown-menu>
    </clr-dropdown>
  </div>

  <div class="wrapper actions" *ngIf="
      isFaaRegulated() &&
      !readonly &&
      !!(isOtherLicenseFeatureEnabled$ | async) &&
      isLicenceEnabled['faa']
    ">
    <daui-button [icon]="buttonIcon.Add" [type]="buttonType.Flat" [attractive]="buttonAttractive.Regular"
      (click)="isLicenceEnabled['faa'] ? goToLicenceAdd('faa') : null"
      *ngIf="!readonly && !!(isOtherLicenseFeatureEnabled$ | async)" [class.disabledItem]="!isLicenceEnabled['faa']">
      Add license
    </daui-button>
  </div>
</div>