import { Subscription } from 'rxjs';

export abstract class BasePageComponent {
  subscriptions: Array<Subscription> = [];

  onInit() {}

  onDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach(sub => sub.unsubscribe());
    }
  }

  subscribe(subscription: Subscription) {
    this.subscriptions.push(subscription);
  }
}
