<form class="form" #f="ngForm" novalidate>
  <div class="container">
    <div class="loginHint">
      {{ loginHint }}
    </div>
    <daui-button *ngIf="!authHelper.isAzureB2C" class="login" [skin]="themeSkin.Alternative"
      (click)="onAuthWithSalesforce()">
      Login with Salesforce
    </daui-button>

    <daui-button *ngIf="authHelper.isAzureB2C" class="login" [skin]="themeSkin.Alternative" (click)="onAuthWithB2C()">
      Login with Azure B2C
    </daui-button>

    <div class="text" (click)="onAuthWithAD()">
      Don’t have a Salesforce account? <br />Click
      <span class="link">
        <daui-button [type]="buttonType.Link" [skin]="themeSkin.Alternative">here</daui-button>
      </span>
      to login.
    </div>
  </div>
</form>