import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { AppSettings } from 'src/app/models/AppSettings';
import { AppSettingsService } from 'common-web-core';
import { BaseDataService } from 'src/app/core/services/base.service';
import { Country } from 'src/app/models/Country';
import { HttpClient } from '@angular/common/http';
import { HttpErrorHandler } from 'src/app/app.error-handler';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CountriesService extends BaseDataService {
  baseUrl: string;

  countries = new Array<Country>();
  authority: string;

  constructor(http: HttpClient, 
              httpErrorHandler: HttpErrorHandler,
              appSettingsService: AppSettingsService<AppSettings>) {
    super(http, httpErrorHandler.createHandleError('CountriesService'));   
   
    appSettingsService.appSettings$.subscribe((appSettings: AppSettings) => {
      this.baseUrl = appSettings.webApiSettings.api+'/countries';      
    });
  }

  getCountries(authority: string): Observable<Array<Country>> {
    const url = `${this.baseUrl}?authority=${authority}`;

    if (this.countries.length > 0 && this.authority === authority) {
      return new BehaviorSubject(this.countries);
    }

    return this.http
      .get<Array<Country>>(url)
      .pipe(
        tap(countries => {
          this.countries = countries;
          this.authority = authority;
        })
      )
      .pipe(catchError(this.handleHttpError<Array<Country>>()));
  }
}
