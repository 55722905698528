<div class="ng-autocomplete">
  <ng-autocomplete [name]="name" [data]="data" [(ngModel)]="ngModel" [placeholder]="placeholder"
    [searchKeyword]="keyword" [debounceTime]="500" (inputChanged)="onInputChanged($event)"
    (selected)='onSelection($event)' (inputCleared)='onInputCleared()' [itemTemplate]="itemTemplate"
    [notFoundTemplate]="notFoundTemplate" [isLoading]="loading" ngDefaultControl *ngIf="!isDisabled">
  </ng-autocomplete>
  <div *ngIf="isDisabled"  class="autocomplete-disabled">
    <input [disabled]="isDisabled" [name]="name" [(ngModel)]="ngModel" [placeholder]="placeholder" ngDefaultControl>
  </div>
  


  <ng-template #itemTemplate let-item>
    <a [innerHTML]="item[keyword]" style="padding-bottom: 5px;"></a>
    <div *ngIf="!!subTextKey" style="padding-left: 15px; margin-bottom: 5px; font-style: italic; font-size: 12px;">
      {{item[subTextKey]}}</div>
  </ng-template>

  <ng-template #notFoundTemplate let-notFound>
    <div [innerHTML]="notFound"></div>
  </ng-template>
</div>