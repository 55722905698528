import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WaitingPanelService {
  private isVisible$: Subject<boolean> = new Subject<boolean>();
  private waitingPanelCount = 0;

  constructor() {}

  show(): void {
    this.waitingPanelCount++;
    if (this.waitingPanelCount === 1) {
      this.isVisible$.next(true);
    }
  }

  hide(): void {
    if (this.waitingPanelCount > 0) {
      this.waitingPanelCount--;
    }
    if (this.waitingPanelCount === 0) {
      this.isVisible$.next(false);
    }
  }

  destroy(): void {
    this.waitingPanelCount = 0;
  }

  getIsVisible(): Observable<boolean> {
    return this.isVisible$;
  }
}
