import { CompleterData, CompleterItem } from 'ng2-completer';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { BaseUIComponent } from '../base-ui-component';

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.less']
})
export class AutoCompleteComponent extends BaseUIComponent implements OnChanges {
  @Input() placeholder = '';
  @Input() ngModel = '';
  @Output() ngModelChange = new EventEmitter<string>();
  @Input() datasource: CompleterData | string | any[];
  @Output() selected = new EventEmitter<CompleterItem>();
  @Input() parentForm: UntypedFormGroup;
  @Input() name: string;
  @Input() id: string;
  @Input() subTextKey: string;
  @Input() keyword: string = 'name';
  @Input() isDisabled:boolean;
  data: any[] = [];
  loading = false;
  // When this is true, the data is not being search from a remote source
  isLocalData = false;

  constructor() {
    super();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['datasource']) {
      if (Array.isArray(this.datasource)) {
        this.data = this.datasource;
        this.isLocalData = true;
      }
    }
  }

  async onInit() {
    if (this.parentForm !== undefined) {
      if (!this.parentForm.contains(this.name)) {
        this.parentForm.addControl(this.name, new UntypedFormControl(this.id));
      }
    }

    if (this.datasource) {

      if (Array.isArray(this.datasource)) {
        this.data = this.datasource;
        this.isLocalData = true;
      }
      else {
        (this.datasource as CompleterData).subscribe(data => {
          this.data = data.map(x => x.originalObject);
          this.loading = false;
        })
      }
    }
  }


  onInputChanged(e: any) {

    if (!this.isLocalData) {
      (this.datasource as CompleterData).search(e);
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 5000);
    }
  }

  onDestroy(): void {
    if (this.parentForm !== undefined) {
      this.parentForm.removeControl(this.name);
    }

  }

  onInputCleared() {
    if (this.parentForm !== undefined) {
      this.parentForm.controls[this.name].markAsDirty();
    }
    this.selected.next(null);
  }

  onSelection($event) {
    if (this.parentForm !== undefined) {
      this.parentForm.controls[this.name].markAsDirty();
    }
    this.selected.next($event);
  }
}
