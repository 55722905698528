import { AccountExecutive } from './AccountExecutive';
import { AddOn } from './Addon';
import { Approver } from './Approver';
import { CourseCheckTypeOverride } from './CourseCheckTypeOverride';
import { Licence } from './Licence';
import { RegulationAgencyName } from './RegulationAgencyName';
import { SpecialAirport } from './SpecialAirport';
import { SubmissionStatus } from './SubmissionStatus';
import { TrainingProfile } from './TrainingProfile';
import { UserRoles } from './User';

export class SubmitTrainingRequirementCommand {
  id: string;
  revisionNumber: number;
  course: string;
  courseId: string;
  location: string;
  customerId: string;
  customer: string;
  accountExecutive: AccountExecutive;
  clientId: string;
  client: string;
  aircraftModel: string;
  aircraftId: string;
  startDate: Date;
  endDate: Date;
  trainingType: string;
  operatingManualA: string;
  operatingManualB: string;
  operatingManualC: string;
  operatingManualD: string;
  seat: string;
  phasedTraining: string;
  overrideSeat: string;
  licences: Licence[];
  specialAirports: SpecialAirport[];
  checkingAddons: AddOn[];
  trainingAddons: AddOn[];
  trainingProfile: TrainingProfile;
  note: string;
  submissionStatus: SubmissionStatus;
  courseCheckType: string;
  courseCheckTypesOverride: Array<CourseCheckTypeOverride>;
  submissionDateTime: string;
  isCustomTcaProvided: boolean;
  approvers: Array<Approver>;
  appRights: string[];
  userRole: UserRoles;
  schedulerReservationId: string;
  aircraftOperatingCertificate: string;
  baseMonth: string;
  currentDutyPosition: string;
  desiredDutyPosition: string;
  overrideDesiredDutyPosition: string;
  regulationAgency: RegulationAgencyName;
  operatingAircraftTypeSerialNumber: string;
  totalFlightTime: string;
  flightTimeInAircraftType: string;
  hasMultiEngineLandandInstrumentRating: boolean;
  constructor() {
    this.id = '0';
    this.course = '';
    this.courseId = '';
    this.location = '';
    this.customerId = '';
    this.customer = '';
    this.accountExecutive = new AccountExecutive();
    this.clientId = '';
    this.client = '';
    this.aircraftModel = '';
    this.aircraftId = '';
    this.startDate = new Date();
    this.endDate = new Date();
    this.trainingType = '';
    this.operatingManualA = '';
    this.operatingManualB = '';
    this.operatingManualC = '';
    this.operatingManualD = '';
    this.seat = '';
    this.phasedTraining = '';
    this.overrideSeat = '';
    this.licences = new Array<Licence>();
    this.specialAirports = new Array<SpecialAirport>();
    this.checkingAddons = new Array<AddOn>();
    this.trainingAddons = new Array<AddOn>();
    this.trainingProfile = new TrainingProfile();
    this.note = '';
    this.submissionStatus = SubmissionStatus.NotSubmitted;
    this.courseCheckType = '';
    this.courseCheckTypesOverride = new Array<CourseCheckTypeOverride>();
    this.approvers = new Array<Approver>();
    this.appRights = ['TcaReadWrite'];
    this.userRole = UserRoles.Customer;
    this.schedulerReservationId = '';
    this.aircraftOperatingCertificate = '';
    this.baseMonth = '';
    this.currentDutyPosition = '';
    this.desiredDutyPosition = '';
    this.overrideDesiredDutyPosition = '';
    this.hasMultiEngineLandandInstrumentRating = false;
    this.regulationAgency = RegulationAgencyName.None;
  }
}
