<div class="collapse-component">
  <div class="collapse-header">
    <p class="p">{{title}}</p>
    <div  class="expander" >
      <daui-button
          class="item"
          [icon]="buttonIcon"
          [type]="buttonType.Flat"
          [attractive]="buttonAttractive.Regular"
          (click)="toggleCollapse()"
          ></daui-button
        >
        
    </div>
  </div>
  
  <div *ngIf="collapsed">
    <ng-content></ng-content>
  </div>
</div>
