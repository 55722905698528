<app-form-card>
  <div class="container" [ngClass]="{ hidden: !currentTrainingRequirement }">
    <h2>{{this.title}}</h2>
    <div class="subTitle" *ngIf="!readonly">
      {{ isCustomTca ? "Upload your TCA in PDF format. (Max size " + this.fileSize + "Mb)" :
      "Upload your documents in PDF format. (Max size " + this.fileSize + "Mb)" }}
    </div>
    <br>
    <daui-panel-info *ngIf="this.isCustomTca && this.isCustomTcaFileMissing" class="mt-1"
      [state]="panelInfoState.Error">
      {{ "TCA required" }}
    </daui-panel-info>
    <div class="wrapper">
      <div class="uploaded-file" *ngFor="let item of files">
        <img class="file-icon" src="../../assets/images/FilePDF.png" />
        <div class='file-list-item'>
          <div class="name">{{item.file.name}}</div>
          <div class="file-size">{{ fileSizeKB(item.file.size) }}</div>
        </div>
        <div style="margin-left: auto; display: flex;">
          <img *ngIf="item.status == 'uploaded'" style="height: 20px; margin: 0px 30px;"
            src="../../assets/images/icon-approved-state@2x.png" />
          <app-form-card-delete (delete)="onDelete(item.file.name)"></app-form-card-delete>
        </div>
      </div>
      <div *ngIf="showUploadButton()" class='upload-button'>
        <daui-button id="upload" [icon]="buttonIcon.None" [type]="buttonType.Regular"
          [attractive]="buttonAttractive.Regular" (click)="uploadFiles()">
          Upload
        </daui-button>
      </div>
      <div class="upload-status" *ngIf="uploadStatus">
        <img [src]="statusIcon" />
        <div>{{statusMessage}}</div>
      </div>
      <div class="wrapper actions" *ngIf="!readonly">
        <div style='height: 0px;width:0px; overflow:hidden;'><input accept="application/pdf" id={{this.fileId}}
            (change)="onFileSelected($event)" onclick="value = null" type="file" value="upload" /></div>
        <daui-button *ngIf="!isCustomTca || this.files.length === 0" id="addNote" [icon]="buttonIcon.Add"
          [type]="buttonType.Flat" [attractive]="buttonAttractive.Regular" (click)="selectFile()">
          ADD PDF
        </daui-button>
      </div>
    </div>

  </div>

  <daui-dialog [behavior]="dialogBehavior.DesktopMobile" [size]="currentDialogSize" [isShow]="isShowDialog">
    <div class="dialog-content-wrapper">
      <h1>Invalid file selected</h1>
      <div style="height: auto; padding: 5px; margin-top: 15px;">
        <daui-panel-info class="panel-info" [state]="panelInfoState.Warning">{{invalidFileMessage}}
        </daui-panel-info>
      </div>
      <daui-action-bar [hasSecondary]="false" primaryButtonText="Close" class="dialog-content-wrapper-actions"
        (primary)="closeDialogClicked()">
      </daui-action-bar>
    </div>
  </daui-dialog>
</app-form-card>