<div
  class="wrapper"
  [ngClass]="{
    default: formFieldListItemState.Default === state,
    error: formFieldListItemState.Error === state,
    warning: formFieldListItemState.Warning === state,
    hover: !readonly
  }"
>
  <div class="addTop"></div>
  <div [ngClass]="{ selectedBlock: !readonly }">
    <div class="navigation">
      <div class="label">{{ label }}</div>
      <div
        *ngIf="!readonly"
        [ngClass]="{
          navIndicator: formFieldListItemMode.Navigation === mode,
          addIndicator: formFieldListItemMode.Add === mode
        }"
      ></div>
    </div>
    <span [ngClass]="readonly ? 'readonly-content' : 'content'">
      <ng-content></ng-content>
    </span>
  </div>
  <div
    class="message"
    [ngClass]="{
      default: formFieldListItemState.Default === state,
      error: formFieldListItemState.Error === state,
      warning: formFieldListItemState.Warning === state
    }"
    *ngIf="isShowPanelInformation"
  >
    <div *ngFor="let item of informations">{{ item }}</div>
  </div>
</div>
