import { Pipe, PipeTransform } from '@angular/core';
import { countryList } from 'src/app/data/countries';

@Pipe({ name: 'countryFormat' })
export class CountryFormatPipe implements PipeTransform {
  transform(id: string): string {
    const selectedCountry = countryList.find(country => country.id === id);
    return !!selectedCountry ? selectedCountry.name : id;
  }
}
