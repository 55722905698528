/**
 * Available features toggle.
 */
export enum ToggleFeatures {
  CustomerWebAppAzureADLogin = 'customer-web-app-azure-ad-login',
  CustomerWebAppAzureB2CLogin = 'customer-web-app-b2c-enabled',
  CustomerWebAppCheckType = 'customer-web-app-check-type',
  CustomerWebAppTCASubmitCommand = 'customer-web-app-tca-submit-command',
  CustomerWebAppMaintenanceMode = 'customer-web-app-maintenance-mode',
  CustomerWebAppTCALocked = 'customer-web-app-tca-status-locked',
  CustomerWebAppCustomerReview = 'customer-web-app-customer-review',
  CustomerWebAppAddOnItemAvailability = 'customer-web-app-add-on-item-availability',
  CustomerWebAppSecuredBrowserDownload = 'customer-web-app-secured-browser-download',
  CustomerWebAppAzureADToSalesforceLogin = 'customer-web-app-azure-ad-to-salesforce-login',
  CustomerWebAppOtherLicense = 'customer-web-app-other-license',    
  CustomerWebApp13398PbnPrivileges = 'customer-web-app-13398-pbn-privileges',
  CustomerWebApp13419LVOHud = 'customer-web-app-13419-lvo-hud',
  CustomerWebAppSIONTrainingType = 'customer-web-app-sion-training-type',      
  CustomerWebApp13420And13044InitialAwopsWarningMessages = 'customer-web-app-13420-13044-initial-awops-warning-messages',        
  CustomerWebAppSaveForLater = 'customer-web-app-save-for-later',
  CustomerWebAppDarkMode = 'customer-web-app-dark-mode',
  CustomerWebAppViewSharepoint = 'customer-web-app-view-sharepoint',
  CustomerWebAppPresets = 'customer-web-app-presets',
  CustomerWebAppAutoLoadPresets = 'customer-web-app-auto-load-latest-preset',
  CustomerWebAppCustomTca = 'customer-web-app-manual-tca',
  CustomerWebAppPlannedMaintenanceBannerText = 'customer-web-app-planned-maintenance-banner'
}
