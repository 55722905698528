import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { Authority } from 'src/app/models/Authority';
import { ClearAllEasaOptions, ClearAllFaaOptions, ClearAllTCOptions, ClearAllGenericOptions, LogAnalyticEvent } from 'src/app/state/trainingRequirement.actions';

@Component({
  selector: 'app-clear-presets',
  templateUrl: './clear-presets.component.html',
  styleUrls: ['./clear-presets.component.less']
})
export class ClearPresetsComponent {

  @Input() authority: Authority;

  @Input() hasTrainingOptions: boolean;

  @Output() onClear = new EventEmitter();

  constructor(private store: Store) { }



  onClearAllOptions() {

    switch (this.authority) {
      case Authority.EASA:
        this.store.dispatch(new ClearAllEasaOptions());
        break;
      case Authority.FAA:
        this.store.dispatch(new ClearAllFaaOptions());
        break;
      case Authority.TC:
        this.store.dispatch(new ClearAllTCOptions());
        break;
      case Authority.Generic:
        this.store.dispatch(new ClearAllGenericOptions());
        break;

    }


    this.store.dispatch(new LogAnalyticEvent('regular', 'TCA Preset Clear All Delete'));

    this.onClear.emit();
  }

}
